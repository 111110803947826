import React, { useState } from 'react'

const UserDel = (props) => {
  return (
    <svg id="icon-delete" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
      <rect id="Rectangle_157" data-name="Rectangle 157" width="44" height="44" fill="#fff" opacity="0" />
      <path id="Icon_awesome-trash" data-name="Icon awesome-trash" d="M13.5,1H9.75L9.456.416A.75.75,0,0,0,8.784,0H5.213a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1ZM1.663,14.594A1.5,1.5,0,0,0,3.159,16h7.681a1.5,1.5,0,0,0,1.5-1.406L13,4H1Z" transform="translate(14.55 14)" />
    </svg>
  )
};

export default UserDel;


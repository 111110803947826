import React from 'react'
import { Button } from 'react-bootstrap';
import EditCategory from '../assets/img/icons/EditCategory';
import './SubCategory.scss'
import UserDel from '../assets/img/icons/DeleteUser';
import RemoveCategory from '../assets/img/icons/RemoveCategory';

const SubCategory = (props) => {
  return (
    <div className='sub-category-list d-flex justify-content-between'>
      <span>{props.SubCategory}</span>
      <div className='d-flex'>
        <Button onClick={props.editOnClick} variant='btn-dark'><EditCategory /></Button>
        <Button onClick={props.deleteOnClick} variant='btn-dark'><RemoveCategory/></Button>
      </div>
    </div>
  )
};

export default SubCategory;


import React from 'react'
import Select from 'react-select';
import { Form } from "react-bootstrap";
import './SelectLabel.scss'

const CustSelectLabel = (props) => {
  const colourStyles = {
    option: (styles, { isSelected }) => ({
      ...styles,
      background: isSelected
        ? '#89CB08'
        : '#ffffff',
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 1000,
    }),
  }
  const error= props.error
  return (
    <div className='cut-select-label'>
      <div className='form-label'>{props.label} <span>{props.labelinfo}</span></div>
      <Select
        classNamePrefix='filter'
        name={props.name}
        placeholder={props.placeholder}
        styles={colourStyles}
        value={props.value}
        onChange={props.onChange}
        isSearchable={false}
        options={props.options}
        isDisabled={props.isDisabled} />
        {error &&
            error !== "" && (
              <div>
              <Form.Text className="red">
                {error}
              </Form.Text>
              </div>
            ) }
    </div>
  )
};

export default CustSelectLabel;


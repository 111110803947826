import React from 'react'
import './Register.scss'
import Strings from "../assets/strings/Strings.json"
import { Button, Col } from 'react-bootstrap';
import Succsessimg from '../assets/img/reset-pass-success.svg'
import { useNavigate } from 'react-router-dom';

const ResetPassSuccess = (props) => {
  const navigate = useNavigate();
  const successButton = () => {
    props.onPress()
    navigate('/')
  }
  let pathName = window.location.pathname;

  return (
    <>
      <h1>{Strings.ForgotPassword.Head}</h1>
      <Col className='register-wrap success reset-success position-relative'>
        <h1>{Strings.ForgotPassword.Title3}</h1>
        <p className='m-0'>{Strings.ForgotPassword.Success}</p>
        {pathName !== `/customer/resetpassword/${props.id}`&&
        <Button variant='primary' onClick={successButton}>{Strings.ForgotPassword.Btn2}</Button>
        }
        <img src={Succsessimg} className='position-absolute' />
      </Col>
    </>
  )
};

export default ResetPassSuccess;


import React, { useState, useEffect } from 'react'
import './TopNav.scss'
import { Link, useNavigate } from "react-router-dom"
import Strings from "../assets/strings/Strings.json"
import Notificaion from '../assets/img/icons/Notification';
import { useDispatch, useSelector } from 'react-redux';
import { _signoutUser, _adminProfile, _signoutAdmin } from '../actions/AuthAction';
import { _vendorProfile, _vendorNotificationAvailable } from '../actions/VendorAction';
import { Button, Modal } from 'react-bootstrap'
import { getAuthToken } from '../utils/localStorage';
import * as constants from '../utils/constants';
import { toast } from 'react-toastify';
import * as alerts from '../utils/Messages'
const TopNav = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();


  // ------- call API from functions and response from redux start ---------
  const signout = () => dispatch(_signoutUser());
  const signoutResponse = useSelector((RootReducer) => RootReducer.AuthReducer.getSignoutResponse)

  const signoutAdmin = () => dispatch(_signoutAdmin());
  const signoutAdminResponse = useSelector((RootReducer) => RootReducer.AuthReducer.getAdminSignoutResponse)

  const vendorProfile = () => dispatch(_vendorProfile());
  const vendorProfileResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorProfileResponse);

  const adminProfile = () => dispatch(_adminProfile());
  const adminProfileResponse = useSelector((RootReducer) => RootReducer.AuthReducer.adminProfileResponse);

  // notification check start
  const notificationCheck = () => dispatch(_vendorNotificationAvailable());
  const notificationCheckApiResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorNotificationAvailableResponse);
  // notification check ends

  // ------- call API from functions and response from redux start ---------

  const [userProfileData, setUserProfileData] = useState([])
  const [adminProfileData, setAdminProfileData] = useState('')
  const [signoutConfirmationShow, setSignoutConfirmationShow] = useState(false)
  const [notificationCount, setNotificationCount] = useState(false)
  const [subscribed, setSubscribed] = useState(false)

  useEffect(() => {
    if (process.env.REACT_APP_USER_TYPE === 'vendor') {
      notificationCheck()
      vendorProfile();
    } else {
      adminProfile();
    }
  }, [])

  useEffect(() => {

    if (signoutAdminResponse && signoutAdminResponse.apiStatus) {
      if (signoutAdminResponse.apiErrorCode === constants.STATUS_200) {
        resetResponse("getAdminSignoutResponse")
        signoutConfirmationClose()
        navigate('/')
      }
    }
  }, [signoutAdminResponse])

  useEffect(() => {

    if (signoutResponse && signoutResponse.apiStatus) {
      if (signoutResponse.apiErrorCode === constants.STATUS_200) {
        resetResponse("getSignoutResponse")
        signoutConfirmationClose()
        navigate('/')
        if( getChat !== null){
          window.location.reload()
        }
      }
    }
  }, [signoutResponse])

  useEffect(() => {
    if (vendorProfileResponse && vendorProfileResponse.apiStatus) {
      if (vendorProfileResponse.apiErrorCode === constants.STATUS_200 && vendorProfileResponse.apiResponse && vendorProfileResponse.apiResponse.basic_info) {
        setUserProfileData(vendorProfileResponse.apiResponse.basic_info)
      } else {
        setUserProfileData([])
        navigate('/')
      }
      if (vendorProfileResponse.apiErrorCode === constants.STATUS_200 &&
        vendorProfileResponse.apiResponse.verification_info &&
        vendorProfileResponse.apiResponse.verification_info.subscribed === constants.STATUS_FALSE &&
        vendorProfileResponse.apiResponse.verification_info.is_first_subscription === constants.STATUS_FALSE) {
        setSubscribed(true)
        navigate("/profile", {
          state: {
            current_tab: "subscription",
          }
        })
      } else {
        setSubscribed(false)
      }
    }
  }, [vendorProfileResponse])

  useEffect(() => {
    if (adminProfileResponse && adminProfileResponse.apiStatus) {

      if (adminProfileResponse.apiErrorCode === constants.STATUS_200 && adminProfileResponse.apiResponse && adminProfileResponse.apiResponse.f_name) {
        setAdminProfileData(adminProfileResponse.apiResponse.f_name)
      } else {
        setAdminProfileData('')
        navigate('/')
      }
    }
  }, [adminProfileResponse])

  //handle notification check API response
  useEffect(() => {
    if (notificationCheckApiResponse && notificationCheckApiResponse.apiStatus) {
      if (notificationCheckApiResponse.apiErrorCode === constants.STATUS_200)
        if (notificationCheckApiResponse.apiResponse.total_unread_notification) {
          setNotificationCount(notificationCheckApiResponse.apiResponse.total_unread_notification)
        }
    }
  }, [notificationCheckApiResponse])

  const resetResponse = (action) => {
    dispatch(
      {
        type: action, apiStatus: false, apiErrorCode: 0, apiResponse: {}, apiMessage: ""
      }
    );
  }

  const signoutConfirmation = () => {
    setSignoutConfirmationShow(true)
  }

  const signoutConfirmationClose = () => {
    setSignoutConfirmationShow(false)
  }

  const signoutConfirmed = () => {
    signoutConfirmationClose()
    if (window.zE) {
      window.zE("messenger:set", "cookies", false)
    }
    window.localStorage.clear();
    if (getAuthToken() === "") {
      navigate('/')
    } else if (process.env.REACT_APP_USER_TYPE === 'vendor') {
      signout()
    } else {
      signoutAdmin()
    }
  }


  const handleSubscription = () => {
    if (subscribed) {
      toast.error(alerts.PLEASE_SUBSCRIBE_LOGIN)
    }
  }

  let pathName = window.location.pathname;

  const getChat = document.getElementById('ze-snippet')
  return (
    <>
      {process.env.REACT_APP_USER_TYPE === 'vendor' && vendorProfileResponse && vendorProfileResponse.apiStatus ?
        <div className='top-nav'>
          <Link onClick={handleSubscription} to={!subscribed && '/dashboard'} className={pathName === '/productdetails' || pathName === '/dashboard' || pathName === '/addproduct' ? 'active' : ""}>{Strings.TopNav.NavItem1}</Link>
          <Link target={getChat !== null && !subscribed && '_top'} onClick={handleSubscription} to={!subscribed && '/purchasehistory'} className={pathName === '/purchasehistory' || pathName ==='/setupshipment' ? 'active' : ""}>{Strings.TopNav.NavItem2}</Link>
          <Link target={getChat !== null && !subscribed && '_top'} onClick={handleSubscription} to={!subscribed && '/heatmap'} className={pathName === '/heatmap' ? 'active' : ""}>{Strings.TopNav.NavItem5}</Link>
          {
            /*
             <Link to='/purchasehistory'  onClick={() => alert("Not Implemented")}  className={pathName == '/purchasehistory' ? 'active' : ""}>{Strings.TopNav.NavItem2}</Link>
             */
          }

          <Link target={getChat !== null && !subscribed && '_top'} to={!subscribed && '/profile'} className={pathName === '/profile' ? 'active' : pathName === '/payment' ? 'active' : ""}>{Strings.TopNav.NavItem3}</Link>
          <Link to='/feedback' className={pathName === '/feedback' ? 'active' : ""} >Feedback</Link>
          <Link to='' onClick={signoutConfirmation}>{Strings.TopNav.NavItem4}</Link>
          <Link target={getChat !== null && !subscribed && '_top'} onClick={handleSubscription} to={!subscribed && '/notifications'} className={pathName === '/notifications' ? 'active position-relative' : "position-relative"} ><Notificaion /> {notificationCount && <span className='d-block position-absolute'>{notificationCount}</span>}</Link>

          {
            /*
             <Link to='/#' onClick={() => alert("Not Implemented")} className='position-relative'><Notificaion /><span className='d-block position-absolute'></span></Link>
             */
          }
        </div>
        :
        process.env.REACT_APP_USER_TYPE === 'admin' && adminProfileData !== '' ?
          <div className='top-nav'>  {  /* ADMINN TOP NAV */}
            {/*
          <Link to='/admin/manageuser' className={pathName == '/admin/manageuser' ? 'active' : ""}>{Strings.Admin.TopNav.NavItem2}</Link>*/
            }
            <Link to='/admin/managevendor' className={pathName === '/admin/managevendor' || pathName === '/admin/viewschedules' || pathName === '/admin/vendorapproval' || (pathName.indexOf('admin/deals/') === 1) ? 'active' : ""}>{Strings.Admin.TopNav.NavItem1}</Link>


            <Link to='/admin/manageuser' className={pathName === '/admin/manageuser' ? 'active' : ""}>{Strings.Admin.TopNav.NavItem2}</Link>

            <Link to='/admin/managesubcategory' className={pathName === '/admin/managesubcategory' ? 'active' : ""}>{Strings.Admin.TopNav.NavItem3}</Link>

            <Link to='/admin/profile' className={pathName === '/admin/profile' || pathName === '/admin/changepassword' || pathName === '/admin/editprofile' ? 'active' : ""} >{Strings.Admin.TopNav.NavItem4}</Link>

            {
              /*
                 <Link to='/admin/profile' className={pathName == '/admin/profile' ? 'active' : ""} >{Strings.Admin.TopNav.NavItem4}</Link>
              */
            }

            <Link to='' onClick={signoutConfirmation}>{Strings.Admin.TopNav.NavItem5}</Link>
          </div>
          : ""
      }


      <Modal show={signoutConfirmationShow} onHide={signoutConfirmationClose} centered backdrop="static">
        <Modal.Body className='text-center'>
          <h5>{Strings.Logout.label}</h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button variant="outline-primary" onClick={signoutConfirmationClose}>{Strings.Logout.Btn2}</Button>
          <Button variant="primary" onClick={signoutConfirmed}>{Strings.Logout.Btn1}</Button>
        </Modal.Footer>
      </Modal>

    </>
  )
};

export default TopNav;


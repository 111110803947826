import React, { useState } from 'react'

const Date = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <g id="Group_845" data-name="Group 845" transform="translate(-509.096 -166.5)">
      <path id="Path_31474" data-name="Path 31474" d="M521.05,168.273H523.6V181h-14V168.273h2.545" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
      <line id="Line_141" data-name="Line 141" x2="6.364" transform="translate(513.414 168.273)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
      <rect id="Rectangle_522" data-name="Rectangle 522" width="1.273" height="2.545" transform="translate(512.141 167)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
      <rect id="Rectangle_523" data-name="Rectangle 523" width="1.273" height="2.545" transform="translate(519.778 167)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1"/>
      <line id="Line_142" data-name="Line 142" x2="14" transform="translate(509.596 172.091)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1"/>
    </g>
  </svg>
  
  )
};

export default Date;


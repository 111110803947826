/**
 * @file   src\reducers\UserReducer.js
 * @brief  This file is responsible for user responses.
 * @date   July, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

const INITIAL_STATE_USER = {
    // user list response 
    userListResponse: {
        apiLoaded: false,
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    // user delete response 
    userDeleteResponse: {
        apiLoaded: false,
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    userForgotPasswordResponse: {
        apiLoaded: false,
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    userResetPasswordResponse: {
        apiLoaded: false,
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    }
};

const UserActionTypes = {
    userList: 'userList',
    forgotUserPassword: 'forgotUserPassword',
    resetUserPassword: 'resetUserPassword',
};

const UserReducer = (state = INITIAL_STATE_USER, action = {}) => {
    switch (action.type) {
        case UserActionTypes.userList:
            return {
                ...state,
                userListResponse: {
                    ...state.userListResponse, apiLoaded:true, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case UserActionTypes.forgotUserPassword:
            return {
                ...state,
                userForgotPasswordResponse: {
                    ...state.userForgotPasswordResponse, apiLoaded:true, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case UserActionTypes.resetUserPassword:
            return {
                ...state,
                userResetPasswordResponse: {
                    ...state.userResetPasswordResponse, apiLoaded:true, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
     
        default:
            return state;
    }
}     

export default UserReducer;
import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap';
import ClearSearch from '../assets/img/icons/Close';
import Search from '../assets/img/icons/Search';
import './SearchInput.scss'

const SearchInput = (props) => {

  const [searchText, setsearchText] = useState("")
  const [searchFlag, setSearchFlag] = useState(false)

  const searchTextChange = (event) => {
    setSearchFlag(true)
    setsearchText(event.target.value)
  }

  const searchOnEnterKey = (event) => {
    if (event.keyCode === 13) {
      props.onClick(searchText)
    }
  }
  const clearSearchData = (event) => {
    setsearchText("")
    props.onClick("")
  }
  useEffect(() => {
    if (props) {
      setsearchText(props.p_SearchKey ? props.p_SearchKey : "")
    }
  }, [props])


  useEffect(() => {
    if (searchFlag && searchText === "") {
      setsearchText("")
      props.onClick("")
    }
  }, [searchText])

  return (
    <div className='search-input d-flex position-relative flex-column'>
      <Form.Control
        type="search"
        id="search"
        placeholder={props.placeholder ? props.placeholder : "Search"}
        name="search"
        value={searchText}
        maxLength={props.maxLength}
        onChange={searchTextChange}
        onKeyDown={searchOnEnterKey}
      />
      {searchText !== '' ? <button className='clear-search position-absolute' onClick={clearSearchData}><ClearSearch /></button> : ""}
      <Button variant='light' className='search position-absolute d-flex align-items-center justify-content-center' onClick={() => props.onClick(searchText)}><Search /></Button>
      {props.errorMessage && props.errorMessage !== '' ? <Form.Text className='error'>
          {props.errorMessage}
        </Form.Text> : ""}
    </div>
  )
};

export default SearchInput;


import React, { useState } from 'react'

const Phone = (props) => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="13.078" height="13.102" viewBox="0 0 13.078 13.102">
  <path id="icn_phone" d="M14.189,11.06v1.822A1.214,1.214,0,0,1,12.865,14.1a12.017,12.017,0,0,1-5.24-1.864A11.841,11.841,0,0,1,3.981,8.588,12.017,12.017,0,0,1,2.117,3.324,1.214,1.214,0,0,1,3.325,2H5.147A1.214,1.214,0,0,1,6.361,3.044a7.8,7.8,0,0,0,.425,1.706,1.214,1.214,0,0,1-.273,1.281L5.742,6.8a9.716,9.716,0,0,0,3.643,3.643l.771-.771A1.214,1.214,0,0,1,11.438,9.4a7.8,7.8,0,0,0,1.706.425A1.214,1.214,0,0,1,14.189,11.06Z" transform="translate(-1.611 -1.5)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
</svg>
  )
};
export default Phone;


/**
 * @file   src\reducers\AuthReducer.js
 * @brief  This file is responsible for auth response.
 * @date   July, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

const INITIAL_STATE = {
    getAuthResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    getChangePasswordResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    getSignoutResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    adminProfileResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },


    resetToInitialState: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    getAdminSignoutResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    }
};


const AuthActionTypes = {
    getAuthResponse: 'getAuthResponse',
    getSignoutResponse: 'getSignoutResponse',
    adminProfileResponse: 'adminProfileResponse',
    resetToInitialState:'resetToInitialState',
    getAdminSignoutResponse:'getAdminSignoutResponse'
};

const AuthReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case AuthActionTypes.getAuthResponse:
            return {
                ...state,
                getAuthResponse: {
                    ...state.getAuthResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AuthActionTypes.getSignoutResponse:
            return {
                ...state,
                getSignoutResponse: {
                    ...state.getSignoutResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AuthActionTypes.resetToInitialState:
            return {
                ...state,
                resetToInitialState: {
                    ...state.resetToInitialState, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AuthActionTypes.adminProfileResponse:
            return {
                ...state,
                adminProfileResponse: {
                    ...state.adminProfileResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AuthActionTypes.getAdminSignoutResponse:
            return {
                ...state,
                getAdminSignoutResponse: {
                    ...state.getAdminSignoutResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        default: {
            return state;
        }
    };
};

export default AuthReducer;
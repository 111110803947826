/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Col, Row, Breadcrumb } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';
import InnerLayout from '../../components/InnerLayout'
import SchedulesView from "../../components/SchedulesView"
import Strings from "../../assets/strings/Strings.json"
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { _adminListSchedule, _adminCancelSchedule, resetResponse } from '../../actions/admin/AdminVendorAction';
import  * as constants from '../../utils/constants';
import Paginate from '../../components/Paginate';
import { toast } from 'react-toastify';

const ViewSchedulesAdmin = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // list Schedule start
  const listSchedule = (params) => dispatch(_adminListSchedule(params));
  const listScheduleResponse = useSelector(
    (RootReducer) => RootReducer.AdminVendorReducer.listScheduleAPIResponse
  );
  // list Schedule ends

  // list Schedule start
  const cancelSchedule = (params) => dispatch(_adminCancelSchedule(params));
  const cancelScheduleResponse = useSelector(
    (RootReducer) => RootReducer.AdminVendorReducer.cancelScheduleAPIResponse
  );
  // list Schedule ends

  const initialListScheduleParams = {
    p_PageSize: 15,
    p_PageNumber: 1,
    vendorId: location.state?.vendor_id,
  };

  const [listScheduleParams, setlistScheduleParmas] = useState(
    initialListScheduleParams
  );
  const [scheduleList, setScheduleList] = useState([]);
  const [scheduledata, setScheduledata] = useState({
    start_date: new Date(),
    end_date: new Date(),
    occurrence: 0,
    value: [],
    date: 0,
  });

  useEffect(() => {
    listSchedule(listScheduleParams);
  }, [listScheduleParams]);

  //handle category list API response
  useEffect(() => {
    if (cancelScheduleResponse && cancelScheduleResponse.apiStatus) {
      if (cancelScheduleResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(cancelScheduleResponse.apiMessage);
        listSchedule(listScheduleParams);
      } else {
        toast.error(cancelScheduleResponse.apiMessage);
      }
      resetResponse(dispatch, "cancelScheduleAPIResponse");
    }
  }, [cancelScheduleResponse]);

  useEffect(() => {
    if (listScheduleResponse && listScheduleResponse.apiStatus) {
      if (
        listScheduleResponse.apiErrorCode === constants.STATUS_200 &&
        listScheduleResponse.apiResponse
      ) {
        let schedule_data = [];
        if (
          listScheduleResponse.apiResponse.scheduled_ntfn_list &&
          listScheduleResponse.apiResponse.scheduled_ntfn_list.length > 0
        ) {
          listScheduleResponse.apiResponse.scheduled_ntfn_list.map((data) => {
            let id = data.scheduleid;
            const filteredProductItems =
              listScheduleResponse.apiResponse.scheduled_ntfn_products.filter(
                (data) => data.scheduleid === id
              );
              const filteredAddress =
              listScheduleResponse.apiResponse.scheduled_ntfn_address.filter(
                (data) => data.scheduleid === id
              );
            let product = [];
            filteredProductItems.map((data) => {
              product.push({
                product_name: data.productname,
                product_id: data.productid,
                product_image: data.thumbnail_image,
              });
            });
            let proxy_zipcode =[]
            if(filteredAddress && filteredAddress.length > 0){
              if(filteredAddress[0].notificationtype === constants.STATUS_2){
                filteredAddress.map((data) => {
                  proxy_zipcode.push(data?.proxy_zipcode);
                });
              }
            }
            schedule_data.push({
              scheduleid: data.scheduleid,
              startdate: data.startdate,
              occurrence: 3,
              enddate: data.enddate,
              products: product,
              date: data.day,
              timeslot:  data?.timeslot,
              zipcodes: proxy_zipcode,
              address :  filteredAddress[0].address ? filteredAddress[0].address : "",
              city :  filteredAddress[0].city ? filteredAddress[0].city : "",
              country :  filteredAddress[0].country ? filteredAddress[0].country : "",
              state :  filteredAddress[0].state ? filteredAddress[0].state : "",
              zip :  filteredAddress[0].zip ? filteredAddress[0].zip : "",
              d_address1 :  filteredAddress[0].d_address1? filteredAddress[0].d_address1: "",
              d_city :  filteredAddress[0].d_city ? filteredAddress[0].d_city : "",
              d_country :  filteredAddress[0].d_country ? filteredAddress[0].d_country : "",
              d_state :  filteredAddress[0].d_state ? filteredAddress[0].d_state : "",
              d_zip :  filteredAddress[0].d_zip ? filteredAddress[0].d_zip : "",
              notificationtype :  filteredAddress[0].notificationtype,
            });
          });
        }
        setScheduleList(schedule_data);
      }
      else {
        setScheduleList([])
      }
    } else {
      setScheduleList([])
    }
  }, [listScheduleResponse]);

  // page change and state change
  const pageChange = (page) => {
    if (page) {
      setlistScheduleParmas((listScheduleParams) => ({
        ...listScheduleParams,
        p_PageNumber: page,
      }));
    }
  };

  const handleCancelSubscription = (id) => {
    cancelSchedule({
      scheduleId: id,
    });
  };

  return (
    <InnerLayout>
      <Col className="action-wrap">
        <Row>
          <Col lg="6">
            <h1>{Strings.Admin.ViewSchedules.Title}</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="/admin/managevendor">
                {Strings.Admin.ViewSchedules.Label}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{Strings.Admin.ViewSchedules.Label1}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col lg="6" className="text-lg-end"></Col>
        </Row>
      </Col>
      {scheduleList && scheduleList.length > 0 ? (
        scheduleList.map((data, key) => {
          return (
            <SchedulesView
              key={[key]}
              Data={data}
              handleCancelSubscription={handleCancelSubscription}
              value={scheduledata.value}
            />
          );
        })
      ) : (
        <Col className="text-center mt-5">{Strings.Dashboard.NoSchedule}</Col>
      )}
      {listScheduleResponse.apiStatus &&
      listScheduleResponse.apiResponse &&
      listScheduleResponse.apiResponse.total_records ? (
        <Col className="d-flex justify-content-center paginate-wrap mt-4">
          <span className="records">
            {Strings.Pagination.Label} &nbsp;
            {(listScheduleParams.p_PageNumber - 1) *
              listScheduleResponse.apiResponse.per_page +
              1}
            &nbsp; {Strings.Pagination.Label1} &nbsp;
            {(listScheduleParams.p_PageNumber - 1) *
              listScheduleResponse.apiResponse.per_page +
              listScheduleResponse.apiResponse.scheduled_ntfn_list.length}
            &nbsp; {Strings.Pagination.Label2} &nbsp;
            {listScheduleResponse.apiResponse.total_records}
            &nbsp; {Strings.Pagination.Label3}
          </span>

          <Paginate
            totalRecords={listScheduleResponse.apiResponse.total_records}
            currentPage={listScheduleResponse.apiResponse.current_page}
            perPage={listScheduleResponse.apiResponse.per_page}
            onClick={pageChange}
          />
        </Col>
      ) : (
        ""
      )}
    </InnerLayout>
  );
}
export default ViewSchedulesAdmin;

import React, { useState } from 'react'
import { Offcanvas } from 'react-bootstrap';
import './OffCanvas.scss'
import TopNav from './TopNav';
import logo from '../assets/img/logo-topbar.svg';
import Menu from '../assets/img/icons/Menu';
const OffCanvas = (props) => {
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {
        props.hideHeader === 'N' ? '' :
          <a className='menu' onClick={handleShow}>
            <Menu />
          </a>
      }
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <img src={logo} />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <TopNav />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
};

export default OffCanvas;


/**
 * @file   src\actions\UserAction.js
 * @brief  This file is responsible for user actions.
 * @date   July, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { UserApiList1, ApiUrls } from '../library/Apis';
//import { apiEncryptedPost } from '../library/Requests';
import  * as constants from '../utils/constants';
import axios from '../library/Requests';


const UserActionType = {
    userList: 'userList',
    forgotUserPassword: 'forgotUserPassword',
    resetUserPassword: 'resetUserPassword',
 };

/**
 * userList response 
 * @param {string} actionType 
 * @param {string} apiStatus 
 * @param {number} apiErrorCode 
 * @param {string} apiResponse 
 * @param {string} apiMessage 
 * @returns 
 */
export function userResponse(actionType, apiStatus, apiErrorCode, apiResponse, apiMessage) {
    return {
        type: actionType, apiStatus, apiErrorCode, apiResponse, apiMessage
    };
}

/**
 * get user list
 * @param {any} params 
 * @returns 
 */
const _userList = (params) => {
    
}
 

/**
* User Forgot Password
* @param {json} params 
* @returns json
*/

//apiEncryptedPost
const _userForgotPassword = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.users + UserApiList1.forgotPasswordURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        userResponse(
                            UserActionType.forgotUserPassword,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        userResponse(
                            UserActionType.forgotUserPassword,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        userResponse(
                            UserActionType.forgotUserPassword,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* User Reset Password
* @param {json} params 
* @returns json
*/
const _userResetPassword = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.users + UserApiList1.resetPasswordURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        userResponse(
                            UserActionType.resetUserPassword,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        userResponse(
                            UserActionType.resetUserPassword,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        userResponse(
                            UserActionType.resetUserPassword,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

export { _userList, _userForgotPassword, _userResetPassword  };
import React, { useState } from 'react'

const Filter = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22">
      <g id="Group_707" data-name="Group 707" transform="translate(-254 -781)">
        <path id="Path_96" data-name="Path 96" d="M273,781H255a1,1,0,0,0-1,1v2.54a1,1,0,0,0,.349.759L261,791v12l6-3v-9l6.651-5.7a1,1,0,0,0,.349-.759V782A1,1,0,0,0,273,781Z" />
      </g>
    </svg>
  )
};

export default Filter;


/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Button, Col, Tab, Row, Nav, Modal } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
import InnerLayout from '../../components/InnerLayout'
import Strings from '../../assets/strings/Strings.json'
import SearchInput from '../../components/SearchInput'
import Add from '../../assets/img/icons/Add'
import SubCategory from '../../components/SubCategory';
import Input from '../../components/Input';
import CustSelectLabel from '../../components/SelectLabel';
import { useDispatch, useSelector } from 'react-redux';
import { _categoryListing, _subCategoryListing, _createSubCategory, resetResponse, _deleteSubCategory } from '../../actions/admin/AdminVendorAction';
import { toast } from 'react-toastify';
import * as alerts from '../../utils/Messages'
import * as constants from '../../utils/constants';

const ManageSubCategory = () => {

  const dispatch = useDispatch();

  //getting category list  info 
  const categoryList = (params) => dispatch(_categoryListing(params));
  const categoryListingApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.categoryListingApiResponse);


  //getting Subcategory list  info 
  const subCategoryList = (params) => dispatch(_subCategoryListing(params));
  const subCategoryListingApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.subCategoryListingApiResponse);

  const createSubCategory = (createSubCategoryParams) => dispatch(_createSubCategory(createSubCategoryParams));
  const createSubCategoryApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.createSubCategoryApiResponse);

  const deleteSubCategory = (deleteSubCategoryParams) => dispatch(_deleteSubCategory(deleteSubCategoryParams));
  const deletesubcategoryApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.deletesubcategoryApiResponse);

  const intitalSubCategoryParams = {
    p_SearchKey: "",
    p_CategoryId: ""
  }

  const intitalcreateSubCategoryParams = {
    p_SubCatName: "",
    p_CategoryId: "",
    type: 1
  }
  const intitalEditSubCategoryParams = {
    p_SubCatName: "",
    p_SubCategoryId: "",
    p_CategoryId: "",
    type: 2
  }
  const intitalcreateSubCategoryParamsError = {
    categoryError: "",
    subcategoryNameError: ""
  }

  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [createSubCategoryParams, setCreateSubCategoryParams] = useState(intitalcreateSubCategoryParams);
  const [deleteSubCategoryId, setDeleteSubCategoryId] = useState('');
  const [createSubCategoryParamsError, setCreateSubCategoryParamsError] = useState(intitalcreateSubCategoryParamsError);
  const [editSubCategoryParamsError, setEditSubCategoryParamsError] = useState('');
  const [selectCategoryList, setSelectCategoryList] = useState([]);
  const [subCategoryParams, setSubCategoryParams] = useState(intitalSubCategoryParams);
  const [editSubCategoryParams, setEditSubCategoryParams] = useState(intitalEditSubCategoryParams);
  const [createdSubCategoryClicked, setCreatedSubCategoryClicked] = useState(false);
  const [deleteSubCategoryClicked, setDeleteSubCategoryClicked] = useState(false);
  const [editSubCategoryClicked, setEditSubCategoryClicked] = useState(false);
  const [editSubCategoryShow, setEditSubCategoryShow] = useState(false);
  const [deleteSubCategoryShow, setDeleteSubCategoryShow] = useState(false);
  const [editSubCategoryValue, setEditSubCategoryValue] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);
  const [categorySelected, setCategorySelected] = useState([]);


  const handleClose = () => {
    setShow(false);
    setCreateSubCategoryParams(intitalcreateSubCategoryParams);
    setCreateSubCategoryParamsError(intitalcreateSubCategoryParamsError);

  }
  const handleShow = () => {
    setShow(true)
  }


  //handle edit sub category
  const handleEditSubCategoryShow = (subcatId, categoryName, subCatName, categoryId ) => {
    setEditSubCategoryValue(categoryName)
    setEditSubCategoryParams(editSubCategoryParams => ({ ...editSubCategoryParams, ["p_SubCategoryId"]: subcatId }))
    setEditSubCategoryParams(editSubCategoryParams => ({ ...editSubCategoryParams, ["p_SubCatName"]: subCatName }))
    setEditSubCategoryParams(editSubCategoryParams => ({ ...editSubCategoryParams, ["p_CategoryId"]: categoryId }))
    setEditSubCategoryShow(true);
  }

  //handle delete sub category
  const handleDeleteSubCategoryShow = (subcatId) => {
  setDeleteSubCategoryId(subcatId)
  setDeleteSubCategoryShow(true);
  }

  const handleEditSubCategoryClose = () => {
    setEditSubCategoryShow(false);
    setEditSubCategoryParamsError('')

  }


  // Calling category list api 
  useEffect(() => {
    categoryList();
  }, [])

  // Calling Sub category list api 
  useEffect(() => {
    if (subCategoryParams.p_CategoryId) {
      subCategoryList(subCategoryParams)
    }
    let selectedCategory = []
    categories.map((category) => {
      if (subCategoryParams.p_CategoryId == category.categoryId)
        selectedCategory.push({ value: category.categoryId, label: category.categoryName })
    })
    setCategorySelected(selectedCategory)

  }, [subCategoryParams, createSubCategoryApiResponse, deletesubcategoryApiResponse])


  //create sub category save 
  useEffect(() => {
    if (createdSubCategoryClicked) {
      createSubCategory(createSubCategoryParams)
    }
  }, [createdSubCategoryClicked])

  // delete subcategory
  useEffect(() => {
    if (deleteSubCategoryClicked) {
      deleteSubCategory({ "p_SubCategoryId":deleteSubCategoryId})
    }
  }, [deleteSubCategoryClicked])

  //Edit sub category save 
  useEffect(() => {
    if (editSubCategoryClicked) {
      createSubCategory(editSubCategoryParams)
    }
  }, [editSubCategoryClicked])


  //handle category list API response
  useEffect(() => {
    if (categoryListingApiResponse && categoryListingApiResponse.apiStatus) {
      if (categoryListingApiResponse.apiErrorCode === constants.STATUS_200) {
        if (categoryListingApiResponse.apiResponse) {
          setCategories(categoryListingApiResponse.apiResponse)
          let categoryArray = []
          categoryListingApiResponse.apiResponse.map((category) => {
            categoryArray.push({ value: category.categoryId, label: category.categoryName })
          })
          setSelectCategoryList(categoryArray)
          setSubCategoryParams(subCategoryParams => ({ ...subCategoryParams, ["p_CategoryId"]: categoryArray[0].value }))
        }
        else
          toast.error(categoryListingApiResponse.apiMessage)
      }
    }
  }, [categoryListingApiResponse])

  //handle Sub category list API response
  useEffect(() => {
     if (subCategoryListingApiResponse && subCategoryListingApiResponse.apiStatus) {
      if (subCategoryListingApiResponse.apiErrorCode === constants.STATUS_200) {
        if (subCategoryListingApiResponse.apiResponse) {
          setSubCategories(subCategoryListingApiResponse.apiResponse)
        }else {
          setSubCategories([])
        }
       // else
        //  toast.error(subCategoryListingApiResponse.apiMessage)
      }else {
        setSubCategories([])
      }
    }
  }, [subCategoryListingApiResponse])

  //handle Create sub category  API response
  useEffect(() => {
    if (createSubCategoryApiResponse && createSubCategoryApiResponse.apiStatus) {
      if (createSubCategoryApiResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(createSubCategoryApiResponse.apiMessage)
        setCreateSubCategoryParams(intitalcreateSubCategoryParams)
        setEditSubCategoryParams(intitalEditSubCategoryParams)
        setCreatedSubCategoryClicked(false);
        setEditSubCategoryClicked(false);
        handleClose();
        handleEditSubCategoryClose();
      }
      else if (createSubCategoryApiResponse.apiErrorCode === constants.STATUS_400) {
        setCreatedSubCategoryClicked(false);
        setEditSubCategoryClicked(false);
        toast.error(createSubCategoryApiResponse.apiMessage)
      }
      resetResponse(dispatch, "createSubCategoryApiResponse");
    }
  }, [createSubCategoryApiResponse])

  //handle Delete sub category  API response
  useEffect(() => {
    if (deletesubcategoryApiResponse && deletesubcategoryApiResponse.apiStatus) {
      if (deletesubcategoryApiResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(deletesubcategoryApiResponse.apiMessage)
        setDeleteSubCategoryClicked(false);
        setDeleteSubCategoryShow(false)
        setDeleteSubCategoryId('')
      }
      else if (deletesubcategoryApiResponse.apiErrorCode === constants.STATUS_400) {
        setDeleteSubCategoryClicked(false);
        toast.error(deletesubcategoryApiResponse.apiMessage)
      }
      resetResponse(dispatch, "deletesubcategoryApiResponse");
    }
  }, [deletesubcategoryApiResponse])


  //handle category change
  const handleCategoryChanges = (event) => {
    setCreateSubCategoryParams(createSubCategoryParams => ({ ...createSubCategoryParams, p_CategoryId: event.value }))

    let selectedCategory = []
    categories.map((category) => {
      if (event.value == category.categoryId)
        selectedCategory.push({ value: category.categoryId, label: category.categoryName })
    })
    setCategorySelected(selectedCategory)

  }

 
  //handle sub category name
  const handleSubcategoryNameChanges = (event) => {
    setCreateSubCategoryParams(createSubCategoryParams => ({ ...createSubCategoryParams, p_SubCatName: event.target.value }))
    //setEditSubCategoryParams(editSubCategoryParams => ({ ...editSubCategoryParams, ["p_SubCatName"]: event.target.value }))
  }
  const handleSubcategoryEditChanges = (event) => {
    setEditSubCategoryParams(editSubCategoryParams => ({ ...editSubCategoryParams, ["p_SubCatName"]: event.target.value }))
  }
  const handleSubcategoryEditCategoryChanges = (event) => {
    setEditSubCategoryParams(editSubCategoryParams => ({ ...editSubCategoryParams, ["p_CategoryId"]: event.value}))

    let selectedCategory = []
    categories.map((category) => {
      if (event.value == category.categoryId)
        selectedCategory.push({ value: category.categoryId, label: category.categoryName })
    })
    setCategorySelected(selectedCategory)
  }

  //create sub category modal validation
  const createSubcategoryValidation = () => {
    setCreateSubCategoryParamsError(intitalcreateSubCategoryParamsError);
    let isValid = true;

    if (createSubCategoryParams.p_CategoryId === "") {
      isValid = false;
      setCreateSubCategoryParamsError(createSubCategoryParamsError => ({ ...createSubCategoryParamsError, categoryError: alerts.NO_CATEGORY_SELECTED }))
    }
    if (createSubCategoryParams.p_SubCatName.trim() === "") {
      isValid = false;
      setCreateSubCategoryParamsError(createSubCategoryParamsError => ({ ...createSubCategoryParamsError, subcategoryNameError: alerts.ENTER_SUB_CATEGORY_NAME }))
    }
    setCreateSubCategoryParams(createSubCategoryParams => ({ ...createSubCategoryParams, p_SubCatName: createSubCategoryParams.p_SubCatName.trim()}))

    return isValid;

  }

  //Edit sub category modal validation
  const editSubcategoryValidation = () => {
    setEditSubCategoryParamsError('');
    let isValid = true;
    if (editSubCategoryParams?.p_SubCatName.trim() === "") {
      isValid = false;
      setEditSubCategoryParamsError(alerts.ENTER_SUB_CATEGORY_NAME)
    }
    setEditSubCategoryParams(editSubCategoryParams => ({ ...editSubCategoryParams, ["p_SubCatName"]: editSubCategoryParams.p_SubCatName.trim() }))
    return isValid;

  }

  //create sub category api call
  const addSubCategory = () => {
    let isValid = createSubcategoryValidation();
    if (isValid) {
      setCreatedSubCategoryClicked(true);
    }
  }

  //Edit sub category api call
  const editSubCategory = () => {
    let isValid = editSubcategoryValidation();
    if (isValid) {
      setEditSubCategoryClicked(true);
    }
  }


  //search Subcategory
  const searchTextChange = (searchText) => {
    setSubCategoryParams(subCategoryParams => ({ ...subCategoryParams, p_SearchKey: searchText }))
  }

  //search Subcategory
  const displaySubCategoryList = () => {
    return subCategories.length ? subCategories.map((items, index) => (
      <SubCategory
        editOnClick={() => handleEditSubCategoryShow(items.subcatId, items.categoryName, items.subcatName, items.categoryId)}
        deleteOnClick={() => handleDeleteSubCategoryShow(items.subcatId)}
        SubCategory={items.subcatName}
      />)) :
      <>{Strings.Admin.SubCategory.Label}</>

  }
  //search Subcategory
  const showAddPopUp = () => {
    handleShow()
    //subCategoryParams={subCategoryParams}
    setCategories(categoryListingApiResponse.apiResponse)
    let selectedCategory = []
    categories.map((category) => {
      if (subCategoryParams.p_CategoryId == category.categoryId) {
        selectedCategory.push({ value: category.categoryId, label: category.categoryName })
        setCreateSubCategoryParams(createSubCategoryParams => ({ ...createSubCategoryParams, p_CategoryId: category.categoryId }))
      }
    })
    setCategorySelected(selectedCategory)
  }

  
  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='12' className='d-lg-flex align-items-lg-center'>
            <h1>{Strings.Admin.SubCategory.Title1}  </h1>
          </Col>
        </Row>
      </Col>
      <Row className='search-wrap'>
        <Col lg='5'>
          <SearchInput onClick={searchTextChange} placeholder={Strings.Admin.SubCategory.Placeholder1} p_SearchKey={subCategoryParams.p_SearchKey}/>
        </Col>
      </Row>
      <Col className='inner-main mt-0 p-0 sub-category'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={3} className="bd-rt">
              <Col className='category-left'>
                <h1>{Strings.Admin.SubCategory.Title2}</h1>
                {categories.length ? <Nav key="1" variant="pills" className="flex-column">
                  {categories.map((items, index) => (
                    <Nav.Item key={"item" + index}>
                      <Nav.Link key={"Link" + index} className={subCategoryParams.p_CategoryId == items.categoryId ? 'active' : ""} onClick={() => { setSubCategoryParams(subCategoryParams => ({ ...subCategoryParams, p_CategoryId: items.categoryId,p_SearchKey: '' })); setInitialLoad(false) }} eventKey={items.categoryId}>{items.categoryName}</Nav.Link>
                    </Nav.Item>))}
                </Nav> : ""}
              </Col>
            </Col>
            <Col sm={9}>
              <Col className='category-right'>
                <div className='category-header'>
                  <Row>
                    <Col md='6' lg='6'>
                      <h2>{categorySelected && categorySelected[0] ? categorySelected[0].label : ""} {Strings.Admin.SubCategory.Title3}</h2>
                    </Col>
                    <Col md='6' lg='6' className='d-flex justify-content-md-start justify-content-lg-end'>
                      <Button onClick={() => showAddPopUp()}><Add />{Strings.Admin.SubCategory.Btn}</Button>
                    </Col>
                  </Row>
                </div>
                <Tab.Content>

                  {initialLoad ? displaySubCategoryList() : ""}

                  {!initialLoad ?
                    <Tab.Pane eventKey={subCategoryParams.p_CategoryId}>
                      {displaySubCategoryList()}
                    </Tab.Pane> : ""}

                </Tab.Content>
              </Col>
            </Col>
          </Row>
        </Tab.Container>
      </Col>

      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Admin.SubCategory.Modal.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='create-sub-category'>
          <CustSelectLabel
            label={Strings.Admin.SubCategory.Modal.Label1}
            options={selectCategoryList}
            placeholder={Strings.Admin.SubCategory.Modal.Placeholder1}
            onChange={(e) => { handleCategoryChanges(e) }}
            value={categorySelected}
            error={createSubCategoryParamsError.categoryError}
          />
          <Input
            label={Strings.Admin.SubCategory.Modal.Label2}
            placeholder={Strings.Admin.SubCategory.Modal.Placeholder2}
            type='text'
            id='subCategory'
            name='p_SubCatName'
            value={createSubCategoryParams.p_SubCatName}
            onChange={handleSubcategoryNameChanges}
            errorMessage={createSubCategoryParamsError.subcategoryNameError}
          />
        </Modal.Body>
        <Modal.Footer className='justify-content-start'>
          <Button onClick={handleClose} variant="outline-primary">{Strings.Admin.SubCategory.Modal.Btn1}</Button>
          <Button onClick={() => addSubCategory()} variant="primary">{Strings.Admin.SubCategory.Modal.Btn2}</Button>
        </Modal.Footer>
      </Modal>

      {/* edit Sub Category */}
      <Modal show={editSubCategoryShow} onHide={handleEditSubCategoryClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Admin.SubCategory.Modal.Title1}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='create-sub-category'>
          <CustSelectLabel
            label={Strings.Admin.SubCategory.Modal.Label1}
            name='p_CategoryId'
            placeholder={editSubCategoryValue}
            options={selectCategoryList}
            onChange={(e) => {handleSubcategoryEditCategoryChanges(e) }}
            value={categorySelected}
            // isDisabled="true"
          />
          <Input
            label={Strings.Admin.SubCategory.Modal.Label3}
            placeholder={Strings.Admin.SubCategory.Modal.Placeholder2}
            type='text'
            id='subCategory'
            name='p_SubCatName'
            value={editSubCategoryParams.p_SubCatName}
            onChange={handleSubcategoryEditChanges}
            errorMessage={editSubCategoryParamsError}
          />
        </Modal.Body>
        <Modal.Footer className='justify-content-start'>
          <Button onClick={handleEditSubCategoryClose} variant="outline-primary">{Strings.Admin.SubCategory.Modal.Btn1}</Button>
          <Button onClick={() => editSubCategory()} variant="primary">{Strings.Admin.SubCategory.Modal.Btn2}</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteSubCategoryShow}
        onHide={() => setDeleteSubCategoryShow(false)}
        size="l"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center">
            {alerts.DELETE_SUBCATEGORY}
          </h5>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="outline-primary"
            onClick={() => setDeleteSubCategoryShow(false)}
          >
            {Strings.Profile.PaymentMethod.Btn}
          </Button>
          <Button variant="primary"
            onClick={()=>setDeleteSubCategoryClicked(true)}
          >
            {Strings.Profile.PaymentMethod.Btn3}
          </Button>
        </Modal.Footer>
      </Modal>
    </InnerLayout>
  );
}
export default ManageSubCategory;

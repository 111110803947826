/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Col, Row, Breadcrumb } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InnerLayout from '../../components/InnerLayout'
import Strings from '../../assets/strings/Strings.json'
import DealsAdmin from '../../components/DealsAdmin'
import { useParams } from 'react-router-dom';
import { _vendorDealsList } from '../../actions/admin/AdminVendorAction';
import { toast } from 'react-toastify';
import Paginate from '../../components/Paginate'
import * as alerts from '../../utils/Messages'
import NoImage from '../../assets/img/no-image.svg'
import * as constants from '../../utils/constants';


const Deals = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  //getting vendor deals and vendor info 
  const vendorDealsList = (vendorDealsListParams) => dispatch(_vendorDealsList(vendorDealsListParams));
  const vendorDealsListApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.vendorDealsApiResponse)

  // ------- state start -------

  const intitalVendorDealsListParams = {
    vendor_id: id,
    p_PageNumber: 1,
    p_PageSize: 8
  }

  const [vendorDealsListParams, setVendorDealsListParams] = useState(intitalVendorDealsListParams)
  const [vendorDetails, setVendorDetails] = useState([])
  const [vendorDeals, setVendorDeals] = useState([])

  // ------- state end -------

  // SETTING VENDOR DEALS API PARAMS FOR THE API 
  useEffect(() => {
    if (id && vendorDealsListParams) {
      vendorDealsList(vendorDealsListParams)
    }
  }, [id, vendorDealsListParams])


  // RECEIVING VENDOR DEALS API RESPONSE
  useEffect(() => {
    if (vendorDealsListApiResponse && vendorDealsListApiResponse.apiStatus) {
      if (vendorDealsListApiResponse.apiErrorCode === constants.STATUS_200 && vendorDealsListApiResponse.apiResponse && vendorDealsListApiResponse.apiResponse.vendor_info) {
        setVendorDetails(vendorDealsListApiResponse.apiResponse.vendor_info)
      } else {
        toast.error(vendorDealsListApiResponse.apiMessage)
      }
      if (vendorDealsListApiResponse.apiErrorCode === constants.STATUS_200 && vendorDealsListApiResponse.apiResponse && vendorDealsListApiResponse.apiResponse.vendor_deals) {
        if (vendorDealsListApiResponse.apiResponse.vendor_deals[0]) {
          setVendorDeals(vendorDealsListApiResponse.apiResponse.vendor_deals)
        }
      } else {
        setVendorDeals([])
      }
    }
  }, [vendorDealsListApiResponse])

  // page change and state change
  const pageChange = (page) => {
    if (page) {
      setVendorDealsListParams(vendorDealsListParams => ({ ...vendorDealsListParams, p_PageNumber: page }))
    }
  }

  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='6'>
            <h1>{Strings.Admin.Deals.Title}</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="/admin/managevendor">{Strings.Admin.Deals.Label1}</Breadcrumb.Item>
              <Breadcrumb.Item href="/admin/managevendor">{Strings.Admin.Deals.Label2}</Breadcrumb.Item>
               <Breadcrumb.Item active>Deals {/*vendorDetails.business_name*/}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Col>
      {vendorDetails.business_name ? <Col lg='12' className='inner-head text-center'>
        <h2>{vendorDetails.business_name}</h2>
      </Col> : ""}
      {vendorDeals.length ? <Row>
        {vendorDeals.map((items, index) => (
          <DealsAdmin img={items.thumbnail_image ? items.thumbnail_image : NoImage} title={items.product_name} price={items.price} />
        ))}
      </Row> : <Row><span className="text-center mt-5" >{alerts.NO_DEALS_AVAILABLE}</span></Row>}

      {
        vendorDealsListApiResponse.apiStatus && vendorDealsListApiResponse.apiResponse && vendorDealsListApiResponse.apiResponse.total_records ?
          <Col className='d-flex justify-content-center paginate-wrap'>
            <span className='records'>
              {Strings.Pagination.Label} &nbsp;
              {((vendorDealsListParams.p_PageNumber - 1) * vendorDealsListParams.p_PageSize) + 1
              }
              &nbsp; {Strings.Pagination.Label1} &nbsp;
              {((vendorDealsListParams.p_PageNumber - 1) * vendorDealsListParams.p_PageSize) + vendorDealsListApiResponse.apiResponse.vendor_deals.length}
              &nbsp; {Strings.Pagination.Label2} &nbsp;
              {vendorDealsListApiResponse.apiResponse.total_records}
              &nbsp; {Strings.Pagination.Label3}
            </span>

            <Paginate
              totalRecords={vendorDealsListApiResponse.apiResponse.total_records}
              currentPage={vendorDealsListApiResponse.apiResponse.current_page}
              perPage={vendorDealsListApiResponse.apiResponse.per_page}
              onClick={pageChange}
            />
          </Col> : ""
      }
    </InnerLayout>

  );
}
export default Deals;

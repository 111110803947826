/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Button, Col, Row, Table,Modal } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';
import InnerLayout from '../../components/InnerLayout'
import Paginate from '../../components/Paginate'
import Strings from '../../assets/strings/Strings.json'
import SearchInput from '../../components/SearchInput'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { _vendorApprovalList, _vendorBusinessinfo, _vendorsVerify } from '../../actions/admin/AdminVendorAction';
import { toast } from 'react-toastify';
import { formatPhoneNumber, dateToDDMMMYY } from '../../helpers/validationHelper'
import { resetResponse } from '../../actions/VendorAction'
import AdminVendorDetails from '../../components/admin/AdminVendorDetails';
import * as constants from '../../utils/constants';
import * as alerts from '../../utils/Messages';

const VendorApprovals = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ------- call API from functions and response from redux start ---------

  // vendor list start
  const vendorApprovalList = (vendorApprovalListParams) => dispatch(_vendorApprovalList(vendorApprovalListParams));
  const vendorApprovalListApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.vendorApprovalListApiResponse)

  const vendorBusinessinfo = (vendorBusinessinfoParams) => dispatch(_vendorBusinessinfo(vendorBusinessinfoParams));
  const vendorBusinessinfoApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.vendorBusinessinfoApiResponse)

  //_vendors_verify
  const vendorsVerify = (vendorsVerifyParams) => dispatch(_vendorsVerify(vendorsVerifyParams));
  const vendorsVerifyApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.vendorsVerifyApiResponse)
  // vendor list ends

  // ------- call API from functions and response from redux start ---------
  // ------- state start -------
  const intitalVendorListParams = {
    page: 1,
    searchText: ""
  };
  const intitalVendorApprovalListParams = {
    p_SearchKey: "",
    p_PageNumber: 1,
    p_PageSize: 20,
    p_SortColumn: "business_name",
    p_SortOrder: "ASC"
  };
  const intitalVendorBusinessinfoParams = {
    vendor_id: 0
  };
  const intitalVendorsVerifyParams = {
    vendor_id: 0,
    status: 0
  };

  const [show, setShow] = useState(false);
  const [modalVendorDetails, setModalVendorDetails] = useState([]);
  const [vendorBusinessinfoParams, setVendorBusinessinfoParams] = useState(intitalVendorBusinessinfoParams)
  const [vendorApprovalListParams, setVendorApprovalListParams] = useState(intitalVendorApprovalListParams)
  const [vendorApprovalListResponse, serVendorApprovalListResponse] = useState([])

  const [vendorsVerifyParams, setVendorsVerifyParams] = useState(intitalVendorsVerifyParams)
  const [vendorsVerifyResponse, setVendorsVerifyResponse] = useState([])

  const [clickedVendorVerify, setClickedVendorVerify] = useState(false)

  const [showAcceptConfirmation, setShowAcceptConfirmation] = React.useState(false)
  const [showRejectConfirmation, setShowRejectConfirmation] = React.useState(false)
  const [vendorId, setVendorId] = React.useState('')

  // ------- state end -------

  // ------- useEffect start ------- 

  // RECEIVING BUSINES INFO API RESPONSE
  useEffect(() => {

    if (vendorBusinessinfoApiResponse && vendorBusinessinfoApiResponse.apiStatus) {
      if (vendorBusinessinfoApiResponse.apiErrorCode == constants.STATUS_200 && vendorBusinessinfoApiResponse.apiResponse && vendorBusinessinfoApiResponse.apiResponse.business_info && vendorBusinessinfoApiResponse.apiResponse.business_info[0]) {
        setModalVendorDetails(vendorBusinessinfoApiResponse.apiResponse.business_info[0])
      } else if (vendorBusinessinfoApiResponse.apiErrorCode == constants.STATUS_401) {
        toast.error(vendorBusinessinfoApiResponse.apiMessage)
        navigate('/')
      }

    }
  }, [vendorBusinessinfoApiResponse])


  // SHOW POP UP IF THE BUSINESS IFNO RECEIVED FROM REDUX
  useEffect(() => {
    if (modalVendorDetails && modalVendorDetails && modalVendorDetails.vendor_id) {
      setShow(true)
    }

  }, [modalVendorDetails])

  // RESETTING BUSINEES INFO ON CLOSING MODAL POP UP
  useEffect(() => {
    if (!show) {
      setVendorBusinessinfoParams(vendorBusinessinfoParams => ({ ...vendorBusinessinfoParams, vendor_id: 0 }))
      setModalVendorDetails([])
    }
  }, [show])

  // RESETTING BUSINEES INFO ON CLOSING MODAL POP UP
  useEffect(() => {
    if (vendorApprovalListApiResponse && vendorApprovalListApiResponse.apiStatus) {
      if (vendorApprovalListApiResponse.apiErrorCode == constants.STATUS_200)
        serVendorApprovalListResponse(vendorApprovalListApiResponse);
      else if (vendorApprovalListApiResponse.apiErrorCode == constants.STATUS_401) {
        toast.error(vendorApprovalListApiResponse.apiMessage)
        navigate('/')
      } else
        toast.error(vendorApprovalListApiResponse.apiMessage)
    }
  }, [vendorApprovalListApiResponse])


  useEffect(() => {
    if (clickedVendorVerify && vendorsVerifyApiResponse && vendorsVerifyApiResponse.apiStatus) {
      setClickedVendorVerify(false)
      setVendorsVerifyParams(intitalVendorsVerifyParams)
      if (vendorsVerifyApiResponse.apiErrorCode == constants.STATUS_200) {
        setVendorsVerifyResponse(vendorsVerifyApiResponse);
        vendorApprovalList(vendorApprovalListParams)
        setShow(false)
        toast.success(vendorsVerifyApiResponse.apiMessage)
      }
      else{
        toast.error(vendorsVerifyApiResponse.apiMessage)
      }
      resetResponse(dispatch, "vendorsVerifyApiResponse")
    }
  }, [vendorsVerifyApiResponse])

  useEffect(() => {
    vendorApprovalList(vendorApprovalListParams)
  }, [vendorApprovalListParams])

  useEffect(() => {
    if (vendorBusinessinfoParams && vendorBusinessinfoParams.vendor_id)
      vendorBusinessinfo(vendorBusinessinfoParams)
  }, [vendorBusinessinfoParams])


  useEffect(() => {
    if (clickedVendorVerify && vendorsVerifyParams && vendorsVerifyParams.vendor_id && vendorsVerifyParams.status) {
      vendorsVerify(vendorsVerifyParams)
    }

  }, [vendorsVerifyParams])
  // ------- useEffect End -------

  // page change and state change
  const pageChange = (page) => {
    if (page) {
      setVendorApprovalListParams(vendorApprovalListParams => ({ ...vendorApprovalListParams, p_PageNumber: page }))
    }
  }
  const searchTextChange = (searchText) => {
    setVendorApprovalListParams(vendorApprovalListParams => ({ ...vendorApprovalListParams, p_SearchKey: searchText, p_PageNumber: 1 }))
  }

  const handleClose = () => setShow(false);


  const showVendorDetails = (vendor) => {
    setVendorBusinessinfoParams(vendorBusinessinfoParams => ({ ...vendorBusinessinfoParams, vendor_id: vendor }))
  }

  const verifyVendorAction = (action, vendorId) => {
    setClickedVendorVerify(true);
    let vendorStatus = (action == 'approve' ? 1 : 2);
    setVendorsVerifyParams(vendorsVerifyParams => ({ ...vendorsVerifyParams, vendor_id: vendorId, status: vendorStatus }))
  }
  // displaying vendor  approval list api response
  const displayVendorApprovalList = () => {

    let startPage = ((vendorApprovalListParams.p_PageNumber - 1) * vendorApprovalListParams.p_PageSize)
    if (vendorApprovalListResponse.apiStatus && vendorApprovalListResponse.apiResponse) {
      if (vendorApprovalListResponse.apiResponse.total_records) {
        return vendorApprovalListResponse.apiResponse.vendor_info.map((vendor, index) => {
          return (
            <tr>
              {/*<td>{slNo < 10 ? '0' + slNo : slNo}</td>*/}
              <td><Link to="" onClick={() => showVendorDetails(vendor.vendor_id)}>{vendor.b_name ? vendor.b_name : 'NA'}</Link></td>
              <td>{vendor.e_address ? vendor.e_address : 'NA'}</td>
              <td>{vendor.mob_number ? formatPhoneNumber(vendor.mob_number) : 'NA'}</td>
              <td>{vendor.u_city ? vendor.u_city : 'NA'}</td>
              <td>{vendor.added_on ? dateToDDMMMYY(vendor.added_on) : 'NA'}</td>
              <td className='action'><Button variant='primary' onClick={()=>{setShowAcceptConfirmation(true);setVendorId(vendor.vendor_id)}}   >{Strings.Admin.VendorApprovals.Btn1} </Button><Button variant='outline-primary' onClick={()=>{setShowRejectConfirmation(true); setVendorId(vendor.vendor_id)}}  >{Strings.Admin.VendorApprovals.Btn2} </Button></td>
              {/* <td className='action'><Button variant='primary' onClick={() => verifyVendorAction('approve', vendor.vendor_id)}   >{Strings.Admin.VendorApprovals.Btn1} </Button><Button variant='outline-primary' onClick={() => verifyVendorAction('reject', vendor.vendor_id)}  >{Strings.Admin.VendorApprovals.Btn2} </Button></td> */}
            </tr>
          )
        })
      } else if (!vendorApprovalListResponse.apiResponse.total_records) {
        return (
          <tr>
            <td colSpan="9" align='center'>{Strings.Admin.VendorApprovals.Label}</td>
          </tr>
        )
      }
    }
  }


  const handleAcceptConfirm = () => {
    verifyVendorAction('approve', vendorId)
    setShowAcceptConfirmation(false)
  }
  const handleRejectConfirm = () => {
    verifyVendorAction('reject', vendorId)
    setShowRejectConfirmation(false)
  }

  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='12' className='d-lg-flex align-items-lg-center'>
            <h1>{Strings.Admin.VendorApprovals.Title}</h1>
          </Col>
        </Row>
      </Col>
      <Row className='search-wrap'>
        <Col lg='5'>
          <SearchInput onClick={searchTextChange} />
        </Col>
      </Row>
      <Col>
        <Table responsive>
          <thead>
            <tr>
              {/*<th>Sl.No</th>*/}
              <th>{Strings.Admin.VendorApprovals.Th}</th>
              <th>{Strings.Admin.VendorApprovals.Th1}</th>
              <th>{Strings.Admin.VendorApprovals.Th2}</th>
              <th>{Strings.Admin.VendorApprovals.Th3}</th>
              <th>{Strings.Admin.VendorApprovals.Th4}</th>
              <th>{Strings.Admin.VendorApprovals.Th5}</th>
            </tr>
          </thead>
          <tbody>
            {displayVendorApprovalList()}

          </tbody>
        </Table>
        {
          vendorApprovalListResponse.apiStatus && vendorApprovalListResponse.apiResponse && vendorApprovalListResponse.apiResponse.total_records ?
            <Col className='d-flex justify-content-center paginate-wrap'>
              <span className='records'>
                {Strings.Pagination.Label} &nbsp;
                {((vendorApprovalListParams.p_PageNumber - 1) * vendorApprovalListParams.p_PageSize) + 1
                }
                &nbsp; {Strings.Pagination.Label1} &nbsp;
                {((vendorApprovalListParams.p_PageNumber - 1) * vendorApprovalListParams.p_PageSize) + vendorApprovalListResponse.apiResponse.vendor_info.length}
                &nbsp; {Strings.Pagination.Label2} &nbsp;
                {vendorApprovalListResponse.apiResponse.total_records}
                &nbsp; {Strings.Pagination.Label3}

              </span>

              <Paginate
                totalRecords={vendorApprovalListResponse.apiResponse.total_records}
                currentPage={vendorApprovalListResponse.apiResponse.current_page}
                perPage={vendorApprovalListResponse.apiResponse.per_page}
                onClick={pageChange}
              />
            </Col> : ""
        }
      </Col>

      {modalVendorDetails && show ? <AdminVendorDetails show={show} handleClose={handleClose} type='vendorApprovals' vendorDetails={modalVendorDetails} onClick={verifyVendorAction} /> : ""}

      <Modal show={showAcceptConfirmation} onHide={()=>setShowAcceptConfirmation(false)} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.ACTIVE_BLOCK_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={()=>setShowAcceptConfirmation(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleAcceptConfirm}>OK</Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={showRejectConfirmation} onHide={()=>setShowRejectConfirmation(false)} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.ACTIVE_BLOCK_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={()=>setShowRejectConfirmation(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleRejectConfirm}>OK</Button>
        </Modal.Footer>
      </Modal>

    </InnerLayout>

  );
}
export default VendorApprovals;

import React, { useState } from 'react'

const Business = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.997" height="13.725" viewBox="0 0 14.997 13.725">
      <g id="Group_846" data-name="Group 846" transform="translate(236.5 -44.955)">
        <path id="Path_31475" data-name="Path 31475" d="M-230.274,53.817H-236v-7h14v7h-5.746" transform="translate(0 0.545)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_31476" data-name="Path 31476" d="M-222.821,51.818v3.817h-12.725V51.818" transform="translate(0.182 2.544)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_31477" data-name="Path 31477" d="M-232.818,47.363V45.455h5.09v1.909" transform="translate(1.272)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" />
        <rect id="Rectangle_524" data-name="Rectangle 524" width="2.545" height="2.545" transform="translate(-230.274 53.089)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" />
      </g>
    </svg>
  )
};

export default Business;


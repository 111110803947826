import React, { useState } from 'react'

const Search = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.451" height="17.821" viewBox="0 0 18.451 17.821">
    <g id="Group_609" data-name="Group 609" transform="translate(1.301 1)">
      <line id="Line_8" data-name="Line 8" x1="4.253" y1="4.253" transform="translate(11.483 11.153)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
      <circle id="Ellipse_22" data-name="Ellipse 22" cx="6.5" cy="6.5" r="6.5" transform="translate(-0.301 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
    </g>
  </svg>
  )
};
export default Search;


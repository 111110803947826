/**
 * @file   src\actions\AuthAction.js
 * @brief  This file is responsible for auth actions.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { AdminApiList, ApiUrls } from "../../library/Apis";
//import { apiPost, axios.delete, apiGet, apiPut, apiUpload, apiEncryptedPost } from "../../library/Requests";
import axios from "../../library/Requests";
import * as alerts from '../../utils/Messages';
import * as constants from '../../utils/constants';

const AdminVendorActionTypes = {
    vendorListApiResponse: 'vendorListApiResponse',
    vendorApprovalListApiResponse: 'vendorApprovalListApiResponse',
    vendorBusinessinfoApiResponse: 'vendorBusinessinfoApiResponse',
    vendorBlockOrActivateApiResponse: 'vendorBlockOrActivateApiResponse',
    vendorsVerifyApiResponse: 'vendorsVerifyApiResponse',
    vendorProfileApiResponse: 'vendorProfileApiResponse',
    vendorDealsApiResponse: 'vendorDealsApiResponse',
    userListApiResponse: 'userListApiResponse',
    userPurchaseHistoryApiResponse: 'userPurchaseHistoryApiResponse',
    deleteUserApiResponse: 'deleteUserApiResponse',
    changeUserStatusApiResponse: 'changeUserStatusApiResponse',
    adminForgotPassword: 'adminForgotPassword',
    adminResetPassword: 'adminResetPassword',
    categoryListingApiResponse: 'categoryListingApiResponse',
    subCategoryListingApiResponse: 'subCategoryListingApiResponse',
    createSubCategoryApiResponse: 'createSubCategoryApiResponse',
    listSubscriptionApiResponse: 'listSubscriptionApiResponse',
    updateProfileApiResponse: 'updateProfileApiResponse',
    listScheduleAPIResponse: 'listScheduleAPIResponse',
    cancelScheduleAPIResponse: 'cancelScheduleAPIResponse',
    updatePasswordApiResponse: 'updatePasswordApiResponse',
    uploadProfileImageApiResponse: 'uploadProfileImageApiResponse',
    removeProfileImageApiResponse: 'removeProfileImageApiResponse',
    deletesubcategoryApiResponse: 'deletesubcategoryApiResponse'

};

/**
 * response
 * @param {string} actionType 
 * @param {string} apiStatus 
 * @param {number} apiErrorCode 
 * @param {json} apiResponse 
 * @param {string} apiMessage 
 * @returns json
 */
export function prepareResponse(actionType, apiStatus, apiErrorCode, apiResponse, apiMessage) {
    return {
        type: actionType, apiStatus, apiErrorCode, apiResponse, apiMessage
    };
}

/**
 * login user
 * @param {json} params 
 * @returns json
 */

const _vendorList = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.vendorList, params)
            .then((response) => {
                if (response) {

                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorListApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorListApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }

                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.vendorListApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};

/**
 * vendorApprovalList  
 * @param {json} params 
 * @returns json
 */

const _vendorApprovalList = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.vendorApprovalList, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorApprovalListApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorApprovalListApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.vendorApprovalListApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};
/**
 * vendorApprovalList  
 * @param {json} params 
 * @returns json
 */

const _vendorBusinessinfo = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.vendorBusinessinfo, params)
            .then((response) => {

                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorBusinessinfoApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorBusinessinfoApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.vendorBusinessinfoApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};

/**
 * vendorApprovalList  
 * @param {json} params 
 * @returns json
 */

const _vendorsVerify = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.vendorAcceptOrReject, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorsVerifyApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorsVerifyApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(

                            AdminVendorActionTypes.vendorApprovalListApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};

/**
 * changeVendorStatus  
 * @param {json} params 
 * @returns json
 */

const _changeVendorStatus = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.vendorBlockOrActivate, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorBlockOrActivateApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    } else {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorBlockOrActivateApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.vendorBlockOrActivateApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};
/**
 * changeVendorStatus  
 * @param {json} params 
 * @returns json
 */

const _adminProfile = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.admin + AdminApiList.adminProfile, {})
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorProfileApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorProfileApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.vendorProfileApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};

/**
 * vendorDealsList  
 * @param {json} params 
 * @returns json
 */

const _vendorDealsList = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.vendorDeals, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorDealsApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.vendorDealsApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.vendorDealsApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};
/**
 * User Listing  
 * @param {json} params 
 * @returns json
 */

const _userListing = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.userListing, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.userListApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.userListApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.userListApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};
/**
 * Purchase history of user  
 * @param {json} params 
 * @returns json
 */

const _userPurchaseHistory = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.userPurchaseHistory, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.userPurchaseHistoryApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.userPurchaseHistoryApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.userPurchaseHistoryApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};


/**
 * Delete User
 * @param {json} params 
 * @returns json
 */


const _deleteUser = (params) => {
    return async (dispatch) => {
        await axios.delete(ApiUrls.admin + AdminApiList.deleteUser, {data:params})
            .then((response) => {
                if (response) {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.deleteUserApiResponse,
                            true, response.error_code, response.data, response.response_code
                        ));
                }
                else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.deleteUserApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
 * Change User status
 * @param {json} params 
 * @returns json
 */


const _changeUserStatus = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.changeUserStatus, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.changeUserStatusApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.changeUserStatusApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.changeUserStatusApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}


/**
 * Reset Response 
 */

const resetResponse = (dispatch, action) => {
    dispatch(
        {
            type: action, apiStatus: false, apiErrorCode: 0, apiResponse: {}, apiMessage: ""
        }
    );
}

/**
* Admin Forgot Password
* @param {json} params 
* @returns json
*/
//apiEncryptedPost
const _adminForgotPassword = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.forgotPasswordURL, params)
            .then((response) => {
                
                if (response) {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.adminForgotPassword,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.adminForgotPassword,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.adminForgotPassword,
                            false, 100, [], 300
                            ));
                    }
                })
        }
    }


/**
 * subCategory Listing
 * @param {json} params 
 * @returns json
 */
const _subCategoryListing = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.subcategoryListing, params)
            .then((response) => {
                if (response) {
 
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.subCategoryListingApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.subCategoryListingApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.subCategoryListingApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.subCategoryListingApiResponse,
                            false, 100, [], 300
                        ));
                }
            })
    }
}

/**
* Admin Reset Password
* @param {json} params 
* @returns json
*/
//apiEncryptedPost
const _adminResetPassword = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.resetPasswordURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.adminResetPassword,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.adminResetPassword,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.adminResetPassword,
                            false, 100, [], 300
                        ));
                }
            })
    }
}
    
/**
 * category Listing
 * @param {json} params 
 * @returns json
 */
const _categoryListing = (params) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.admin + AdminApiList.categoryListing, {})
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.categoryListingApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.categoryListingApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.categoryListingApiResponse,
                            false, 100, [], 300
                        ));
                }
            })
    }
}

/**
 * Create sub category 
 * @param {json} params 
 * @returns json
 */
const _createSubCategory = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.createSubCategory, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.createSubCategoryApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.createSubCategoryApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.createSubCategoryApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.createSubCategoryApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}
/**
 * List Subscriptions 
 * @param {json} params 
 * @returns json
 */
const _getSubscriptionList = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.admin + AdminApiList.listSubscriptions)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.listSubscriptionApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.listSubscriptionApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.listSubscriptionApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.listSubscriptionApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}


/**
* User Profile Update
* @param {json} params 
* @returns json
*/

const _updateProfile = (params) => {
    return async (dispatch) => {
        await axios.put(ApiUrls.admin + AdminApiList.updateProfile, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.updateProfileApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.updateProfileApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.updateProfileApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.updateProfileApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}

/**
* Admin Update password
* @param {json} params 
* @returns json
*/

//apiEncryptedPost
const _UpdatePassword = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.updatePassword, params)
        .then((response) => {
            if (response) {
                if (response.error_code === constants.STATUS_200) {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.updatePasswordApiResponse,
                            true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.updatePasswordApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.updatePasswordApiResponse,
                                true, response.error_code, [], response.response_code
                                ));
                        }
                    } else {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.updatePasswordApiResponse,
                                false, 100, [], 300
                                ));
                        }
                    })
        
            };
        }
/**
* list Schedule Update
* @param {json} params 
* @returns json
*/

const _adminListSchedule = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.listSchedule, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.listScheduleAPIResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.listScheduleAPIResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.listScheduleAPIResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.listScheduleAPIResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}



/**
* cancel Schedule Update
* @param {json} params 
* @returns json
*/

const _adminCancelSchedule = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.cancelSchedule, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.cancelScheduleAPIResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.cancelScheduleAPIResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.cancelScheduleAPIResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.cancelScheduleAPIResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}

/**
* profile image Update
* @param {json} params 
* @returns json
*/

const _adminProfileImageUpload = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.uploadProfileImage, params)
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.uploadProfileImageApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.uploadProfileImageApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.uploadProfileImageApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.uploadProfileImageApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}

/**
* profile image remove
* @param {json} params 
* @returns json
*/

const _adminProfileImageRemove = () => {
    return async (dispatch) => {
        await axios.delete(ApiUrls.admin + AdminApiList.removeProfileImage, {data:{}})
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.removeProfileImageApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.remove,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.removeProfileImageApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.removeProfileImageApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}
/**
* Delete Subcategory
* @param {json} params 
* @returns json
*/

const _deleteSubCategory = (params) => {
    return async (dispatch) => {
        await axios.delete(ApiUrls.admin + AdminApiList.deleteSubcategory, {data:params})
            .then((response) => {
                if (response) {
                    if (response.error_code === constants.STATUS_200) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.deletesubcategoryApiResponse,
                                true, response.error_code, response.data, response.response_code
                            ));
                    } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.deletesubcategoryApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                    else if (response.error_code === constants.STATUS_401) {
                        return dispatch(
                            prepareResponse(
                                AdminVendorActionTypes.deletesubcategoryApiResponse,
                                true, response.error_code, [], response.response_code
                            ));
                    }
                } else {
                    return dispatch(
                        prepareResponse(
                            AdminVendorActionTypes.removeProfileImageApiResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
}

export { _vendorList, _vendorApprovalList, _vendorBusinessinfo, _changeVendorStatus, _vendorsVerify, _adminProfile, _vendorDealsList, _userListing, _userPurchaseHistory, _deleteUser, _changeUserStatus, resetResponse, _categoryListing, _subCategoryListing, _adminForgotPassword, _adminResetPassword, _createSubCategory, _getSubscriptionList, _updateProfile, _adminListSchedule, _adminCancelSchedule, _UpdatePassword, _adminProfileImageUpload, _adminProfileImageRemove, _deleteSubCategory };


import React, { useState } from 'react'

const Add = (props) => {

  return (
<svg xmlns="http://www.w3.org/2000/svg" width="19.543" height="19.543" viewBox="0 0 19.543 19.543">
  <g id="add" transform="translate(9.798 2) rotate(45)">
    <path id="chevron-left" d="M5.514,11.029,2.068,7.582,0,5.514l2.3-2.3L5.514,0" transform="translate(5.439 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    <path id="chevron-left-2" data-name="chevron-left" d="M0,11.029,3.446,7.582,5.514,5.514l-2.3-2.3L0,0" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </g>
</svg>

  )
};

export default Add;


/**
 * @file   src\actions\VendorAction.js
 * @brief  This file is responsible for vendor actions.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { ProductsApiList, ApiUrls } from "../library/Apis";

// import { apiPost, apiDelete, apiGet, apiEncryptedPost } from "../library/Requests";
import  * as constants from '../utils/constants';
import axios from '../library/Requests';

const ProductsActionType = {
    draftproductsResponse: 'draftproductsResponse',
    listProductToAppResponse: 'listProductToAppResponse',
    addProduct: 'addProduct',
    deleteProduct: 'deleteProduct',
    productImagePreSigned: 'productImagePreSigned',
    removeProductResponse: 'removeProductResponse',
    productDetailsId: 'productDetailsId',
    createProductPayment: 'createProductPayment',
    productPaymentStatus: 'productPaymentStatus',
    freshnessList: 'freshnessList',
    scheduleTimeSlot: 'scheduleTimeSlot',
    deleteaddress: 'deleteaddress'
};


/**
* response
* @param {string} actionType 
* @param {string} apiStatus 
* @param {number} apiErrorCode 
* @param {json} apiResponse 
* @param {string} apiMessage 
* @returns json
*/

export function ProductResponse(actionType, apiStatus, apiErrorCode, apiResponse, apiMessage) {
    return {
        type: actionType, apiStatus, apiErrorCode, apiResponse, apiMessage
    };
}

/**
 * Register vendor
 * @param {json} params 
 * @returns json
 */

const _draftproducts = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.product + ProductsApiList.draftproducts, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.draftproductsResponse,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.draftproductsResponse,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
 * Vendor category list  
 * @param {json} params 
 * @returns json
 */

const _deleteProduct = (params) => {
    return async (dispatch) => {
        await axios.delete(ApiUrls.product + ProductsApiList.deleteProductURL, {data:params})
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.deleteProduct,
                            true, response.error_code, response.data, response.response_code
                        ));
                }
                else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.deleteProduct,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * Add product 
 * @param {json} params 
 * @returns json
 */

const _addProduct = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.product + ProductsApiList.addProductURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.addProduct,
                            true, response.error_code, response.data, response.response_code
                        ));
                }
                else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.addProduct,
                            false, 100, [], 300
                        ));
                }
            })

    }
}


/**
 * Image PreSigned URL 
 * @param {json} params 
 * @returns json
 */
const _productImagePreSignedURL = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.product + ProductsApiList.imagePreSignedURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.productImagePreSigned,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.productImagePreSigned,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.productImagePreSigned,
                            false, 100, [], 300
                        ));
                }
            })

    }
}



/**
 * _list Product To App
 * @param {json} params 
 * @returns json
 */

const _listProductToApp = (params = {}) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.product + ProductsApiList.listProductToApp, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.listProductToAppResponse,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.listProductToAppResponse,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * remove Product 
 * @param {json} params 
 * @returns json
 */

const _removeProduct = (params = {}) => {
    return async (dispatch) => {
        await axios.delete(ApiUrls.product + ProductsApiList.removeimage, {data:params})
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.removeProductResponse,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.removeProductResponse,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * remove Product 
 * @param {json} params 
 * @returns json
 */

const _productsDetailsId = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.product + ProductsApiList.productDetailsById, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.productDetailsId,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.productDetailsId,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Create product payment 
* @param {json} params 
* @returns json
*/
const _createProductPayment = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + ProductsApiList.createProductPaymentURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.createProductPayment,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.createProductPayment,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.createProductPayment,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Product payment status
* @param {json} params 
* @returns json
*/
const _productPaymentStatus = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + ProductsApiList.productPaymentStatusURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.productPaymentStatus,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.productPaymentStatus,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.productPaymentStatus,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* Product freshness list
* @param {json} params 
* @returns json
*/
const _productFreshnessList = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.product + ProductsApiList.productFreshnessListURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.freshnessList,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.freshnessList,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.freshnessList,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
* schedule time slot list
* @param {json} params 
* @returns json
*/
const _scheduleTimeList = (params = {}) => {
    return async (dispatch) => {
        await axios.get(ApiUrls.product + ProductsApiList.scheduleTimeListURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.scheduleTimeSlot,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.scheduleTimeSlot,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.scheduleTimeSlot,
                            false, 100, [], 300
                        ));
                }
            })

    }
}
/**
* Delete proxy address and proxy pincode
* @param {json} params 
* @returns json
*/
const _deleteAddress = (params = {}) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.product + ProductsApiList.deleteaddress, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.deleteaddress,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else if (response && response.error_code  && response.error_code === constants.STATUS_400) {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.deleteaddress,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        ProductResponse(
                            ProductsActionType.scheduleTimeSlot,
                            false, 100, [], 300
                        ));
                }
            })

    }
}

/**
 * Reset Response 
 */

const _resetResponse = (dispatch, action) => {
    dispatch(
        {
            type: action, apiStatus: false, apiErrorCode: 0, apiResponse: {}, apiMessage: ""
        }
    );
}

export { _draftproducts, _listProductToApp, _removeProduct, _productsDetailsId, _deleteProduct, _addProduct, _productImagePreSignedURL, _createProductPayment, _productPaymentStatus, _productFreshnessList, _scheduleTimeList, _deleteAddress, _resetResponse };



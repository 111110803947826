import React, { useState } from 'react'

const InfoTooltip = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="Group_1637" data-name="Group 1637" transform="translate(-250 -1134)">
        <rect id="Rectangle_781" data-name="Rectangle 781" width="18" height="18" transform="translate(250 1134)" fill="none" />
        <path id="Path_31812" data-name="Path 31812" d="M8,0a8,8,0,1,0,8,8A8.009,8.009,0,0,0,8,0M8,4a.667.667,0,1,1-.667.667A.667.667,0,0,1,8,4m2,8H6V10.667H7.333V8H6V6.667H8a.666.666,0,0,1,.667.667v3.333H10Z" transform="translate(251 1135)" fill="#444" opacity="0.5" />
      </g>
    </svg>
  )
};

export default InfoTooltip;


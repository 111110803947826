import React, { useState } from "react";
import "./ForgotPasswordForm.scss";
import { Button, Col } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import Input from "../components/Input";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { _vendorResetPassword } from "../actions/VendorAction";
import { _adminResetPassword } from "../actions/admin/AdminVendorAction";
import { _userResetPassword } from "../actions/UserAction";
import { passwordValidation } from "../helpers/authHelper";
import * as alerts from "../utils/Messages";
import viewpass from "../assets/img/view-password.svg";
import hidepass from "../assets/img/hide-password.svg";

const ResetPasswordForm = (props) => {
  const dispatch = useDispatch();

  // ------- call API from functions and response from redux start ---------
  const vendorResetPassword = (params) =>
    dispatch(_vendorResetPassword(params));

  const adminResetPassword = (params) => dispatch(_adminResetPassword(params));

  const userResetPassword = (params) => dispatch(_userResetPassword(params));

  const [passwords, setPasswords] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [passwordsError, setPasswordsError] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  let pathName = window.location.pathname;

  // handle input change
  const handleChange = (event) => {
    setSubmitClicked(false);
    setPasswords((passwords) => ({
      ...passwords,
      [event.target.name]: event.target.value,
    }));
  };

  // handle validation
  const validation = () => {
    let isValid = true;
    setPasswordsError("");
    let passwordErrorMsg = passwordValidation(
      passwords.new_password,
      "the New Password"
    );
    if (passwordErrorMsg !== "") {
      isValid = false;
      setPasswordsError((passwordsError) => ({
        ...passwordsError,
        ["new_passwordError"]: passwordErrorMsg,
      }));
    }
    if (passwords.confirm_password === "") {
      isValid = false;
      setPasswordsError((passwordsError) => ({
        ...passwordsError,
        ["confirm_passwordError"]: alerts.RESET_PASSWORD_ENTER_CONFIRM_PASSWORD,
      }));
    } else if (passwords.new_password !== passwords.confirm_password) {
      isValid = false;
      setPasswordsError((passwordsError) => ({
        ...passwordsError,
        ["confirm_passwordError"]: alerts.RESET_PASSWORD_DOSENT_MATCH,
      }));
    }
    return isValid;
  };

  // handle submit and api call
  const submit = () => {
    let isValid = validation();
    if (isValid) {
      setSubmitClicked(true);
      if (pathName === `/customer/resetpassword/${props.id}`) {
        userResetPassword({
          token: props.id,
          new_password: passwords.new_password,
          confirm_password: passwords.confirm_password,
        });
      } else {
        if (process.env.REACT_APP_USER_TYPE === "admin") {
          adminResetPassword({
            token: props.id,
            new_password: passwords.new_password,
            confirm_password: passwords.confirm_password,
          });
        } else {
          vendorResetPassword({
            token: props.id,
            new_password: passwords.new_password,
            confirm_password: passwords.confirm_password,
          });
        }
      }
    }
  };

  const submitFormOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  }

  return (
    <div className="forgot-pass-wrap">
      <h1 className="reset-head">
        {process.env.REACT_APP_USER_TYPE === "admin" ? "Admin - " : ""}{" "}
        {Strings.ForgotPassword.Title2}
      </h1>
      <form autoComplete="off">
        <Col lg="12" className="position-relative">
          <Input
            id="new_password"
            label={Strings.ForgotPassword.Label2}
            name="new_password"
            type={viewPassword ? "text" : "password"}
            autoComplete="off"
            maxLength="30"
            value={passwords.new_password}
            onChange={handleChange}
            errorMessage={passwordsError.new_passwordError}
            onKeyDown={submitFormOnKeyDown}
          />
          <span className="view-password position-absolute">
            <img
              src={
                passwords.new_password !== "" && viewPassword
                  ? viewpass
                  : hidepass
              }
              onClick={() => setViewPassword(!viewPassword)}
            />
          </span>
        </Col>
        <Col lg="12" className="position-relative">
          <Input
            id="confirm_password"
            label={Strings.ForgotPassword.Label3}
            name="confirm_password"
            type={viewConfirmPassword ? "text" : "password"}
            autoComplete="off"
            maxLength="30"
            value={passwords.confirm_password}
            onChange={handleChange}
            errorMessage={passwordsError.confirm_passwordError}
            onKeyDown={submitFormOnKeyDown}
          />
          <span className="view-password position-absolute">
            <img
              src={
                passwords.confirm_password !== "" && viewConfirmPassword
                  ? viewpass
                  : hidepass
              }
              onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
            />
          </span>
        </Col>
        {pathName !== `/customer/resetpassword/${props.id}`&&
        <Col className="d-block text-end forgot-pass">
          <Link to="/">{Strings.Register.BasicInfo.Info2}</Link>
        </Col>
        }
        <Button onClick={submit} className="mt-2" disabled={submitClicked}>
          {Strings.ForgotPassword.Btn1}
        </Button>
      </form>
    </div>
  );
};

export default ResetPasswordForm;

import React from 'react'
import { Button, Row, Modal } from 'react-bootstrap'
import Strings from "../../assets/strings/Strings.json"
import BusinessDetails from '../../components/BusinessDetails';
import Business from '../../assets/img/icons/Business';
import Date from '../../assets/img/icons/Date';
import User from '../../assets/img/icons/User';
import Phone from '../../assets/img/icons/Phone';
import Mail from '../../assets/img/icons/Mail';
import Subscription from '../../assets/img/icons/Subscription';
import Address from '../../assets/img/icons/Address';
import { dateToMMMDDYYYY } from '../../helpers/validationHelper';
import { formatPhoneNumber } from '../../helpers/validationHelper';
import * as alerts from '../../utils/Messages';

const AdminVendorDetails = (props) => {

  const show = props.show;
  const type = props.type;
  const vendorDetails = props.vendorDetails;
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [showAcceptConfirmation, setShowAcceptConfirmation] = React.useState(false)
  const [showRejectConfirmation, setShowRejectConfirmation] = React.useState(false)
  const [vendorStatus,setVendorStatus]= React.useState('')

  const handleCloseConfirm = () => {
    setShowConfirmation(false)
    setVendorStatus('')
  }
  const handleAcceptConfirm = () => {
    props.onClick('approve', vendorDetails.vendor_id)
    setShowAcceptConfirmation(false)
  }
  const handleRejectConfirm = () => {
    props.onClick('reject', vendorDetails.vendor_id)
    setShowAcceptConfirmation(false)
  }
  const handleActiveBlockConfirm = () => {
    props.onClick(vendorDetails.vendor_id, vendorStatus)
    setShowConfirmation(false)
    setVendorStatus('')
  }
  const handleShowConfirm = (e) => {
    setShowConfirmation(true)
    setVendorStatus(e)
  }
  return (
    <Modal show={show} onHide={props.handleClose} size="xl" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{Strings.Admin.VendorApprovals.Modal.Title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          type === 'manageVendor' ?
            <Row>

              <BusinessDetails icon={<Business />} label={Strings.Admin.VendorManagement.Modal.Label1} value={vendorDetails.business_name ? vendorDetails.business_name : '-'} />

              <BusinessDetails icon={<Phone />} label={Strings.Admin.VendorManagement.Modal.Label8} value={(vendorDetails.business_phoneno ? formatPhoneNumber(vendorDetails.business_phoneno) : '-')} />


              <BusinessDetails icon={<User />} label={Strings.Admin.VendorManagement.Modal.Label3} value={(vendorDetails.f_name ? vendorDetails.f_name : '-') + ' ' + (vendorDetails.l_name ? vendorDetails.l_name : '-')} />

              <BusinessDetails className={vendorDetails.is_mobileverified === 1 ? 'verified' : ""} icon={<Phone />} label={Strings.Admin.VendorManagement.Modal.Label4} value={(vendorDetails.mob_number ? formatPhoneNumber(vendorDetails.mob_number) : '-')} />

              <BusinessDetails className={vendorDetails.is_emailverified === 1 ? 'verified' : ""} icon={<Mail />} label={Strings.Admin.VendorManagement.Modal.Label5} value={(vendorDetails.e_address ? vendorDetails.e_address : '-')} />


              <BusinessDetails icon={<Subscription />} label={Strings.Admin.VendorManagement.Modal.Label6} value={(vendorDetails.subscription_status ? vendorDetails.subscription_status : '-')} />

            </Row>
            :
            <Row>
              {/* ======== not approved vendor ======  approve or reject content ========*/}

              <BusinessDetails icon={<Business />} label={Strings.Admin.VendorApprovals.Modal.Label1} value={vendorDetails.business_name ? vendorDetails.business_name : '-'} />

              <BusinessDetails icon={<User />} label={Strings.Admin.VendorApprovals.Modal.Label3} value={(vendorDetails.f_name ? vendorDetails.f_name : '-') + ' ' + (vendorDetails.l_name ? vendorDetails.l_name : '-')} />

              <BusinessDetails className={vendorDetails.is_mobileverified === 1 ? 'verified' : ""} icon={<Phone />} label={Strings.Admin.VendorApprovals.Modal.Label5} value={(vendorDetails.mob_number ? formatPhoneNumber(vendorDetails.mob_number) : '-')} />

              <BusinessDetails icon={<Phone />} label={Strings.Admin.VendorApprovals.Modal.Label7} value={(vendorDetails.business_phoneno ? formatPhoneNumber(vendorDetails.business_phoneno) : '-')} />

              <BusinessDetails className={vendorDetails.is_emailverified === 1 ? 'verified' : ""} icon={<Mail />} label={Strings.Admin.VendorApprovals.Modal.Label6} value={(vendorDetails.e_address ? vendorDetails.e_address : '-')} />





            </Row>
        }
        <Row>
          <BusinessDetails icon={<Address />} label="Address"
            value={
              (vendorDetails.u_address1 ? vendorDetails.u_address1.trim() + ', ' : ' ') +
              (vendorDetails.u_address2 ? vendorDetails.u_address2.trim() + ',  ' : ' ') +
              (vendorDetails.u_city ? vendorDetails.u_city.trim() + ',  ' : ' ') +
              (vendorDetails.u_state ? vendorDetails.u_state.trim() + ',  ' : ' ') +
              (vendorDetails.u_zip ? vendorDetails.u_zip.trim() + ' ' : ' ')} />
        </Row>

        {
          type === 'manageVendor' ?
            <Row>

              <BusinessDetails icon={<Date />} label={Strings.Admin.VendorManagement.Modal.Label7} value={vendorDetails.date_of_request ? dateToMMMDDYYYY(vendorDetails.approved_on) : '-'} />

              <BusinessDetails icon={<Date />} label={Strings.Admin.VendorManagement.Modal.Label2} value={vendorDetails.status !== 2 && vendorDetails.approved_on ? dateToMMMDDYYYY(vendorDetails.approved_on) : '-'} />
            </Row>
            :
            <Row>
              <BusinessDetails icon={<Date />} label={Strings.Admin.VendorApprovals.Modal.Label2} value={vendorDetails.date_of_request ? dateToMMMDDYYYY(vendorDetails.date_of_request) : '-'} />
            </Row>
        }

        {
          /* 
           <Col className='content-area'>
             <h3>Description</h3>
             <p>{(vendorDetails.description ? vendorDetails.description : '-')} </p>
           </Col>
          */
        }

      </Modal.Body>
      {
        type === 'manageVendor' ?
          <Modal.Footer className='justify-content-start'>
            <Button variant={vendorDetails.status === 1 ? "primary" : "secondary"} disabled={vendorDetails.status === 1 ? false : true} onClick={() => handleShowConfirm(3)} >{Strings.Admin.VendorManagement.Modal.Btn1}</Button>

            <Button variant={vendorDetails.status === 3 ? "primary" : "secondary"} onClick={() => handleShowConfirm(1)} disabled={vendorDetails.status === 3 ? false : true} >{Strings.Admin.VendorManagement.Modal.Btn2}</Button>

            <Button hidden={vendorDetails.status !== 2} variant={vendorDetails.status === 2 ? "primary" : "secondary"} disabled={vendorDetails.status === 2 ? false : true} onClick={() => props.onClick(vendorDetails.vendor_id, 1)} >{Strings.Admin.VendorManagement.Modal.Btn3}</Button>

          </Modal.Footer>
          :
          <Modal.Footer className='justify-content-start'>
            <Button variant="primary" onClick={()=>setShowAcceptConfirmation(true)} >{Strings.Admin.VendorApprovals.Btn1} </Button>
            <Button variant="outline-primary" onClick={()=>setShowRejectConfirmation(true)} >{Strings.Admin.VendorApprovals.Btn2}  </Button>
          </Modal.Footer>
      }
      <Modal show={showConfirmation} onHide={handleCloseConfirm} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.ACTIVE_BLOCK_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleCloseConfirm}>Cancel</Button>
          <Button variant="primary" onClick={handleActiveBlockConfirm}>OK</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAcceptConfirmation} onHide={()=>setShowAcceptConfirmation(false)} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.ACTIVE_BLOCK_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={()=>setShowAcceptConfirmation(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleAcceptConfirm}>OK</Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show={showRejectConfirmation} onHide={()=>setShowRejectConfirmation(false)} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.ACTIVE_BLOCK_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={()=>setShowRejectConfirmation(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleRejectConfirm}>OK</Button>
        </Modal.Footer>
      </Modal>
    </Modal>
  )
};

export default AdminVendorDetails;


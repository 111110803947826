/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Breadcrumb, Button, Col, Row } from 'react-bootstrap'
import React, { useState, useRef, useEffect } from 'react'
import InnerLayout from '../../components/InnerLayout'
import Strings from '../../assets/strings/Strings.json'
import Input from '../../components/Input';
import viewpass from '../../assets/img/view-password.svg'
import hidepass from '../../assets/img/hide-password.svg'
import {  passwordValidation } from '../../helpers/authHelper';
import { _UpdatePassword, resetResponse } from "../../actions/admin/AdminVendorAction";
import * as constants from '../../utils/constants';
import * as alerts from '../../utils/Messages';
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const AdminChangePass = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updatePassword = (changePasswordData) => dispatch(_UpdatePassword(changePasswordData));
  const updatePasswordResponse = useSelector(
    (RootReducer) => RootReducer.AdminVendorReducer.updatePasswordApiResponse
  );


  const intitalChangePasswordError = {
    emailError: "",
    oldPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: ""
  }
  const intitalChangePassword = {
    email: "",
    password: "",
    old_Password: "",
    confirmPassword: ""
  }


  const [changePasswordData, setChangePasswordData] = useState(intitalChangePassword)
  const [changePasswordError, setChangePasswordError] = useState(intitalChangePasswordError)
  const [updatePasswordClicked, setUpdatePasswordClicked] = useState(false)
  const [viewOldPassword, setViewOldPassword] = useState(false)
  const [viewPassword, setViewPassword] = useState(false)
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false)
  const refPassword = useRef(null);
  const refOldPassword = useRef(null);


  // ------- useEffect start -------
  useEffect(() => {
    let postData = {
      email: changePasswordData.email,
      password: changePasswordData.password,
      old_password: changePasswordData.old_Password,
    }
    if (updatePasswordClicked) {
      updatePassword(postData)
    }
  }, [updatePasswordClicked])


  useEffect(() => {
    if (updatePasswordResponse  && updatePasswordResponse.apiStatus) {
      if (updatePasswordResponse.apiErrorCode === constants.STATUS_200) {
        toast.success(updatePasswordResponse.apiMessage)
        navigate("/admin/profile")
        resetResponse(dispatch, "updatePasswordApiResponse")
      }
      else {
        setUpdatePasswordClicked(false)
        toast.error(updatePasswordResponse.apiMessage)

      }
    }
  }, [updatePasswordResponse]);

  // ------- useEffect End -------


  const handlePasswordChange = (event) => {
    setChangePasswordData(changePasswordData => ({ ...changePasswordData, [event.target.name]: event.target.value }))
  };

  const validation = () => {

    setChangePasswordError(intitalChangePasswordError);
    let isValid = true;
    let focusSet = false;

    if (changePasswordData.old_Password.trim() === "") {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["oldPasswordError"]: alerts.ENTER_OLD_PASSWORD }))
      if (!focusSet) {
        refOldPassword.current.focus();
        focusSet = true;
      }
    }

    if (changePasswordData.password.trim() === "") {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["newPasswordError"]: alerts.ENTER_NEW_PASSWORD }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }
    let passwordErrorMsg = passwordValidation(changePasswordData.password, 'New Password')
    if (passwordErrorMsg !== "") {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["newPasswordError"]: passwordErrorMsg }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }

    if (changePasswordData.confirmPassword === "") {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["confirmPasswordError"]: alerts.REENTER_NEW_PASSWORD }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    } else if (changePasswordData.confirmPassword !== changePasswordData.password) {
      isValid = false;
      setChangePasswordError(changePasswordError => ({ ...changePasswordError, ["confirmPasswordError"]: alerts.RETYPE_PASSWORD_DOSENT_MATCH }))
      if (!focusSet) {
        refPassword.current.focus();
        focusSet = true;
      }
    }
    return isValid;
  };

  const update_Password = () => {
    let isValid = validation();
    if (isValid) {
      setUpdatePasswordClicked(true);
    }
  };

  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='6'>
            <h1>{Strings.Admin.AdminProfile.ChangePassword.Title}</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="/admin/managevendor">{Strings.Admin.AdminProfile.ChangePassword.Label5}</Breadcrumb.Item>
              <Breadcrumb.Item href="/admin/profile">{Strings.Admin.AdminProfile.ChangePassword.Label6}</Breadcrumb.Item>
              <Breadcrumb.Item active>{Strings.Admin.AdminProfile.ChangePassword.Label7}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Col>
      <Col lg='12' className='inner-head text-center'>
        <h2>{Strings.Admin.AdminProfile.ChangePassword.Title2}</h2>
      </Col>
      <Col className='inner-main profile-admin'>
        <Row>
          <Col xl="4">
            <Row>
              <Col lg='12' className='position-relative'>
                <Input
                  label={Strings.Admin.AdminProfile.ChangePassword.Label2}
                  id='oldpassword'
                  name="old_Password"
                  maxLength={15}
                  autoComplete="off"
                  type={viewOldPassword ? 'text' : 'password'}
                  value={changePasswordData.old_Password}
                  onChange={handlePasswordChange}
                  errorMessage={changePasswordError.oldPasswordError}
                  txtRef={refOldPassword}
                />
                <span className='view-password position-absolute'><img src={changePasswordData.old_Password !== "" && viewOldPassword ? viewpass : hidepass} onClick={() => setViewOldPassword(!viewOldPassword)} /></span>
              </Col>
            </Row>
            <Row>
              <Col lg='12' className='position-relative'>
                <Input
                  label={Strings.Admin.AdminProfile.ChangePassword.Label3}
                  id='newpassword'
                  type={viewPassword ? 'text' : 'password'}
                  name='password'
                  maxLength={15}
                  autoComplete="off"
                  value={changePasswordData.password}
                  onChange={handlePasswordChange}
                  errorMessage={changePasswordError.newPasswordError}
                  txtRef={refPassword}
                />
                <span className='view-password position-absolute'><img src={changePasswordData.password !== "" && viewPassword ? viewpass : hidepass} onClick={() => setViewPassword(!viewPassword)} /></span>
              </Col>
            </Row>
            <Row>

              <Col lg='12' className='position-relative'>
                <Input
                  label={Strings.Admin.AdminProfile.ChangePassword.Label4}
                  id='confirmpassword'
                  name='confirmPassword'
                  maxLength={15}
                  autoComplete="off"
                  type={viewConfirmPassword ? 'text' : 'password'}
                  value={changePasswordData.confirmPassword}
                  onChange={handlePasswordChange}
                  errorMessage={changePasswordError.confirmPasswordError}
                />
                <span className='view-password position-absolute'><img src={changePasswordData.confirmPassword !== "" && viewConfirmPassword ? viewpass : hidepass} onClick={() => setViewConfirmPassword(!viewConfirmPassword)} /></span>
              </Col>
            </Row>
          </Col>
        </Row>
        <Col className='btn-wrap mt-4'>
          <Button variant='outline-primary'
            onClick={() => navigate("/admin/profile")}
          >
            {Strings.Admin.AdminProfile.ChangePassword.Btn1}
          </Button>
          <Button variant='primary'
            onClick={update_Password}
          >
            {Strings.Admin.AdminProfile.ChangePassword.Btn2}
          </Button>
        </Col>
      </Col>
    </InnerLayout>

  );
}
export default AdminChangePass;

/**
 * @file   src\helpers\validationHelper.js
 * @brief  This file is responsible for  helper functions like phone number formatting
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import moment from "moment";

/**
 * phone format 
 * @param {number}   
 * @returns string
 */
const formatPhoneNumber = (input) => {
  if (!input || isNaN(input)) return input
  if (typeof (input) !== 'string') input = input.toString()
  if (input.length === 10) {
    return input.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } else if (input.length < 10) {
    return input
  } else if (input.length > 10) {
    return input
  } else {
    return input
  }

};

const dateToDDMMMYY = (input) => {
  if (!input) return input
  return moment(input).format("DD MMM YY")
}
const dateToMMMDDYY = (input) => {
  if (!input) return input
  return moment(input).format("MMM DD, YY")
}
const dateToMMMDDYYYY = (input) => {
  if (!input) return input
  return moment(input).format("MMM DD, YYYY")
}
const dateToMMMDDYYYYUnix = (input) => {
  if (!input) return input
  return moment.unix(input).format("MMM DD, YYYY")
}
const dateToMMDDYYYY = (input) => {
  if (!input) return input
  return moment.unix(input).format("MM-DD-YYYY")
}
const dateToMMDDYYYdate = (input) => {
  if (!input) return input
  return moment(input).format("MM-DD-YYYY")
}
const dateToMMDDYYYSlashFormat = (input) => {
  if (!input) return input
  return moment(input).format("MM/DD/YYYY , h:mm A ")
}

const WeekChange = (val, data) => {
  if (val.includes(7) && data.value.includes(7)) {
    const index = val.indexOf(7);
    if (index > -1) {
      val.splice(index, 1);
      return val
    }
  } else {
    if (val.includes(7)) {
      return [0, 1, 2, 3, 4, 5, 6, 7]
    } else {
      return val
    }
  }
}

const currencyFormat = (num) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export { formatPhoneNumber, dateToDDMMMYY, dateToMMMDDYY, dateToMMMDDYYYY, dateToMMDDYYYY, WeekChange, dateToMMMDDYYYYUnix, dateToMMDDYYYdate, dateToMMDDYYYSlashFormat, currencyFormat };
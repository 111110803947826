/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Button, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import InnerLayout from "../../components/InnerLayout";
import Strings from "../../assets/strings/Strings.json";
import { useDispatch, useSelector } from "react-redux";
import { _adminProfile } from "../../actions/admin/AdminVendorAction";
import * as constants from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber  } from '../../helpers/validationHelper'
import NoImage from '../../assets/img/no-image.svg'

const AdminProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ------- call API from functions and response from redux start ---------
  // vendor profile start
  const vendorProfile = () => dispatch(_adminProfile());
  const vendorProfileResponse = useSelector(
    (RootReducer) => RootReducer.AdminVendorReducer.vendorProfileApiResponse
  );
  // vendor profile ends
  const intitalValuesProfile = {
    e_address: ""
  };

  const [profileData, setProfileData] = useState(intitalValuesProfile);
  

  useEffect(() => {
    vendorProfile();
  }, []);


  useEffect(() => {
    if (vendorProfileResponse && vendorProfileResponse.apiStatus) {
      if (vendorProfileResponse.apiErrorCode === constants.STATUS_200) {
        setProfileData(vendorProfileResponse.apiResponse);
      }
    }
  }, [vendorProfileResponse])

  return (
    <InnerLayout>
      <Col className="action-wrap">
        <Row>
          <Col lg="6">
            <h1>{Strings.Admin.AdminProfile.Profile.Title}</h1>
          </Col>
        </Row>
      </Col>
      <Col lg="12" className="inner-head text-center">
        <h2>{Strings.Admin.AdminProfile.Profile.Title2}</h2>
      </Col>
      <Col className="inner-main profile-admin">
        <Col className="img-wrap m-auto">
            <img src={profileData.p_image ? profileData.p_image : NoImage} alt="User" />
        </Col>
        <p className="name text-center">
          {profileData?.f_name} {profileData?.l_name}
        </p>
        <p className="address text-center">
          {profileData.u_address1 ? profileData.u_address1.trim() + ', ': ""} <br />
          {profileData.u_city ? profileData.u_city.trim() + ', ' : ""} {profileData.u_state ? profileData.u_state.trim() + ', ' : ""} {profileData.u_country ? profileData.u_country.trim() + ', ' : ""}{" "}
          {profileData.u_zip ? profileData.u_zip.trim() : ""}
        </p>
        <div className="contact">
          {profileData.e_address ? <p className="email">{profileData.e_address}</p> : ""}
          {profileData.mob_no ? <p className="phone">{formatPhoneNumber(profileData.mob_no)}</p> : ""}
        </div>
        <Col className="btn-wrap text-center">
          <Button
            variant="outline-primary"
            onClick={() => navigate("/admin/editprofile")}
          >
            {Strings.Admin.AdminProfile.Profile.Btn1}
          </Button>
          <Button variant="primary" onClick={()=>navigate("/admin/changepassword")}>
            {Strings.Admin.AdminProfile.Profile.Btn2}
          </Button>
        </Col>
      </Col>
    </InnerLayout>
  );
};
export default AdminProfile;

/**
 * @file   src\utils\Constants.js
 * @brief  javascript file to config messages used
 * @date   July , 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

export const STATUS_TRUE = true;
export const STATUS_200 = 1000;
export const STATUS_400 = 1001;
export const STATUS_401 = 1002;
export const STATUS_500 = 500;
export const STATUS_0 = 0;
export const STATUS_0_01 = 0.01;
export const STATUS_1 = 1;
export const STATUS_2 = 2;
export const STATUS_3 = 3;
export const STATUS_4 = 4;
export const STATUS_5 = 5;
export const STATUS_6 = 6;
export const STATUS_7 = 7;
export const STATUS_8 = 8;
export const STATUS_10 = 10;
export const STATUS_30000 = 30000;
export const STATUS_FALSE = false;
export const USER_TYPE_VENDOR = 'vendor';
export const USER_TYPE_ADMIN = 'admin';
export const STATUS_UPCOMING = "1";
export const STATUS_TRAILING = "2";
export const STATUS_SUCCESS = "3";
export const STATUS_CANCELED = "4";
export const STATUS_EXPIRED = "5";
export const STATUS_REJECTED = "Rejected";
export const PROMOTIONAL_CITIES = ['New York', 'Los Angeles', 'New Orleans', 'Miami', 'Seattle', 'San Francisco']
export const EFFECTIVE_DATE = '01-17-2024'
export const ZIPCODE_LENGTH = 5;
export const MedianLabel = ['Householder Under 25 Years', 'Householder 25 to 44 Years', 'Householder 44 to 64 Years', 'Householder 65+ Years']
export const RaceLabel = ['White', 'Black', 'Native', 'Asian', 'Islander', 'Other']
export const AgeLabel = ['0-9 years', '10-19 years', '20-29 years', '30-39 years', '40-49 years', '50-59 years', '60-69 years', '70+ years',]
export const HouseholdLabel = ['Families', 'Married Couple Families', 'Single Parent Families', 'Non Families']
export const HouseholdIncomeLabel = ['Less than $25,000', '$25,000 to $49,000', '$50,000 to $74,999', '$75,000 to $99,999', '$100,000 to $149,000', '$150,000 to $199,999', '$200,000+']
export const Label_Generated = "Label Generated"
export const Delivered = "Delivered"
export const Shipped="Accepted"
export const InTransit="In Transit"
export const STATUS_NULL= null
export const DECIMAL_REGEX = /^[0-9]*(\.[0-9]{0,2})?$/
export const MAX_DECIMAL_LENGTH = 6
export const MAX_PHONE_LENGTH = 14
export const MAX_BUSINESSNAME_LENGTH = 50
export const MAX_ADDRESS_LENGTH = 150
export const PLATFORM_CHARGE = 0.10
export const CON_CHARGE = 0.80
export const STRIPE_CHARGE_PENTGE = 2.9
export const STRIPE_CHARGE_CONST = 0.30
export const MAX_PROCEED_LENGTH = 10
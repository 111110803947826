/**
 * @file   src\reducers\VendorReducer.js
 * @brief  This file is responsible for vendor responses.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

const INITIAL_STATE_VENDOR = {
    // vendor list response 
    draftproductsResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
      // list Product To App Response
      listProductToAppResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
     //removeProductResponse
     removeProductResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
     //productDetailsIdResponse
     productDetailsIdResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    deleteProductResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    addProductResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    productImagePreSignedResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    productPaymentResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    productPaymentStatusResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    productFreshnessListResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    scheduleTimeListResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    deleteaddressResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    
};

const ProductActionTypes = {
    draftproductsResponse: 'draftproductsResponse',
    deleteProduct: 'deleteProduct',
    addProduct: 'addProduct',
    productImagePreSigned: 'productImagePreSigned',
    listProductToAppResponse:'listProductToAppResponse',
    removeProductResponse:'removeProductResponse',
    productDetailsId: 'productDetailsId',
    createProductPayment: 'createProductPayment',
    productPaymentStatus: 'productPaymentStatus',
    freshnessList: 'freshnessList',
    scheduleTimeSlot: 'scheduleTimeSlot',
    deleteaddress: 'deleteaddress'
};


const ProductReducer = (state = INITIAL_STATE_VENDOR, action = {}) => {
    switch (action.type) {
        case ProductActionTypes.draftproductsResponse:
            return {
                ...state,
                draftproductsResponse: {
                    ...state.draftproductsResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
            
            case ProductActionTypes.deleteProduct:
            return {
                ...state,
                deleteProductResponse: {
                    ...state.deleteProductResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
     
            case ProductActionTypes.addProduct:
            return {
                ...state,
                addProductResponse: {
                    ...state.addProductResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

            case ProductActionTypes.productImagePreSigned:
            return {
                ...state,
                productImagePreSignedResponse: {
                    ...state.productImagePreSignedResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
     
            case ProductActionTypes.listProductToAppResponse:
            return {
                ...state,
                listProductToAppResponse: {
                    ...state.listProductToAppResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
  
            case ProductActionTypes.removeProductResponse:
            return {
                ...state,
                removeProductResponse: {
                    ...state.removeProductResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
            case ProductActionTypes.productDetailsId:
            return {
                ...state,
                productDetailsIdResponse: {
                    ...state.productDetailsIdResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
            case ProductActionTypes.createProductPayment:
            return {
                ...state,
                productPaymentResponse: {
                    ...state.productPaymentResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
            case ProductActionTypes.productPaymentStatus:
            return {
                ...state,
                productPaymentStatusResponse: {
                    ...state.productPaymentStatusResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
            case ProductActionTypes.freshnessList:
            return {
                ...state,
                productFreshnessListResponse: {
                    ...state.productFreshnessListResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
            case ProductActionTypes.scheduleTimeSlot:
            return {
                ...state,
                scheduleTimeListResponse: {
                    ...state.scheduleTimeListResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
            case ProductActionTypes.deleteaddress:
            return {
                ...state,
                deleteaddressResponse: {
                    ...state.deleteaddressResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

            
        default:
            return state;
    }
}

export default ProductReducer;
/**
 * @file   src\reducers\AdminUserReducer.js
 * @brief  This file is responsible for Admin responses.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

const INITIAL_STATE = {

    userListApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    userPurchaseHistoryApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    deleteUserApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    changeUserStatusApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    adminForgotPasswordResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    adminResetPasswordResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
};


const AdminActionTypes = {
    userListApiResponse: 'userListApiResponse',
    userPurchaseHistoryApiResponse: 'userPurchaseHistoryApiResponse',
    deleteUserApiResponse: 'deleteUserApiResponse',
    changeUserStatusApiResponse: 'changeUserStatusApiResponse',
    adminForgotPassword: 'adminForgotPassword',
    adminResetPassword: 'adminResetPassword'
};

const AdminUserReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case AdminActionTypes.userListApiResponse:
            return {
                ...state,
                userListApiResponse: {
                    ...state.userListApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminActionTypes.userPurchaseHistoryApiResponse:
            return {
                ...state,
                userPurchaseHistoryApiResponse: {
                    ...state.userPurchaseHistoryApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminActionTypes.deleteUserApiResponse:
            return {
                ...state,
                deleteUserApiResponse: {
                    ...state.deleteUserApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminActionTypes.changeUserStatusApiResponse:
            return {
                ...state,
                changeUserStatusApiResponse: {
                    ...state.changeUserStatusApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminActionTypes.adminForgotPassword:
            return {
                ...state,
                adminForgotPasswordResponse: {
                    ...state.adminForgotPasswordResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminActionTypes.adminResetPassword:
            return {
                ...state,
                adminResetPasswordResponse: {
                    ...state.adminResetPasswordResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        default: {
            return state;
        }
    };
};

export default AdminUserReducer;
import React, { useState } from 'react'

const RemoveCategory = (props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
      <path id="delete_FILL0_wght400_GRAD0_opsz48_1_" d="M841,494a1.012,1.012,0,0,1-.7-.4.713.713,0,0,1-.3-.6V483h-1v-1h4v-1h4v1h4v1h-1v9.9a1.025,1.025,0,0,1-1,1h-8Zm8.1-11H841v10.1h8.1Zm-6.1,9h1v-7h-1Zm3,0h1v-7h-1Zm-4.9-8.8Z" transform="translate(-839 -481)" fill="#fff" />
    </svg>

  )
};

export default RemoveCategory;


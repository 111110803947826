/**
 * @file   src\reducers\RootReducer.js
 * @brief  This file is responsible for common declaration for responses.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import {combineReducers} from 'redux';

import AuthReducer from './AuthReducer';
import UserReducer from './UserReducer';
import VendorReducer from './VendorReducer';
import ProductReducer from './ProductReducer';
import AdminVendorReducer from './admin/AdminVendorReducer';
import AdminUserReducer from './admin/AdminUserReducer';

 
const RootReducer =  combineReducers({
    AuthReducer,
    UserReducer,
    VendorReducer,
    ProductReducer,
    AdminVendorReducer,
    AdminUserReducer
});
 

export default RootReducer;
import React from 'react'
import './BusinessDetails.scss'
import { Col } from 'react-bootstrap';
import Verified from '../assets/img/icons/Verified';

const BusinessDetails = (props) => {
  return (
    <Col lg={props.label === 'Address' ? '12' : '4'} className='d-flex bus-details'>
      <div className='icon-wrap d-flex align-items-center justify-content-center'>
        {props.icon}
      </div>
      <div className='details-wrap'>
        <span>{props.label}</span>
        <p className={props.className}>{props.value}{props.className === "verified" ? <Verified /> : ""}</p>
      </div>
    </Col>
  )
};

export default BusinessDetails;


const getAuthToken = () => {
  let userInfo = JSON.parse(localStorage.getItem("vInfo"))

  if (process.env.REACT_APP_USER_TYPE === 'admin') {
    userInfo = JSON.parse(localStorage.getItem("aInfo"));
  }

  //userInfo  is a string not json thts the issue.
  let aToken = ''
  if (userInfo) {

     if (userInfo.a_token) {
      aToken = userInfo.a_token;
    } else if (JSON.parse(userInfo).a_token) {
       aToken = JSON.parse(userInfo).a_token;
    }
  }
  // let aToken = userInfo ? JSON.parse(userInfo).a_token : ""

  return aToken;
}
const getRefreshToken = () => {
  let userInfo = JSON.parse(localStorage.getItem("vInfo"))
  //userInfo  is a string not json thts the issue.
  if (process.env.REACT_APP_USER_TYPE === 'admin') {
    userInfo = JSON.parse(localStorage.getItem("aInfo"));
  }
  let rToken = ''
  if (userInfo) {

     if (userInfo.r_token) {
      rToken = userInfo.r_token;
    } else if (JSON.parse(userInfo).r_token) {
       rToken = JSON.parse(userInfo).r_token;
    }
  }
  return rToken;
}

const removeAuthToken = (authToken) => {
  localStorage.removeItem("AUTH_TOKEN", authToken)
}
const getAuthUser = () => {
  return localStorage.getItem("AUTH_TOKEN_V");
}

const setAuthUser = (authData) => {
  localStorage.setItem("AUTH_TOKEN_V", authData)
};


const setVendor = (vendor) => {
  localStorage.setItem("vInfo", JSON.stringify(vendor))
}

const getVendor = () => {
  let venderInfo = JSON.parse(localStorage.getItem("vInfo"));
  return venderInfo;
};

const setAdmin = (vendor) => {
  localStorage.setItem("aInfo", vendor.toString())
}
const getAdmin = () => {
  localStorage.getItem("aInfo")
}


const removeVendor = () => {
  localStorage.removeItem("vInfo")
}
export { getAuthToken, removeAuthToken, getAuthUser, setAuthUser, setVendor, setAdmin, getVendor, getAdmin, removeVendor, getRefreshToken };
/**
 * @file   src\reducers\AdminReducer.js
 * @brief  This file is responsible for Admin responses.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */




const INITIAL_STATE = {

    vendorListApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorApprovalListApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorBusinessinfoApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorBlockOrActivateApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorsVerifyApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorProfileApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    vendorDealsApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    categoryListingApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    subCategoryListingApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    createSubCategoryApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    updateProfileApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    listScheduleAPIResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    cancelScheduleAPIResponse:  {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    updatePasswordApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    uploadProfileImageApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    removeProfileImageApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
    deletesubcategoryApiResponse: {
        apiStatus: false,
        apiErrorCode: 0,
        apiResponse: {},
        apiMessage: ""
    },
};

const AdminVendorActionTypes = {
    vendorListApiResponse: 'vendorListApiResponse',
    vendorApprovalListApiResponse: 'vendorApprovalListApiResponse',
    vendorBusinessinfoApiResponse: 'vendorBusinessinfoApiResponse',
    vendorBlockOrActivateApiResponse: 'vendorBlockOrActivateApiResponse',
    vendorsVerifyApiResponse: 'vendorsVerifyApiResponse',
    vendorProfileApiResponse: 'vendorProfileApiResponse',
    vendorDealsApiResponse: 'vendorDealsApiResponse',
    categoryListingApiResponse: 'categoryListingApiResponse',
    subCategoryListingApiResponse: 'subCategoryListingApiResponse',
    createSubCategoryApiResponse: 'createSubCategoryApiResponse',
    listSubscriptionApiResponse: 'listSubscriptionApiResponse',
    updateProfileApiResponse:'updateProfileApiResponse',
    listScheduleAPIResponse: 'listScheduleAPIResponse',
    cancelScheduleAPIResponse: 'cancelScheduleAPIResponse',
    updatePasswordApiResponse:'updatePasswordApiResponse',
    uploadProfileImageApiResponse: 'uploadProfileImageApiResponse',
    removeProfileImageApiResponse: 'removeProfileImageApiResponse',
    deletesubcategoryApiResponse: 'deletesubcategoryApiResponse',

}

const AdminVendorReducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case AdminVendorActionTypes.vendorListApiResponse:
            return {
                ...state,
                vendorListApiResponse: {
                    ...state.vendorListApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case AdminVendorActionTypes.vendorApprovalListApiResponse:
            return {
                ...state,
                vendorApprovalListApiResponse: {
                    ...state.vendorApprovalListApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.vendorBusinessinfoApiResponse:
            return {
                ...state,
                vendorBusinessinfoApiResponse: {
                    ...state.vendorBusinessinfoApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.vendorBlockOrActivateApiResponse:
            return {
                ...state,
                vendorBlockOrActivateApiResponse: {
                    ...state.vendorBlockOrActivateApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case AdminVendorActionTypes.vendorsVerifyApiResponse:
            return {
                ...state,
                vendorsVerifyApiResponse: {
                    ...state.vendorsVerifyApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };

        case AdminVendorActionTypes.vendorProfileApiResponse:
            return {
                ...state,
                vendorProfileApiResponse: {
                    ...state.vendorProfileApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.vendorDealsApiResponse:
            return {
                ...state,
                vendorDealsApiResponse: {
                    ...state.vendorDealsApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.categoryListingApiResponse:
            return {
                ...state,
                categoryListingApiResponse: {
                    ...state.categoryListingApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.subCategoryListingApiResponse:
            return {
                ...state,
                subCategoryListingApiResponse: {
                    ...state.subCategoryListingApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.createSubCategoryApiResponse:
            return {
                ...state,
                createSubCategoryApiResponse: {
                    ...state.createSubCategoryApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.listSubscriptionApiResponse:
            return {
                ...state,
                listSubscriptionApiResponse: {
                    ...state.listSubscriptionApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.updateProfileApiResponse:
            return {
                ...state,
                updateProfileApiResponse: {
                    ...state.updateProfileApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.listScheduleAPIResponse:
            return {
                ...state,
                listScheduleAPIResponse: {
                    ...state.listScheduleAPIResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.cancelScheduleAPIResponse:
            return {
                ...state,
                cancelScheduleAPIResponse: {
                    ...state.cancelScheduleAPIResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.updatePasswordApiResponse:
            return {
                ...state,
                updatePasswordApiResponse: {
                    ...state.updatePasswordApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.uploadProfileImageApiResponse:
            return {
                ...state,
                uploadProfileImageApiResponse: {
                    ...state.uploadProfileImageApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.removeProfileImageApiResponse:
            return {
                ...state,
                removeProfileImageApiResponse: {
                    ...state.removeProfileImageApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        case AdminVendorActionTypes.deletesubcategoryApiResponse:
            return {
                ...state,
                deletesubcategoryApiResponse: {
                    ...state.deletesubcategoryApiResponse, apiStatus: action.apiStatus, apiErrorCode: action.apiErrorCode, apiResponse: action.apiResponse, apiMessage: action.apiMessage
                }
            };
        default:
            return state;

    };
};

export default AdminVendorReducer;
/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Button, Col, Row, Table, Modal, Form, Badge } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';
import InnerLayout from '../../components/InnerLayout'
import Paginate from '../../components/Paginate'
import Strings from '../../assets/strings/Strings.json'
import SearchInput from '../../components/SearchInput'
import Filter from '../../assets/img/icons/Filter';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  _vendorList,
  _vendorBusinessinfo,
  _changeVendorStatus,
  _vendorsVerify,
  _getSubscriptionList,
} from "../../actions/admin/AdminVendorAction";
import { resetResponse } from '../../actions/VendorAction';
import { toast } from 'react-toastify';
import { formatPhoneNumber, dateToDDMMMYY } from '../../helpers/validationHelper'
import AdminVendorDetails from '../../components/admin/AdminVendorDetails';
import DatePicker from "react-datepicker";
import moment from 'moment';
import * as constants from '../../utils/constants';
import * as alerts from '../../utils/Messages';

const ManageVendor = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // ------- call API from functions and response from redux start ---------
  // vendor list start
  const vendorList = (vendorListParams) => dispatch(_vendorList(vendorListParams));
  const vendorListApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.vendorListApiResponse)
  // vendor list ends

  const vendorSubscriptionList = () => dispatch(_getSubscriptionList());
  const listSubscriptionApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.listSubscriptionApiResponse)

  //getting business info for a vendor
  const vendorBusinessinfo = (vendorBusinessinfoParams) => dispatch(_vendorBusinessinfo(vendorBusinessinfoParams));
  const vendorBusinessinfoApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.vendorBusinessinfoApiResponse)
  //   change Vendor Status
  const changeVendorStatus = (vendorBlockOrActivateParams) => dispatch(_changeVendorStatus(vendorBlockOrActivateParams));
  const vendorBlockOrActivateApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.vendorBlockOrActivateApiResponse)

  //_vendors_verify
  const vendorsVerify = (vendorsVerifyParams) => dispatch(_vendorsVerify(vendorsVerifyParams));
  const vendorsVerifyApiResponse = useSelector((RootReducer) => RootReducer.AdminVendorReducer.vendorsVerifyApiResponse)
  // vendor list ends

  // ------- call API from functions and response from redux start ---------
  // ------- state start -------
  const intitalVendorListParams = {
    p_SearchKey: "",
    p_PageNumber: 1,
    p_PageSize: 20,
    p_StatusOrder: '',
    p_LocationOrder: 1,
    p_SubcsriptionOrder: ''
  };

  const intitalVendorBusinessinfoParams = {
    vendor_id: 0
  };
  const intitalVendorBlockOrActivateParams = {
    vendor_id: 0,
    status: 0
  };
  const intitaVendorListFilters = {
    p_StatusOrder: '',
    p_LocationOrder: 1,
    p_SubcsriptionOrder: '',
    p_DateFrom: "",
    p_DateTo: ""
  };

  const intitalVendorsVerifyParams = {
    vendor_id: 0,
    status: 0
  };

  const [show, setShow] = useState(false);
  const [modalVendorDetails, setModalVendorDetails] = useState([]);
  const [vendorBusinessinfoParams, setVendorBusinessinfoParams] = useState(intitalVendorBusinessinfoParams)
  const [vendorListParams, setVendorListParams] = useState(intitalVendorListParams)
  const [vendorListResponse, serVendorListResponse] = useState([])
  const [vendorsVerifyParams, setVendorsVerifyParams] = useState(intitalVendorsVerifyParams)
  const [vendorBlockOrActivateParams, setVendorBlockOrActivateParams] = useState(intitalVendorBlockOrActivateParams)
  const [clickedVendorBlockOrActivate, setClickedVendorBlockOrActivate] = useState(false)

  const [Filtershow, FilterShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRejected, setIsRejected] = useState(false)
  const [startDateFilterError, setStartDateFilterError] = useState('')

  const [vendorListFilters, setVendorListFilters] = useState(intitaVendorListFilters)
  const [subscriptionList, setSubscriptionList] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [activeBlockVendorId,setActiveBlockVendorId]= useState('')
  // ------- state end -------

  // ------- useEffect start -------
  // GETTING THE APPROVED   VENDOR LIST
  useEffect(() => {
    vendorSubscriptionList();
  }, [])

  useEffect(() => {

    if (vendorListApiResponse && vendorListApiResponse.apiStatus) {
      if (vendorListApiResponse.apiErrorCode == constants.STATUS_200)
        serVendorListResponse(vendorListApiResponse);
      else
        toast.error(vendorListApiResponse.apiMessage)
    }
  }, [vendorListApiResponse])

  // RECEIVING BUSINES INFO API RESPONSE
  useEffect(() => {


    if (vendorBusinessinfoApiResponse && vendorBusinessinfoApiResponse.apiStatus) {
      if (vendorBusinessinfoApiResponse.apiErrorCode == constants.STATUS_200 && vendorBusinessinfoApiResponse.apiResponse && vendorBusinessinfoApiResponse.apiResponse.business_info && vendorBusinessinfoApiResponse.apiResponse.business_info[0]) {
        setModalVendorDetails(vendorBusinessinfoApiResponse.apiResponse.business_info[0])
      } else {
        toast.error(vendorBusinessinfoApiResponse.apiMessage)
        navigate('/')
      }

    }
  }, [vendorBusinessinfoApiResponse])

  useEffect(() => {

    vendorList(vendorListParams)
  }, [vendorListParams])

  // SHOW POP UP IF THE BUSINESS IFNO RECEIVED FROM REDUX
  useEffect(() => {
    if (modalVendorDetails && modalVendorDetails && modalVendorDetails.vendor_id) {
      setShow(true)
    }
  }, [modalVendorDetails])

  // RESETTING BUSINEES INFO ON CLOSING MODAL POP UP
  useEffect(() => {
    if (!show) {
      setVendorBusinessinfoParams(vendorBusinessinfoParams => ({ ...vendorBusinessinfoParams, vendor_id: 0 }))
      setModalVendorDetails([])
    }
  }, [show])

  // SETTING BUSINEES INFO API PARAMS FOR THE API 
  useEffect(() => {
    if (vendorBusinessinfoParams && vendorBusinessinfoParams.vendor_id) {
      vendorBusinessinfo(vendorBusinessinfoParams)
    }
  }, [vendorBusinessinfoParams])

  // Activate or Block Vendor
  useEffect(() => {
    if (clickedVendorBlockOrActivate && vendorBlockOrActivateParams && vendorBlockOrActivateParams.vendor_id && vendorBlockOrActivateParams.status) {
      changeVendorStatus(vendorBlockOrActivateParams)
    }
  }, [vendorBlockOrActivateParams])
  // Activate or Block Vendor api response


  useEffect(() => {
    if (clickedVendorBlockOrActivate && vendorBlockOrActivateApiResponse && vendorBlockOrActivateApiResponse.apiStatus) {

      if (vendorBlockOrActivateApiResponse.apiErrorCode == constants.STATUS_200) {
        toast.success(vendorBlockOrActivateApiResponse.apiMessage)

        // resetting states
        setClickedVendorBlockOrActivate(false)
        setVendorBlockOrActivateParams(intitalVendorBlockOrActivateParams)

        if (show)
          setShow(false)
        // refreshing page listing
        vendorList(vendorListParams)
      } else
        toast.error(vendorBlockOrActivateApiResponse.apiMessage)
    }
  }, [vendorBlockOrActivateApiResponse])

  //
  useEffect(() => {
    if (vendorsVerifyApiResponse && vendorsVerifyApiResponse.apiStatus) {
      setVendorsVerifyParams(intitalVendorsVerifyParams)
      if (vendorsVerifyApiResponse.apiErrorCode == constants.STATUS_200) {
        setShow(false)
        toast.success(vendorsVerifyApiResponse.apiMessage)
      }
      else {
        toast.error(vendorsVerifyApiResponse.apiMessage)
      }
      resetResponse(dispatch, "vendorsVerifyApiResponse")
    }
  }, [vendorsVerifyApiResponse])

  useEffect(() => {
    let startDates = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    setVendorListFilters(vendorListFilters => ({ ...vendorListFilters, p_DateFrom: startDates }))
    setStartDateFilterError('')
  }, [startDate])

  useEffect(() => {
    let endDates = endDate ? moment(endDate).format('YYYY-MM-DD') : ""
    setVendorListFilters(vendorListFilters => ({ ...vendorListFilters, p_DateTo: endDates }))
    setStartDateFilterError('')
  }, [endDate])


  useEffect(() => {
    console.log(JSON.stringify(listSubscriptionApiResponse))
    if (listSubscriptionApiResponse && listSubscriptionApiResponse.apiStatus) {
      if (listSubscriptionApiResponse.apiErrorCode == constants.STATUS_200 && listSubscriptionApiResponse.apiResponse) {
        setSubscriptionList(listSubscriptionApiResponse.apiResponse)
        resetResponse(dispatch, "listSubscriptionApiResponse")
      }
      else {
        resetResponse(dispatch, "listSubscriptionApiResponse")
      }
    }
  }, [listSubscriptionApiResponse])

  // ------- useEffect End -------

  // page change and state change
  const pageChange = (page) => {
    if (page) {
      setVendorListParams(vendorListParams => ({ ...vendorListParams, p_PageNumber: page }))
    }
  }

  const searchTextChange = (searchText) => {
    setVendorListParams(vendorListParams => ({ ...vendorListParams, p_SearchKey: searchText, p_PageNumber: 1 }))
  }

  const handleClose = () => (setShow(false), setIsRejected(false));

  const showVendorDetails = (vendor) => {
    setVendorBusinessinfoParams(vendorBusinessinfoParams => ({ ...vendorBusinessinfoParams, vendor_id: vendor }))
  }

  const vendorBlockOrActivate = (vendor) => {
    setClickedVendorBlockOrActivate(true)
    if (vendor.vendor_id && (vendor.status === constants.STATUS_1 || vendor.status === 3)) {
      // if isblocked 0, he is active, if isblocked 1, he is inactive
      // NB:: status  -  1 to activate, 3 to block
      let vendorStatus = vendor.status == constants.STATUS_1 ? 3 : 1;
      setVendorBlockOrActivateParams(vendorBlockOrActivateParams => ({ ...vendorBlockOrActivateParams, vendor_id: vendor.vendor_id, status: vendorStatus }))
    }
  }

  const vendorBlockOrActivateFromPopUp = (vendor, vendorStatus) => {
    setClickedVendorBlockOrActivate(true)
    // if isblocked 0, he is active, if isblocked 1, he is inactive
    // NB:: status  -  1 to activate, 2 to block

    setVendorBlockOrActivateParams(vendorBlockOrActivateParams => ({ ...vendorBlockOrActivateParams, vendor_id: vendor, status: vendorStatus }))

  }
  const filterClose = () => FilterShow(false);

  const filterVendorListOptions = (filterType, value) => {
    setVendorListFilters(vendorListFilters => ({ ...vendorListFilters, [filterType]: value }))
  }
  const clearFilterVendorList = () => {
    setVendorListFilters(intitaVendorListFilters)
    setVendorListParams(intitalVendorListParams)
    setStartDate('')
    setEndDate('')
    setStartDateFilterError('')
  }


  const preventKeyPress = (event) => {
    event.preventDefault();
  }

  // set filter options
  const filterVendorList = () => {

    if (vendorListFilters.p_DateTo != '' && vendorListFilters.p_DateFrom == "") {
      setStartDateFilterError(Strings.Admin.VendorManagement.Modal.startDateError)
    } else {
      setStartDateFilterError('')
      setVendorListParams(vendorListParams => ({
        ...vendorListParams,
        p_PageNumber: 1,
        p_StatusOrder: vendorListFilters.p_StatusOrder,
        p_LocationOrder: vendorListFilters.p_LocationOrder,
        p_SubcsriptionOrder: vendorListFilters.p_SubcsriptionOrder,
        p_DateFrom: vendorListFilters.p_DateFrom,
        p_DateTo: vendorListFilters.p_DateTo
      }))
      filterClose()
    }

  }

  const handleSchedule = (id) => {
    navigate("/admin/viewschedules", {
      state: {
        vendor_id : id
      },
    });
  }

  // calling vendor verify api
  const verifyVendorAction = (action, vendorId) => {
    vendorsVerify({ vendor_id: vendorId, status: action })
  }

  const statusAndClass = (status) => {
    let result = { status: 'Pending', statusClass: '', btnText: 'Activate', label: 'Pending' };

    if (status === constants.STATUS_1) {
      result = { status: 'Active', statusClass: 'active', btnText: 'Activate', label: 'Activated' }
    }

    else if (status === constants.STATUS_2) {
      result = { status: 'Rejected', statusClass: 'reject', btnText: 'Reject', label: 'Reject' };
    }

    else if (status === 3) {
      result = { status: 'Blocked', statusClass: 'block', btnText: 'Block', label: 'Block' };
    }
    return result;
  }

  // handle active and block modal 
  const handleCloseActiveBlock = () => { 
    setShowConfirmation(false)
    setActiveBlockVendorId('')
  } 
  const handleShowActiveBlock = (e) => { 
    setShowConfirmation(true)
    setActiveBlockVendorId(e)
  } 
  const handleActiveBlockConfirm = () => { 
    vendorBlockOrActivate(activeBlockVendorId)
    setShowConfirmation(false)
  } 
  // displaying vendor list api response
  const displayVendorList = () => {
    if (vendorListResponse.apiStatus && vendorListResponse.apiResponse) {
      if (vendorListResponse.apiResponse.total_records) {
        let startPage = ((vendorListParams.p_PageNumber - 1) * vendorListParams.p_PageSize);
        return vendorListResponse.apiResponse.vendor_info.map((vendor, index) => {
          let slNo = startPage + (index + 1);

          let vendorStatus = statusAndClass(vendor.status)
          return (
            <tr>
              {/*<td>{slNo < 10 ? '0' + slNo : slNo}</td>*/}
              <td><Link to="" onClick={() => (showVendorDetails(vendor.vendor_id))}>{vendor.b_name ? vendor.b_name : '-'}</Link></td>
              <td>{vendor.e_address ? vendor.e_address : '-'}</td>
              <td>{vendor.mob_number ? formatPhoneNumber(vendor.mob_number) : '-'}</td>
              {/* <td>{vendor.city ? vendor.city : '-'}</td> */}
              {/* <td>{vendor.status == constants.STATUS_2 ? '-' : vendor.createdon ? dateToDDMMMYY(vendor.createdon) : '-'}</td> */}
              <td>{vendor.status == constants.STATUS_2 ? '-' : vendor.subscription_status ? vendor.subscription_status : '-'}</td>
              <td className='action'><Link to="" className={vendorStatus.statusClass} onClick={() =>  vendor.status !== constants.STATUS_2 && handleShowActiveBlock(vendor)}>{vendorStatus.status} </Link></td>
              <td>{vendor.status == constants.STATUS_2 ? '-' : <Link to={`/admin/deals/${vendor.vendor_id}`}>Deals</Link>}</td>
              <td className='action'>{vendorStatus.status !== constants.STATUS_REJECTED ? <Button onClick={() => { handleSchedule(vendor.vendor_id) }} variant='primary'>View</Button> : ""}</td>         
            </tr>
          )
        })
      } else if (!vendorListResponse.apiResponse.total_records) {
        return (
          <tr>
            <td colSpan="9" align='center'>{Strings.Admin.VendorManagement.Label}</td>
          </tr>
        )
      }
    }
  }
  // redirecting to vendor approval
  const goToVendorApprovals = () => {
    navigate('/admin/vendorapproval');
  }

  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='6' className='d-lg-flex align-items-lg-center'>
            <h1>{Strings.Admin.VendorManagement.Title}</h1>
          </Col>
          <Col lg='6' className='text-lg-end'>
            <Button variant='primary' onClick={goToVendorApprovals}>{Strings.Admin.VendorManagement.Btn1}
              {
                vendorListResponse.apiStatus && vendorListResponse.apiResponse && vendorListResponse.apiResponse.total_waiting_for_approval ? <Badge className='pending-aprovals'>{vendorListResponse.apiResponse.total_waiting_for_approval}</Badge> : ""
              }
            </Button>
          </Col>
        </Row>
      </Col>
      <Row className='search-wrap'>
        <Col md="9" lg='5'>
          <SearchInput onClick={searchTextChange} p_SearchKey={vendorListParams.p_SearchKey} />
        </Col>
        <Col md="3" lg="3">
          <Button variant='primary' onClick={() => FilterShow(true)}><Filter />{Strings.Admin.VendorManagement.Btn2}</Button>
        </Col>
      </Row>
      <Col>
        <Table responsive>
          <thead>
            <tr>
              {/* <th>Sl.No</th>*/}
              <th>{Strings.Admin.VendorManagement.Th}</th>
              <th>{Strings.Admin.VendorManagement.Th1}</th>
              <th>{Strings.Admin.VendorManagement.Th2}</th>
              {/* <th>{Strings.Admin.VendorManagement.Th3}</th> */}
              {/* <th>{Strings.Admin.VendorManagement.Th4}</th> */}
              <th>{Strings.Admin.VendorManagement.Th5}</th>
              <th>{Strings.Admin.VendorManagement.Th6}</th>
              <th>{Strings.Admin.VendorManagement.Th7}</th>
              <th>{Strings.Admin.VendorManagement.Th8}</th>
            </tr>
          </thead>
          <tbody>
            {displayVendorList()}
          </tbody>
        </Table>
        {
          vendorListResponse.apiStatus && vendorListResponse.apiResponse && vendorListResponse.apiResponse.total_records ?
            <Col className='d-flex justify-content-center paginate-wrap'>

              <span className='records'>
                {Strings.Pagination.Label} &nbsp;
                {((vendorListParams.p_PageNumber - 1) * vendorListParams.p_PageSize) + 1
                }
                &nbsp; {Strings.Pagination.Label1} &nbsp;
                {((vendorListParams.p_PageNumber - 1) * vendorListParams.p_PageSize) + vendorListResponse.apiResponse.vendor_info.length}
                &nbsp; {Strings.Pagination.Label2} &nbsp;
                {vendorListResponse.apiResponse.total_records}
                &nbsp; {Strings.Pagination.Label3}

              </span>
              <Paginate
                totalRecords={vendorListResponse.apiResponse.total_records}
                currentPage={vendorListResponse.apiResponse.current_page}
                perPage={vendorListResponse.apiResponse.per_page}
                onClick={pageChange}
              />
            </Col> : ""
        }
      </Col>

      {modalVendorDetails && show ? <AdminVendorDetails show={show} handleClose={handleClose} type='manageVendor' vendorDetails={modalVendorDetails} onClick={isRejected ? verifyVendorAction : vendorBlockOrActivateFromPopUp} /> : ""}

      <Modal show={Filtershow} onHide={filterClose} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Admin.UserManagement.FilterModal.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='filter-wrap'>
          <Row>
            <Col lg='4'>
              <span>{Strings.Admin.UserManagement.FilterModal.Subtitle1}</span>
              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label1} name="p_LocationOrder" onClick={() => filterVendorListOptions('p_LocationOrder', 1)} checked={vendorListFilters.p_LocationOrder === constants.STATUS_1 ? true : false} />

              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label2} name="p_LocationOrder" onClick={() => filterVendorListOptions('p_LocationOrder', 2)} checked={vendorListFilters.p_LocationOrder === constants.STATUS_2 ? true : false} />
            </Col>
            <Col lg='4'>
              <span>{Strings.Admin.UserManagement.FilterModal.Subtitle2}</span>
              <Row>
                <Col lg="12">
                  {subscriptionList ? subscriptionList.map((items, index) => (
                    <Form.Check type="radio" aria-label="radio 1" label={items.plan_name} name="p_SubcsriptionOrder" onClick={() => filterVendorListOptions('p_SubcsriptionOrder', items.plan_id)} checked={vendorListFilters.p_SubcsriptionOrder === items.plan_id ? true : false} />
                  )) : ""}
                  <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Subscription.Label6} name="p_SubcsriptionOrder" onClick={() => filterVendorListOptions('p_SubcsriptionOrder', 5)} checked={vendorListFilters.p_SubcsriptionOrder === 5 ? true : false} />
                  <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Subscription.Label5} name="p_SubcsriptionOrder" onClick={() => filterVendorListOptions('p_SubcsriptionOrder', 6)} checked={vendorListFilters.p_SubcsriptionOrder === 6 ? true : false} />
                </Col>
              </Row>
            </Col>
            <Col lg='4'>
              <span>{Strings.Admin.UserManagement.FilterModal.Subtitle3}</span>
              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label5} name="p_StatusOrder" onClick={() => filterVendorListOptions('p_StatusOrder', 1)} checked={vendorListFilters.p_StatusOrder === constants.STATUS_1 ? true : false} />
              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label6} name="p_StatusOrder" onClick={() => filterVendorListOptions('p_StatusOrder', 3)} checked={vendorListFilters.p_StatusOrder === 3 ? true : false} />
              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label9} name="p_StatusOrder" onClick={() => filterVendorListOptions('p_StatusOrder', 2)} checked={vendorListFilters.p_StatusOrder === constants.STATUS_2 ? true : false} />
            </Col>
          </Row>
          <Row className='mt-4'>
            <div className='w-100'><span>{Strings.Admin.UserManagement.FilterModal.Subtitle4}</span></div>
            <Col lg="4">

              <div className='date-picker'>
                <label>{Strings.Admin.UserManagement.FilterModal.Label7}</label>
                <DatePicker onKeyDown={preventKeyPress} maxDate={new Date()} style={startDateFilterError != '' ? { backgroundColor: "red" } : {}} selected={startDate} onChange={(date) => setStartDate(date)} />
                <small className='error' style={startDateFilterError !== '' ? { color: "red" } : {}}>{startDateFilterError}</small>
              </div>
            </Col>
            <Col lg="4">
              <div className='date-picker'>
                <label>{Strings.Admin.UserManagement.FilterModal.Label8}</label>
                <DatePicker onKeyDown={preventKeyPress} selected={endDate} minDate={startDate ? startDate : null} maxDate={new Date()} onChange={(date) => setEndDate(date)} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-start mt-3'>
          <Button variant="outline-primary" onClick={clearFilterVendorList}>{Strings.Admin.UserManagement.FilterModal.Btn1}</Button>
          <Button variant="primary" onClick={filterVendorList}>{Strings.Admin.UserManagement.FilterModal.Btn2}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmation} onHide={handleCloseActiveBlock} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.ACTIVE_BLOCK_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleCloseActiveBlock}>Cancel</Button>
          <Button variant="primary" onClick={handleActiveBlockConfirm}>OK</Button>
        </Modal.Footer>
      </Modal>
    </InnerLayout>

  );
}
export default ManageVendor;

import React, { useState } from 'react'

const Address = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
      <g id="icn_address" transform="translate(-461 -293.139)">
        <rect id="Rectangle_742" data-name="Rectangle 742" width="22" height="22" transform="translate(461 293.139)" fill="none" stroke="none" />
        <g id="Group_1458" data-name="Group 1458">
          <path id="Path_1" data-name="Path 1" d="M15.5,7.25c0,4.861-6.25,9.028-6.25,9.028S3,12.111,3,7.25a6.25,6.25,0,1,1,12.5,0Z" transform="translate(462.75 295.5)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          <circle id="Ellipse_5" data-name="Ellipse 5" cx="3.125" cy="3.125" r="3.125" transform="translate(468.875 299.885)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
      </g>
    </svg>
  )
};
export default Address;

import React, { useState } from 'react'

const User = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.307" height="13.307" viewBox="0 0 13.307 13.307">
      <g id="Group_844" data-name="Group 844" transform="translate(-513.457 -294.968)">
        <path id="Path_31473" data-name="Path 31473" d="M513.957,307.1a5.594,5.594,0,0,1,5.594-5.594h1.119a5.593,5.593,0,0,1,5.594,5.594" transform="translate(0 0.678)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        <circle id="Ellipse_23" data-name="Ellipse 23" cx="3.356" cy="3.356" r="3.356" transform="translate(516.754 295.468)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
      </g>
    </svg>
  )
};

export default User;


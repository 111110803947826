import React from 'react'
import './Register.scss'
import { Pagination } from 'react-bootstrap';

const Paginate = (props) => {

  const totalRecords = props.totalRecords
  const currentPage = props.currentPage
  const perPage = props.perPage

  const prevPage = currentPage >= 1 ? currentPage - 1 : 1;
  const NextPage = currentPage >= 1 ? currentPage + 1 : 1;

  const firstPage = 1;
  const leftAndRight = 4;


  let startPage = 1;
  let startMinus = 0;

  let totalPages = totalRecords % perPage === 0 ? totalRecords / perPage : parseInt(totalRecords / perPage) + 1
  let endPage = totalPages;

  if (totalPages <= 1) {
    startPage = firstPage;
    endPage = totalPages;
  } else {

    const start = currentPage - leftAndRight;
    if (start < firstPage) {
      startMinus = -1 * start;
    }

    startPage = start < firstPage ? firstPage : start;

    let end = currentPage + leftAndRight;
    // adding left side to right side if < 0
    end = end + startMinus;
    endPage = end > totalPages ? totalPages : end;

    let endMinus = endPage - leftAndRight - leftAndRight;
    if (endMinus < startPage && endMinus > 0)
      startPage = endMinus;


  }

  let rightEllipsis = currentPage + 10 > totalPages ? totalPages : currentPage + 10
  let leftEllipsis = currentPage - 10 < firstPage ? firstPage : currentPage - 10

  const pageItem = () => {
    const pages = [];
    for (let i = startPage; i <= endPage; i++) {

      pages.push(
        i === currentPage ?
          <Pagination.Item key={"Item" + i} active onClick={() => props.onClick(i)} >{i}</Pagination.Item>
          : <Pagination.Item key={"Item" + i} onClick={() => props.onClick(i)} >{i}</Pagination.Item>
      );

    }
    return pages;
  };


  return (
    <Pagination>

      {currentPage === startPage ? "" : 
        <Pagination.Prev disabled={currentPage <= startPage ? true : false} onClick={() => props.onClick(prevPage)} className='previous' />
      }

      {
        parseInt(firstPage) === parseInt(startPage) ? "" : <><Pagination.Item onClick={() => props.onClick(firstPage)}>{firstPage} </Pagination.Item></>
      }
      {
        parseInt(startPage) > 2 ? <Pagination.Ellipsis onClick={() => props.onClick(leftEllipsis)} /> : ""
      }
      {pageItem()}

      {(totalPages - endPage > 1) ? <Pagination.Ellipsis onClick={() => props.onClick(rightEllipsis)} /> : ""}

      {
        parseInt(totalPages) === parseInt(endPage) ? "" :
          <Pagination.Item onClick={() => props.onClick(totalPages)}>{totalPages} </Pagination.Item>
      }
      {
        currentPage === totalPages ? "" :
          <Pagination.Next disabled={NextPage > totalPages ? true : false} onClick={() => props.onClick(NextPage)} className='next' />
      }

    </Pagination>
  )
};

export default Paginate;


/**
 * @file   src\helpers\authHelper.js
 * @brief  This file is responsible for login form validation.
 * @date   October, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import * as alerts from '../utils/Messages';

/**
 * login form validation
 * @param {json} loginData 
 * @returns json
 */
const validateLogin = (loginData) => {
  let errors = { email: '', password: '' };
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (!loginData.email) {
    errors.email = alerts.ENTER_EMAIL;
  } else if (!reg.test(loginData.email)) {
    errors.email = alerts.ENTER_VALID_EMAIL;
  }
  if (!loginData.password) {
    errors.password = alerts.ENTER_PASSWORD;
  }
  return errors;
};

/**
 * To handle change password form validation
 * @param {json} formData 
 * @returns json
 */
const validateChangePassword = (formData) => {
  let errors = {};
  var reg = /^(?=.{8,})(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/;
  if (!formData.currPassword) {
    errors.currPassword = alerts.ENTER_CURRENT_PASSWORD;
  }
  if (!formData.newPassword) {
    errors.newPassword = alerts.ENTER_NEW_PASSWORD;
  } else if (!reg.test(formData.newPassword)) {
    errors.newPassword = alerts.ENTER_VALID_PASSWORD;
  } else if (formData.newPassword && formData.currPassword) {
    if (formData.currPassword == formData.newPassword) {
      errors.newPassword = alerts.PASSWORD_SHOULD_NOT_BE_SAME;
    }

  }
  if (!formData.confirmPassword) {
    errors.confirmPassword = alerts.ENTER_CONFIRM_PASSWORD;
  } else if (formData.confirmPassword != formData.newPassword) {
    errors.confirmPassword = alerts.PASSWORD_MISMATCH;
  }
  return errors;
};

const emailValidation = (value) => {
  let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  let result = pattern.test(value)
  return result;
}

const nameValidation = (value) => {
  const specialCharRegExp = /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/;
  const alphanumericRegx = /[0-9]+/g;
  const alphanumericTest = alphanumericRegx.test(value);
  const specialCharCheck = specialCharRegExp.test(value);
  let errMsg = "";
  if (specialCharCheck) {
    errMsg = alerts.SPECIALCHARACTER_ERROR;
  }else if(alphanumericTest) {
    errMsg =alerts.NUMBER_ERROR;
  }
  return errMsg;
}

const passwordValidation = (value, fieldLabel) => {
  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;
  const noSpaceRegExp = /^\S*$/;

  const passwordLength = value.length;
  const uppercasePassword = uppercaseRegExp.test(value);
  const lowercasePassword = lowercaseRegExp.test(value);
  const specialCharPassword = specialCharRegExp.test(value);
  const minLengthPassword = minLengthRegExp.test(value);
  const space = noSpaceRegExp.test(value);

  let errMsg = "";
  if (passwordLength === 0) {
    errMsg = fieldLabel==""? "Enter Password." : "Enter "+fieldLabel+'.';
  } else if (!minLengthPassword) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  } else if (!uppercasePassword) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  } else if (!lowercasePassword) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  } else if (!specialCharPassword) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  } else if (!space) {
    errMsg = alerts.ENTER_VALID_PASSWORD;
  }
  return errMsg;
}

const phoneNumberValidation = (value) => {
  const lengthRegExp = /^\D*(?:\d\D*){10,10}$/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const lengthOfPhoneNumber = lengthRegExp.test(value);
  const specialCharCheck = specialCharRegExp.test(value);

  let errMsg = ""
  if (!lengthOfPhoneNumber) {
    errMsg = alerts.ENTER_VALID_PHONE_NUMBER;
  } else if (specialCharCheck) {
    errMsg = alerts.ENTER_VALID_PHONE_NUMBER;
  } 
  return errMsg;
}

const zipCodeValidation = (value) => {
  let errMsg = ""
  const lengthRegExp = /^\D*(?:\d\D*){15,15}$/
  const lengthOfZipcode = lengthRegExp.test(value);
  if (!lengthOfZipcode) {
    errMsg = alerts.ENTER_VALID_ZIP_CODE;
  }
  return errMsg;
}

const validateImageTypes = (file) => {
  const validExtensions = ['png', 'jpeg', 'jpg', 'PNG', 'JPG', 'JPEG']
  const fileExtension = file.type.split('/')[1]
  const typeIncluded = validExtensions.includes(fileExtension)
  let errMsg = ""
  if (!typeIncluded) {
    errMsg = alerts.IMAGE_TYPE;
  } else if (file.size > 6000000) {
    errMsg = alerts.IMAGE_SIZE
  }
  return errMsg
}
const validateFileTypes = (file) => {
  const validExtensions = ['png', 'jpeg', 'jpg', 'PNG', 'JPG', 'JPEG','pdf']
  const fileExtension = file.type.split('/')[1]
  const typeIncluded = validExtensions.includes(fileExtension)
  let errMsg = ""
  if (!typeIncluded) {
    errMsg = alerts.FILE_TYPE;
  } else if (file.size > 6000000) {
    errMsg = alerts.FILE_SIZE
  }
  return errMsg
}


const checkHttpsLink = (text) => {
  const regex = /^https:\/\/[^\s]+$|^https:\/\/[^\s]+\/[^\s]+$/;
  const isLink = regex.test(text);
  return !!(isLink);
};


export { validateLogin, validateChangePassword, emailValidation, passwordValidation, phoneNumberValidation, zipCodeValidation, validateImageTypes, nameValidation, validateFileTypes, checkHttpsLink };


/**
 * @file   src\library\Requests.js
 * @brief  This file is responsible for handling api calls.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import axios from 'axios';
import * as constants from "../utils/constants";
import { VendorApiList, AdminApiList } from "../library/Apis";
import { getAuthToken, setVendor, getRefreshToken, setAdmin } from '../utils/localStorage';
import { encryptUrls, uploadUrls } from '../utils/encryptionUrls'
import { decryptData, encryptData } from "./Encryption";

// Create axios instance.
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Create a list to hold the request queue
const refreshAndRetryQueue = [];
let isRefreshing = false;


// Set axios headers.
const headers = {};
headers['Access-Control-Allow-Origin'] = '*';
headers['Access-Control-Allow-Headers'] = '*';
headers['Access-Control-Allow-Credentials'] = true;
headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, PATCH, DELETE';
headers['Content-Type'] = 'application/json';
headers.Accept = 'application/json';
headers['Cache-Control'] = 'no-cache';
headers['api_key'] = process.env.REACT_APP_API_KEY;

// Assign default values.
axiosClient.defaults.baseURL = process.env.REACT_APP_USER_TYPE === constants.USER_TYPE_VENDOR ? process.env.REACT_APP_VENDOR_URL : process.env.REACT_APP_ADMIN_URL;
axiosClient.defaults.headers = headers;
axiosClient.defaults.timeout = constants.STATUS_30000;
axiosClient.defaults.maxContentLength = constants.STATUS_200;
axiosClient.defaults.maxRedirects = constants.STATUS_5;
axiosClient.defaults.withCredentials = true;

// Handle interceptor request.

axiosClient.interceptors.request.use(
  async (config) => {
    const token = getAuthToken();
    if (config.headers.baseURL && config.headers.baseURL === process.env.REACT_APP_API_PRODUCT_URL) {
      config.baseURL = process.env.REACT_APP_API_PRODUCT_URL;
    }
    else if (config.headers.baseURL && config.headers.baseURL === process.env.REACT_APP_API_URL_VENDOR) {
      config.baseURL = process.env.REACT_APP_API_URL_VENDOR;
    }
    else if (config.headers.baseURL && config.headers.baseURL === process.env.REACT_APP_API_MONGODB_URL) {
      config.baseURL = process.env.REACT_APP_API_MONGODB_URL;
    }
    if (encryptUrls.includes(config.url)) {
      config.headers.Authorization = `Bearer ${token}`
      const dt = JSON.parse(JSON.stringify(config)).data;
      const encryptJson = await encryptData(dt);
      config.data = encryptJson;
      config.headers['content-type'] = 'text/plain';
      config.headers.Accept = 'text/plain';
    }
    if (uploadUrls.includes(config.url)) {
      config.headers['content-type'] = 'multipart/form-data';
      config.headers.Accept = '*/*';
      config.headers.Authorization = `Bearer ${token}`
    }
      config.headers.Authorization = `Bearer ${token}`
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);


// Handle interceptor response.
axiosClient.interceptors.response.use(
  async (resp) => {
    const contentType = resp.headers.get("content-type");
    const respo = resp.data
    if (resp?.data && resp.data?.error_code === constants.STATUS_401) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const data = await refreshAccessToken(getRefreshToken());
          let newAccessToken ;
          if (process.env.REACT_APP_USER_TYPE === constants.USER_TYPE_ADMIN) {
            newAccessToken=data.responseData.accessToken
            const adminInfo = {
              a_token: data.responseData.accessToken,
              r_token: data.responseData.refreshToken,
            };
            setAdmin(JSON.stringify(adminInfo));
          }
          else {
            newAccessToken=data.data.accessToken
            const vendorInfo = {
              a_token: data.data.accessToken,
              r_token: data.data.refreshToken,
            };
            setVendor(JSON.stringify(vendorInfo));
          }
          resp.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
          refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
            axiosClient
              .request(config)
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          });
          refreshAndRetryQueue.length = 0;
          return axiosClient(resp.config);
        } catch (refreshError) {
          console.error('Failed to refresh access token', refreshError);
          return Promise.reject('Failed to refresh access token');
        } finally {
          isRefreshing = false;
        }
      }
      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: resp.config, resolve, reject });
      });
    } 
    else if (resp.data && resp.data.data && resp.data.data.error_code === constants.STATUS_500) {
      window.location.href = '/';
    } 
    
    else if (contentType && contentType.includes("text/html")) {
      let cipherText = await respo;
      try {
        let response = await decryptData(cipherText);
        return response; // Return the decrypted response
      } catch (decryptError) {
        return Promise.reject(decryptError);
      }
    } 
    
    else {
      try {
        let response = await respo;
        return response; // Return the JSON response
      } catch (jsonError) {
        return Promise.reject(jsonError);
      }
    }
  },
  (error) => {
    if (error.response?.status === constants.STATUS_401) {
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);


const refreshAccessToken = async (rtoken) => {
  try {
    const baseURL = process.env.REACT_APP_USER_TYPE === constants.USER_TYPE_VENDOR ? process.env.REACT_APP_VENDOR_API_URL : process.env.REACT_APP_ADMIN_API_URL;
    const refreshTokenURL = process.env.REACT_APP_USER_TYPE === constants.USER_TYPE_VENDOR ? VendorApiList.refreshTokenURL : AdminApiList.refreshTokenURL;
    const requestURL = baseURL + refreshTokenURL;

    const headers = {
      origin: process.env.REACT_APP_USER_TYPE === constants.USER_TYPE_VENDOR ? process.env.REACT_APP_VENDOR_URL : process.env.REACT_APP_ADMIN_URL,
      referer: process.env.REACT_APP_USER_TYPE === constants.USER_TYPE_VENDOR ? process.env.REACT_APP_VENDOR_URL : process.env.REACT_APP_ADMIN_URL,
      "access-control-allow-origin": process.env.REACT_APP_USER_TYPE === constants.USER_TYPE_VENDOR ? process.env.REACT_APP_VENDOR_URL : process.env.REACT_APP_ADMIN_URL,
      api_key: process.env.REACT_APP_API_KEY,
      "x-refresh-token": rtoken,
      "Access-Control-Allow-Origin": "*",
    };

    const response = await axios.post(requestURL, {}, { headers });
    const responseData = response.data;

    if (process.env.REACT_APP_USER_TYPE === constants.USER_TYPE_ADMIN) {
      if (responseData && responseData.responseData && responseData.responseData.error_code === constants.STATUS_200) {
        return responseData;
      } else {
        window.location.href = '/';
      }
    } else {
      if (responseData && responseData.error_code === constants.STATUS_200) {
        return responseData;
      } else {
        window.location.href = '/';
      }
    }

    return null;
  } catch (error) {
    console.error("Error while refreshing token:", error);
    window.location.href = '/';
    return null;
  }
};

// Export default methods.
export default {
  get: axiosClient.get,
  post: axiosClient.post,
  put: axiosClient.put,
  patch: axiosClient.patch,
  delete: axiosClient.delete,
};

import React, { useState } from 'react'

const EditCategory = (props) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.5" height="13.7" viewBox="0 0 11.5 13.7">
      <g id="Group_648" transform="translate(0.5 1.1)">
        <rect id="Rectangle_741" data-name="Rectangle 741" width="10.7" height="1" transform="translate(-0.5 11.6)" fill="#fff" />
        <path id="Path_31784" data-name="Path 31784" d="M3.3,10.5H-.5V6.7L7.3-1.1,11,2.7ZM.5,9.5H2.8L9.6,2.7,7.3.3.5,7.1Z" fill="#fff" />
      </g>
    </svg>

  )
};

export default EditCategory;


/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Breadcrumb, Button, Col, Row, Form, Modal } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import InnerLayout from "../../components/InnerLayout";
import Strings from "../../assets/strings/Strings.json";
import Input from "../../components/Input";
import { useSelector, useDispatch } from "react-redux";
import { _adminProfile, _updateProfile, resetResponse, _adminProfileImageUpload, _adminProfileImageRemove } from "../../actions/admin/AdminVendorAction";
import * as constants from "../../utils/constants";
import { useNavigate } from 'react-router-dom';
import { phoneNumberValidation } from '../../helpers/authHelper';
import * as alerts from '../../utils/Messages';
import { toast } from 'react-toastify';
import { validateImageTypes } from "../../helpers/authHelper";
import NoImage from '../../assets/img/no-image.svg'
import { usePlacesWidget } from "react-google-autocomplete";



const AdminProfileEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vendorProfile = () => dispatch(_adminProfile());
  const vendorProfileResponse = useSelector(
    (RootReducer) => RootReducer.AdminVendorReducer.vendorProfileApiResponse
  );

  const updateProfile = (profileEditData) => dispatch(_updateProfile(profileEditData));
  const updateProfileResponse = useSelector(
    (RootReducer) => RootReducer.AdminVendorReducer.updateProfileApiResponse
  );

  const imageUpload = (imageData) => dispatch(_adminProfileImageUpload(imageData));
  const imageUploadResponse = useSelector(
    (RootReducer) => RootReducer.AdminVendorReducer.uploadProfileImageApiResponse
  );
  const removeImage = () => dispatch(_adminProfileImageRemove());
  const removeImageResponse = useSelector(
    (RootReducer) => RootReducer.AdminVendorReducer.removeProfileImageApiResponse
  );

  const intitalValuesProfile = {
    address1: "",
    state: "",
    country: "",
    zip: "",
    phoneno: ""
  };
  const intitalErrorValuesProfile = {
    address1Error: "",
    stateError: "",
    countryError: "",
    zipError: "",
    phonenoError: ""
  };

  const [profileEmail, setProfilEmail] = useState("");
  const [profileEditData, setProfileEditData] = useState(intitalValuesProfile);
  const [profileDataError, setProfileDataError] = useState(intitalErrorValuesProfile);
  const [editProfileClicked, setEditProfileClicked] = useState(false);
  const [productPicError, setProductPicError] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [place, setPlace] = useState(null);
  const [loadMap, setLoadMap] = useState(false);
  const [showDelete,setShowDelete] = useState(false)
  const refaddress1 = useRef(null);
  const refstate = useRef(null);
  const refcountry = useRef(null);
  const refzip = useRef(null);
  const refPhoneno = useRef(null);

  const [displayedPhoneNumber, setDisplayedPhoneNumber] = useState("")

  // ------- useEffect start -------
  // useEffect(() => {
  //   if (!loadMap) {
  //     loadGoogleMapScript(() => {
  //       setLoadMap(true)
  //     });
  //   }
  // }, [loadMap]);

  // useEffect(() => {
  //   if (loadMap) {
  //     initPlaceAPI();
  //   }

  // }, [loadMap]);

  useEffect(() => {
    if (place) {
      let address = place;
      let streetNumber = "";
      let routeInfo = "";
      let plusCode = "";
      let neighborhood = "";
      let premise = "";
      address.forEach((item) => {

        if (item.types.indexOf("street_number") >= 0) {
          streetNumber = item.long_name + " ";
          setProfileEditData((profileEditData) => ({
            ...profileEditData,
            address1: streetNumber,
          }));
        }

        if (item.types.indexOf("route") >= 0) {
          routeInfo = streetNumber + item.long_name + " ";
          setProfileEditData((profileEditData) => ({
            ...profileEditData,
            address1: routeInfo,
          }));
        }
        if (item.types.indexOf("plus_code") >= 0) {
          if (streetNumber === "") {
            plusCode = streetNumber + routeInfo + item.long_name + " ";
          } else {
            plusCode = routeInfo + item.long_name + " ";
          }
          setProfileEditData((profileEditData) => ({
            ...profileEditData,
            address1: plusCode,
          }));
        }

        if (item.types.indexOf("neighborhood") >= 0) {
          if (streetNumber === "") {
            neighborhood = streetNumber + routeInfo + plusCode + item.long_name + " ";
          } else {
            neighborhood = routeInfo + plusCode + item.long_name + " ";
          }
          setProfileEditData((profileEditData) => ({
            ...profileEditData,
            address1: neighborhood,
          }));
        }
        if (item.types.indexOf("premise") >= 0) {
          if (streetNumber === "") {
            premise =
              streetNumber +
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          } else {
            premise =
              routeInfo +
              plusCode +
              neighborhood +
              item.long_name +
              " ";
          }
          setProfileEditData((profileEditData) => ({
            ...profileEditData,
            address1: premise,
          }));
        }
        if (item.types.indexOf("administrative_area_level_1") >= 0) {
          setProfileEditData((profileEditData) => ({
            ...profileEditData,
            state: item.long_name,
          }));
        }
        if (item.types.indexOf("country") >= 0) {
          setProfileEditData((profileEditData) => ({
            ...profileEditData,
            country: item.long_name,
          }));
        }
        if (item.types.indexOf("postal_code") >= 0) {
          setProfileEditData((profileEditData) => ({
            ...profileEditData,
            zip: item.long_name,
          }));
        }
      });
    }
  }, [place]);


  useEffect(() => {
    vendorProfile();
  }, []);

  // handle basic phone formatting
  useEffect(() => {
    phoneFormat(profileEditData.phoneno, 1);
  }, [profileEditData.phoneno]);

  useEffect(() => {
    if (vendorProfileResponse && vendorProfileResponse.apiStatus) {
      if (vendorProfileResponse.apiErrorCode === constants.STATUS_200) {
        if (vendorProfileResponse.apiResponse.p_image) {
          setProfileImage(vendorProfileResponse.apiResponse.p_image)
        }
        let profiledata = [vendorProfileResponse.apiResponse]
        setProfilEmail(vendorProfileResponse.apiResponse.e_address);
        let profileDataArray = []
        profiledata.map((profile_Data) => {
          profileDataArray.push({
            address1: profile_Data.u_address1,
            state: profile_Data.u_state,
            country: profile_Data.u_country,
            zip: profile_Data.u_zip,
            phoneno: profile_Data.mob_no
          })
        })
        setProfileEditData(profileDataArray[0])
      }
    }
  }, [vendorProfileResponse]);


  useEffect(() => {
    if (updateProfileResponse && updateProfileResponse.apiStatus) {
      if (updateProfileResponse.apiErrorCode == constants.STATUS_200 && updateProfileResponse.apiResponse) {
        toast.success(updateProfileResponse.apiMessage)
        setEditProfileClicked(false)
        navigate("/admin/profile")
        resetResponse(dispatch, "updateProfileApiResponse")
      }
      else {
        resetResponse(dispatch, "updateProfileApiResponse")
      }
    }
  }, [updateProfileResponse])

  useEffect(() => {
    if (imageUploadResponse && imageUploadResponse.apiStatus) {
      if (imageUploadResponse.apiErrorCode === constants.STATUS_200 && imageUploadResponse.apiResponse) {
        toast.success(imageUploadResponse.apiMessage)
        setProfileImage(imageUploadResponse.apiResponse)
      } else {
        toast.error(imageUploadResponse.apiMessage)
      }
      resetResponse(dispatch, "uploadProfileImageApiResponse")
    }
  }, [imageUploadResponse])

  useEffect(() => {
    if (removeImageResponse && removeImageResponse.apiStatus) {
      if (removeImageResponse.apiErrorCode === constants.STATUS_200 && removeImageResponse.apiResponse) {
        toast.success(removeImageResponse.apiMessage)
        setProfileImage("")
      } else {
        toast.error(removeImageResponse.apiMessage)
      }
      resetResponse(dispatch, "removeProfileImageApiResponse")
    }
  }, [removeImageResponse])



  useEffect(() => {
    if (editProfileClicked) {
      updateProfile(profileEditData)
    }
  }, [editProfileClicked])

  // load google map script
  // const loadGoogleMapScript = (callback) => {

  //   if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
  //     callback();

  //   } else {
  //     const googleMapScript = document.createElement("script");
  //     googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY}&callback=Function.prototype&libraries=places`;
  //     window.document.body.appendChild(googleMapScript);
  //     googleMapScript.addEventListener("load", callback);
  //     setLoadMap(true)
  //   }
  // }

  // ------- useEffect End -------


  // // initialize the google place autocomplete
  // const initPlaceAPI = () => {
  //   setTimeout(() => {
  //     if (window.google) {
  //       let autocomplete = new window.google.maps.places.Autocomplete(refaddress1.current);

  //       new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
  //         let place = autocomplete.getPlace();
  //         setProfileEditData(profileEditData => ({ ...profileEditData, address1: "", u_city: "", state: "", zip: "", country: "" }))
  //         setPlace(place.address_components)
  //       });
  //     }
  //   }, 500)

  // };

  const handleProfileEditChange = (event) => {
    if (event.target.name === 'zip') {
        setProfileEditData(profileEditData => ({ ...profileEditData, [event.target.name]: event.target.value }))
    }
    else if (event.target.name === 'phoneno') {
      if ((/^[-\d\(\)\[\]\s]+$/.test(event.target.value))) {
        const numbers = event.target.value.match(/\d+/g);
        const fullNumber = numbers.join("");
        setProfileEditData(profileEditData => ({ ...profileEditData, [event.target.name]: fullNumber }))
      } else if (!(/\D/.test(event.target.value))) {
        setProfileEditData(profileEditData => ({ ...profileEditData, [event.target.name]: event.target.value }))
      }
    }
    else {
      setProfileEditData(profileEditData => ({ ...profileEditData, [event.target.name]: event.target.value }))
    }
  };

  const phoneFormat = (value, type) => {
    let formattedPhoneNumber = value;
    if (value.length <= 3) {
      formattedPhoneNumber = value;
    } else if (value.length > 3 && value.length <= 6) {
      formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
    } else if (value.length > 6) {
      formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
        3,
        6
      )}-${value.slice(6, 10)}`;
    }
    if (type === constants.STATUS_1) {
      setDisplayedPhoneNumber(formattedPhoneNumber)
    } else
      setDisplayedPhoneNumber(formattedPhoneNumber);
  };

  //validation
  const editProfileValidation = () => {
    let isValid = true;
    let focusSet = false;
    let phoneNumberErrMsg = (profileEditData.phoneno !== "") ? phoneNumberValidation(profileEditData.phoneno) : "";

    setProfileDataError(intitalErrorValuesProfile)

    if (profileEditData.phoneno.trim() === "") {
      isValid = false;
      setProfileDataError(profileDataError => ({ ...profileDataError, ["phonenoError"]: alerts.ENTER_PHONE_NUMBER }))
      if (!focusSet) {
        refPhoneno.current.focus();
        focusSet = true;
      }
    }
    else if (phoneNumberErrMsg !== "") {
      isValid = false;
      setProfileDataError(profileDataError => ({ ...profileDataError, ["phonenoError"]: phoneNumberErrMsg }))
      if (!focusSet) {
        refPhoneno.current.focus();
        focusSet = true;
      }
    }

    if (profileEditData.address1.trim() === "") {
      isValid = false;
      setProfileDataError(profileDataError => ({ ...profileDataError, ["address1Error"]: alerts.ENTER_ADDRESS }))
      if (!focusSet) {
        refaddress1.current.focus();
        focusSet = true;
      }
    }

    if (profileEditData.state.trim() === "") {
      isValid = false;
      setProfileDataError(profileDataError => ({ ...profileDataError, ["stateError"]: alerts.ENTER_STATE }))
      if (!focusSet) {
        refstate.current.focus();
        focusSet = true;
      }
    }

    if (profileEditData.country.trim() === "") {
      isValid = false;
      setProfileDataError(profileDataError => ({ ...profileDataError, ["countryError"]: alerts.ENTER_COUNTRY }))
      if (!focusSet) {
        refcountry.current.focus();
        focusSet = true;
      }
    }
    return isValid;
  };


  const UpdateProfile = () => {
    let isValid = editProfileValidation();
    if (isValid) {
      setEditProfileClicked(true);
    }
  }

  const uploadFile = (e) => {
    setProductPicError("")
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      const fileExtension = img.type.split('/')[1]
      let isValid = validateImageTypes(img);
      if (isValid === "") {
        const formData = new FormData();
        formData.append("fileName", img );
        formData.append("fileType", fileExtension );
        imageUpload(formData)
      } else {
        setProductPicError(isValid);
      }
    }
  }

  const removeProfileImage = () => {
    removeImage()
  }
  // handle Delete image modal.
  const handleCloseDelete = () => {
    setShowDelete(false)
  }
  const handleShowDelete = (e) => {
    setShowDelete(true)
  }

  const handleDeleteButton = () => {
    removeProfileImage();
    setShowDelete(false)
  }
  const { ref } = usePlacesWidget({
    apiKey:process.env.REACT_APP_GOOGLE_MAP_ACCESS_KEY,
    onPlaceSelected: (place) => {
      setPlace(place.address_components)
    },
    options: {
      fields: ['formatted_address', 'address_components', 'geometry', 'name'],
      strictBounds: false,
      types: ['geocode', 'establishment'],
    },
  });
  


  
  return (
    <InnerLayout>
      <Col className="action-wrap">
        <Row>
          <Col lg="6">
            <h1>{Strings.Admin.AdminProfile.EditProfile.Title1}</h1>
            <Breadcrumb>
              <Breadcrumb.Item href="/admin/managevendor">
                {Strings.Admin.AdminProfile.EditProfile.Label4}
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/admin/profile">
                {Strings.Admin.AdminProfile.EditProfile.Label5}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {Strings.Admin.AdminProfile.EditProfile.Label6}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Col>
      <Col lg="12" className="inner-head text-center">
        <h2>{Strings.Admin.AdminProfile.EditProfile.Title2}</h2>
      </Col>
      <Col className="inner-main profile-admin">
        <Row>
          <Col lg="10">
            <div className="img-wrap m-auto">
              <img src={profileImage ? profileImage : NoImage} alt="User" />
            </div>
          </Col>
        </Row>
        <p>
          {productPicError && productPicError !== "" ? (
            <Form.Text className="red">{productPicError}</Form.Text>
          ) : (
            ""
          )}
        </p>
        <Col lg="10" className="profile-img-action text-center">
          <Button variant="outline-primary" onClick={() => { document.getElementById("customFile1").click() }}>
            <input
              class="d-none"
              id="customFile1"
              type="file"
              name="file"
              onChange={(e) => {
                uploadFile(e);
              }}
            />
            {profileImage?Strings.Admin.AdminProfile.EditProfile.Btn1 :Strings.Admin.AdminProfile.EditProfile. Btn5}
          </Button>
        </Col>
        <Col lg="10" className="profile-img-action text-center">
          <Button
            disabled={profileImage === "" ? true : false}
            onClick={() => {handleShowDelete()}}
            variant="outline-secondary"
          >
            {Strings.Admin.AdminProfile.EditProfile.Btn2}
          </Button>
        </Col>
        <Row className="mt-5">
          <Col lg="5">
            <Input
              label={Strings.Admin.AdminProfile.EditProfile.Label1}
              name="email"
              value={profileEmail}
              type="email"
              status="true"
            />
            <Input
              label={Strings.Admin.AdminProfile.EditProfile.Label3}
              name="address1"
              value={profileEditData.address1}
              type="text"
              txtRef={ref}
              onChange={handleProfileEditChange}
              errorMessage={profileDataError.address1Error}
            />
            <Input
              label={Strings.Admin.AdminProfile.EditProfile.Label7}
              name="zip"
              value={profileEditData.zip}
              type="text"
              txtRef={refzip}
              //onChange={handleProfileEditChange}
              errorMessage={profileDataError.zipError}
            />
          </Col>
          <Col lg="5">
            <Input
              label={Strings.Admin.AdminProfile.EditProfile.Label2}
              name="phoneno"
              value={displayedPhoneNumber}
              type="text"
              maxLength={14}
              txtRef={refPhoneno}
              onChange={handleProfileEditChange}
              errorMessage={profileDataError.phonenoError}
            />
            <Input
              label={Strings.Admin.AdminProfile.EditProfile.Label8}
              name="state"
              value={profileEditData.state}
              type="text"
              txtRef={refstate}
              //onChange={handleProfileEditChange}
              errorMessage={profileDataError.stateError}
            />
            <Input
              label={Strings.Admin.AdminProfile.EditProfile.Label9}
              name="country"
              value={profileEditData.country}
              type="text"
              txtRef={refcountry}
              //onChange={handleProfileEditChange}
              errorMessage={profileDataError.countryError}
            />
          </Col>
        </Row>
        <Col className="btn-wrap mt-4">
          <Button
            variant="outline-primary"
            onClick={() => navigate("/admin/profile")}
          >
            {Strings.Admin.AdminProfile.EditProfile.Btn3}
          </Button>
          <Button variant="primary" onClick={UpdateProfile}>
            {Strings.Admin.AdminProfile.EditProfile.Btn4}
          </Button>
        </Col>
      </Col>
      <Modal show={showDelete} onHide={handleCloseDelete} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.DELETE_IMAGE_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleCloseDelete}>Cancel</Button>
          <Button variant="primary" onClick={handleDeleteButton}>OK</Button>
        </Modal.Footer>
      </Modal>
    </InnerLayout>
  );
};
export default AdminProfileEdit;

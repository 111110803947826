/**
 * @file   src\actions\AuthAction.js
 * @brief  This file is responsible for auth actions.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { AdminApiList, VendorApiList, ApiUrls,  } from "../library/Apis";
// import { apiGet, apiPost, apiEncryptedPost } from "../library/Requests";
import axios from '../library/Requests';

const AuthActionTypes = {
    getAuthResponse: 'getAuthResponse',
    getSignoutResponse: 'getSignoutResponse',
    getChangePasswordResponse: 'getChangePasswordResponse',
    adminProfileResponse: 'adminProfileResponse',
    resetToInitialState:'resetToInitialState',
    getAdminSignoutResponse:'getAdminSignoutResponse'
};

/**
 * response
 * @param {string} actionType 
 * @param {string} apiStatus 
 * @param {number} apiErrorCode 
 * @param {json} apiResponse 
 * @param {string} apiMessage 
 * @returns json
 */
export function authResponse(actionType, apiStatus, apiErrorCode, apiResponse, apiMessage) {
    return {
        type: actionType, apiStatus, apiErrorCode, apiResponse, apiMessage
    };
}

/**
 * login user
 * @param {json} params 
 * @returns json
 */

const _loginUser = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.vendor + VendorApiList.loginURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        authResponse(
                            AuthActionTypes.getAuthResponse,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        authResponse(
                            AuthActionTypes.getAuthResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};

/**
 * login user
 * @param {json} params 
 * @returns json
 */

const _resetState = () => {
    return async (dispatch) => {

        return dispatch(
            authResponse(
                AuthActionTypes.resetToInitialState,
                false, 0, [], ''
            ));
    }
};

/**
 * login user admin
 * @param {json} params 
 * @returns json
 */

const _loginUserAdmin = (params) => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + VendorApiList.loginURL, params)
            .then((response) => {
                if (response) {
                    return dispatch(
                        authResponse(
                            AuthActionTypes.getAuthResponse,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        authResponse(
                            AuthActionTypes.getAuthResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};
/**
 * signout user
 * @returns json
 */

const _signoutUser = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.vendor + VendorApiList.signOutURL)
            .then((response) => {
                if (response) {
                     return dispatch(
                        authResponse(
                            AuthActionTypes.getSignoutResponse,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        authResponse(
                            AuthActionTypes.getSignoutResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};


/**
 * signout user
 * @returns json
 */

const _adminProfile = () => {
    return async (dispatch) => {
        await axios.get(ApiUrls.admin + AdminApiList.adminProfile, {})
            .then((response) => {
                if (response) {
                    return dispatch(
                        authResponse(
                            AuthActionTypes.adminProfileResponse,
                            true, response.error_code, response.data, response.response_code
                        ));
                } else {
                    return dispatch(
                        authResponse(
                            AuthActionTypes.adminProfileResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};


/**
 * signout admin
 * @returns json
 */

const _signoutAdmin = () => {
    return async (dispatch) => {
        await axios.post(ApiUrls.admin + AdminApiList.signOutURL,{})
            .then((response) => {
                if (response) {
                     return dispatch(
                        authResponse(
                            AuthActionTypes.getAdminSignoutResponse,
                            true, response.error_code, [], response.response_code
                        ));
                } else {
                    return dispatch(
                        authResponse(
                            AuthActionTypes.getAdminSignoutResponse,
                            false, 100, [], 300
                        ));
                }
            })

    };
};
export { _loginUser, _loginUserAdmin, _signoutUser, _resetState, _adminProfile, _signoutAdmin };
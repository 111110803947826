import React, { useState } from 'react'

const UserInfo = (props) => {
  return (
<svg id="icon-info" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
  <rect id="Rectangle_157" data-name="Rectangle 157" width="44" height="44" fill="#fff" opacity="0"/>
  <g id="Group_863" data-name="Group 863" transform="translate(-431 -575)">
    <path id="Path_95" data-name="Path 95" d="M452.713,588a8.713,8.713,0,1,0,8.713,8.713A8.723,8.723,0,0,0,452.713,588Zm0,4.357a.726.726,0,1,1-.726.726A.726.726,0,0,1,452.713,592.357Zm2.178,8.714h-4.357v-1.452h1.452v-2.9h-1.452v-1.452h2.178a.726.726,0,0,1,.726.726v3.631h1.452Z"/>
  </g>
</svg>
  )
};

export default UserInfo;


import React, { useState } from 'react'

const ClearSearch = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g id="close" transform="translate(-634 -283)">
        <rect id="Rectangle_736" data-name="Rectangle 736" width="14" height="14" transform="translate(634 283)" fill="none" />
        <path id="line" d="M6,15,5,14l4-4L5,6,6,5l4,4,4-4,1,1-4,4,4,4-1,1-4-4Z" transform="translate(631 280)" />
      </g>
    </svg>
  )
};

export default ClearSearch;


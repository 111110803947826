/**
 * @file   src\library\Encryption.js
 * @brief  This file is responsible for handling encrypting and decrypting api calls.
 * @date   November, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */


import CryptoJS from "crypto-js";

const encryptData = async (plainText) => {
  try {
    var Key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_PASSWORD);
    var IV = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);
    var encryptedText = CryptoJS.AES.encrypt(JSON.stringify(plainText), Key, {
      // 4. Use decrypt instead of encrypt
      iv: IV,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });
    const encryptedData = encryptedText.toString(CryptoJS.format.Base64);
    return encryptedData;
  } catch (error) {
    console.error("Error decrypting data:", error);
    throw error;
  }
};

const decryptData = async (encryptedData) => {
  try {
    var Key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_PASSWORD);
    var IV = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_IV);
    var decryptedText = CryptoJS.AES.decrypt(encryptedData, Key, {
      iv: IV,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });
    const decryptedData = decryptedText.toString(CryptoJS.enc.Utf8);
    const decryptedJSON = JSON.parse(decryptedData);
    return decryptedJSON;
  } catch (error) {
    throw error;
  }
};

export { decryptData, encryptData };
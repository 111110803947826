/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import React, { useState, useRef, useEffect } from 'react'
import '../assets/styles/register.scss'
import InnerLayout from '../components/InnerLayout';
import ForgotPasswordForm from '../components/ForgotPasswordForm';


const ForgotPassword = (props) => {

  return (
    <InnerLayout hideHeader={props.hideHeader}>
      <ForgotPasswordForm />
    </InnerLayout>
  );
}
export default ForgotPassword;

import React, { useState, useEffect } from 'react'
import './SchedulesView.scss'
import { Col, Row, Button, Modal } from 'react-bootstrap';
import Strings from '../assets/strings/Strings.json'
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProductsImgs from './ProductsImgs';
import NoImage from '../assets/img/no-image.svg'
import * as constants from '../utils/constants';
import * as alerts from "../utils/Messages";

const SchedulesView = (props) => {
  const ScheduleData = props.Data
  const [modalSize, setModalSize] = useState("xl");
  const ProductImageCount = ScheduleData ? ScheduleData.products.length : ""
  const handleCancelSubscription = props.handleCancelSubscription
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const initialFromDate = new Date(ScheduleData.startdate);
  const initialEndDate = new Date(ScheduleData.enddate);
  const from_date = initialFromDate.getTime();
  const end_date = initialEndDate.getTime();
  const getNumberWithsuffixes = (n) => {
    if (n === "") {
      return ""
    } else {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = n % 100;
      return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0])
    }
  }


  useEffect(() => {
    if (ProductImageCount && ProductImageCount === 1) {
      setModalSize("sm")
    } else if (ProductImageCount && ProductImageCount === 2) {
      setModalSize("md")
    } else if (ProductImageCount && ProductImageCount === 3) {
      setModalSize("lg")
    } else if (ProductImageCount && ProductImageCount === 4) {
      setModalSize("xl")
    }
  }, [])

  const handleDeleteButton = () => {
    handleCancelSubscription(ScheduleData?.scheduleid)
    handleCloseDelete()
  }

  return (
    <>
      <div className='schedules-outer'>
        <Row>
          <Col lg="3">
            {ScheduleData.products && ScheduleData.products.length === 1 &&
              <div className='img-view single'>
                <div className='prod-img-single'>
                  <img src={(ScheduleData.products[0] && ScheduleData.products[0].product_image) ? ScheduleData.products[0].product_image : NoImage} alt="" />
                </div>
              </div>
            }
            <div className='img-view'>
              <div className='prod-img d-flex'>
                {ScheduleData.products && ScheduleData.products.length > 1 &&
                  <img src={(ScheduleData.products[0] && ScheduleData.products[0].product_image) ? ScheduleData.products[0].product_image : NoImage} alt="" />
                }
                {ScheduleData.products && ScheduleData.products.length >= 2 &&
                  <img className='mb-8' src={(ScheduleData.products[1] && ScheduleData.products[1].product_image) ? ScheduleData.products[1].product_image : NoImage} alt="" />
                }
              </div>
              <div className='prod-img d-flex'>
                {ScheduleData.products && ScheduleData.products.length >= 3 &&
                  <img src={(ScheduleData.products[2] && ScheduleData.products[2].product_image) ? ScheduleData.products[2].product_image : NoImage} alt="" />
                }
                {ScheduleData.products && ScheduleData.products.length === 4 &&
                  <img src={(ScheduleData.products[3] && ScheduleData.products[3].product_image) ? ScheduleData.products[3].product_image : NoImage} alt="" />
                }
                {ScheduleData.products && ScheduleData.products.length > 4 &&
                  <div className='count d-flex justify-content-center align-items-center'>+{ScheduleData.products.length - 3}</div>
                }
              </div>
            </div>
          </Col>
          <Col lg="7" className='schedule-view'>
            <div className='schedule-view-inner'>
              <h3>{Strings.Dashboard.ViewSchedules.Title}{Strings.Dashboard.ViewSchedules.Label5}</h3>
              <Row>
                <Col md="6">
                  <div className="date-picker">
                    <label>{Strings.Dashboard.ViewSchedules.Label1}</label>
                    <DatePicker
                      disabled
                      selected={from_date && new Date(from_date)}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="date-picker">
                    <label>{Strings.Dashboard.ViewSchedules.Label2}</label>
                    <DatePicker
                      disabled
                      selected={end_date && new Date(end_date)} />
                  </div>
                </Col>

                <Col md="12">
                  <p className='schedule-info'>The notification will be sent on "{getNumberWithsuffixes(ScheduleData?.date)}" of every month between {ScheduleData?.timeslot} till  {moment(end_date).format('MM/DD/YYYY')}.</p>
                </Col>
              </Row>
            </div>

          </Col>
          <Col lg="2" className='action-btn'>
            <Button variant='primary' onClick={() => setShow(true)}>{Strings.Dashboard.ViewSchedules.Btn1}</Button>
            <Button variant='outline-primary' onClick={() => handleShowDelete()}>{Strings.Dashboard.ViewSchedules.Btn2}</Button>
          </Col>
        </Row>
      </div>
      <Modal show={show} onHide={handleClose}
        centered
        backdrop="static"
        size={modalSize}
      >
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Dashboard.ProductsModal.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {ScheduleData.products && ScheduleData.products.length > 0 && (
              ScheduleData.products.map((data, key) => {
                return (
                  <ProductsImgs image={data.product_image ? data.product_image : NoImage} name={data?.product_name} modalSize={modalSize} />
                )
              }))}
            <h5>{Strings.Dashboard.ViewSchedules.Label6} {ScheduleData.notificationtype === 0 || ScheduleData.notificationtype === 1 ? Strings.Dashboard.ViewSchedules.Label7 : Strings.Dashboard.ViewSchedules.Label9}</h5>

            {ScheduleData.notificationtype === 0 &&
              <>
                <p>{ScheduleData?.d_address1 || ""}
                  {ScheduleData?.d_city ? ", " +
                    ScheduleData?.d_city : ""}
                  {ScheduleData?.d_state ? ", " +
                    ScheduleData?.d_state : ""}
                  {ScheduleData?.d_zip ? ", " +
                    ScheduleData?.d_zip : ""}
                  {ScheduleData?.d_country ? ", " +
                    ScheduleData?.d_country : ""}
                </p>
                {/* <p>{ScheduleData?.d_address1.trim()}, {ScheduleData?.d_city.trim()}, {ScheduleData?.d_country.trim()}, {ScheduleData?.d_state.trim()}, {ScheduleData?.d_zip.trim()}.</p> */}
              </>
            }
            {ScheduleData.notificationtype === constants.STATUS_2 &&
              <>
                <div className='zip-list'>
                  <ul>
                    {ScheduleData.zipcodes && ScheduleData.zipcodes.length > 0 && (
                      ScheduleData.zipcodes.map((data, key) => {
                        return (
                          <li>{data}</li>
                        )
                      }))}
                  </ul>
                </div>

              </>
            }
            {ScheduleData.notificationtype === constants.STATUS_1 &&
              <>
                <p>{ScheduleData?.address.trim()}, {ScheduleData?.city.trim()}, {ScheduleData?.country.trim()}, {ScheduleData?.state.trim()}, {ScheduleData?.zip.trim()}.</p>
              </>
            }
          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={showDelete} onHide={handleCloseDelete} size="l" centered backdrop="static">
        <Modal.Body >
          <h5 className="text-center"> {alerts.CANCEL_SCHEDULE} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={handleCloseDelete}>Cancel</Button>
          <Button variant="primary" onClick={handleDeleteButton}>OK</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

export default SchedulesView;


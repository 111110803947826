import React, { useState, useEffect } from 'react'
import './ProductsImgs.scss'
import { Col } from 'react-bootstrap'

const ProductsImgs = (props) => {
  const modalSize = props.modalSize
  const [md, setMd] = useState("");
  const [lg, setLg] = useState("");
  const [xl, setXl] = useState("");

  useEffect(() => {
    if (modalSize && modalSize === "sm") {
      setMd("");
      setLg(12)
      setXl("")
    }
    else if (modalSize && modalSize === "md") {
      setMd("");
      setLg(6)
      setXl("")
    }
    else if (modalSize && modalSize === "lg") {
      setMd("");
      setLg(4)
      setXl("")
    }
    else if (modalSize && modalSize === "xl") {
      setMd(6);
      setLg(4)
      setXl(3)
    }
    else {
      setMd(6);
      setLg(4)
      setXl(3)
    }
  }, [modalSize])
  return (
    <Col md={md} lg={lg} xl={xl}>
      <div className='productsimgs'>
        <div className='img-wrap'>
          <img src={props.image} alt="" />
        </div>
        <h4>{props.name}</h4>
      </div>
    </Col>
  )
};

export default ProductsImgs;


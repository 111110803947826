import React from 'react'
import './DealsAdmin.scss'
import { Col } from 'react-bootstrap';

const DealsAdmin = (props) => {
  return (
    <Col md='6' lg='4' xl='3' className='deals-wrap'>
      <div className='deals'>
        <div className='img-wrap'>
          <img src={props.img} />
        </div>
        <span className='title d-block'>
          {props.title}
        </span>
        <div className='bottom-wrap d-flex justify-content-between align-items-center'>
          <span className='price text-center'>${props.price}</span>
        </div>
      </div>
    </Col>
  )
};

export default DealsAdmin;


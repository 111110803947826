import React from 'react'
import { Container } from 'react-bootstrap';
import Header from './Header';

const InnerLayout = (props) => {


  return (
    <main>
      <Header hideHeader={props.hideHeader} id={props.id}/>
      <Container fluid>
        <Container className={'content-wrap ' + props.className}>
          {props.children}
        </Container>
      </Container>
    </main>
  )
};

export default InnerLayout;


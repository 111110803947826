/**
 * @file   src\
 * @brief  This file is responsible for
 * @date   jan, 2023
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import React, { useState, useEffect } from "react";
import "../assets/styles/register.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetResponse } from "../actions/VendorAction";
import { toast } from "react-toastify";
import InnerLayout from "../components/InnerLayout";
import ResetPasswordForm from "../components/ResetPasswordForm";
import ResetPassSuccess from "../components/ResetPassSuccess";
import  * as constants from '../utils/constants';

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const naviagte = useNavigate()

  let { id } = useParams();

  // vendor reset password response
  const vendorResetPasswordResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorResetPasswordResponse
  );

  const adminResetPasswordResponse = useSelector(
    (RootReducer) => RootReducer.AdminUserReducer.adminResetPasswordResponse
  );
  
  const userResetPasswordResponse = useSelector(
    (RootReducer) => RootReducer.UserReducer.userResetPasswordResponse
  );

  const [switchCase, setSwitchCase] = useState(false);

  // handle reset password response
  useEffect(() => {
    if (vendorResetPasswordResponse && vendorResetPasswordResponse.apiStatus) {
      if (
        vendorResetPasswordResponse &&
        vendorResetPasswordResponse.apiErrorCode === constants.STATUS_200
      ) {
        setSwitchCase(true);
      } else if (
        vendorResetPasswordResponse &&
        vendorResetPasswordResponse.apiErrorCode === constants.STATUS_400
      ) {
        toast.error(vendorResetPasswordResponse.apiMessage);
        naviagte("/")
      }
      resetResponse(dispatch, "resetPassword");
    }
  }, [vendorResetPasswordResponse]);

  // handle reset password response
  useEffect(() => {
    if (adminResetPasswordResponse && adminResetPasswordResponse.apiStatus) {
      if (
        adminResetPasswordResponse &&
        adminResetPasswordResponse.apiErrorCode === constants.STATUS_200
      ) {
        setSwitchCase(true);
      } else if (
        adminResetPasswordResponse &&
        adminResetPasswordResponse.apiErrorCode !== constants.STATUS_200
      ) {
        toast.error(adminResetPasswordResponse.apiMessage);
      }
      resetResponse(dispatch, "adminResetPassword");
    }
  }, [adminResetPasswordResponse]);

  // handle user reset password response
  useEffect(() => {
    if (userResetPasswordResponse && userResetPasswordResponse.apiStatus) {
      if (
        userResetPasswordResponse &&
        userResetPasswordResponse.apiErrorCode === constants.STATUS_200
      ) {
        setSwitchCase(true);
      } else if (
        userResetPasswordResponse &&
        userResetPasswordResponse.apiErrorCode !== constants.STATUS_200
      ) {
        toast.error(userResetPasswordResponse.apiMessage);
      }
      resetResponse(dispatch, "resetUserPassword");
    }
  }, [userResetPasswordResponse]);

  const resetCompleteHide = () => {
    setSwitchCase(false);
  };
  return (
    <InnerLayout hideHeader={props.hideHeader} id={id}>
      {switchCase ? (
        <ResetPassSuccess onPress={resetCompleteHide} id={id}/>
      ) : (
        <ResetPasswordForm id={id} />
      )}
    </InnerLayout>
  );
};
export default ResetPassword;

import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import * as constants from '../utils/constants'

// Register the necessary components from Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChartVertical = (props) => {

    // Data configuration for a grouped bar chart
    const dataGroup = {
        labels: props.label, // Labels for the x-axis
        datasets: [{
            data: props.value, // Data for the first group of bars
            backgroundColor: [
                '#94da0a' // Color for the first group of bars
            ],
            borderColor: [
                '#94da0a' // Border color for the first group of bars
            ],
            borderWidth: 0.1 // Border width for the first group of bars
        },
        {
            data: props.value2, // Data for the second group of bars
            backgroundColor: [
                '#F9C901' // Color for the second group of bars
            ],
            borderColor: [
                '#F9C901' // Border color for the second group of bars
            ],
            borderWidth: 0.1 // Border width for the second group of bars
        }]
    };

    // Configuration options for the bar chart
    const options = {
        plugins: {
            legend: {
                display: false, // Hide the legend
            },
            tooltip: {
                enabled: true, // Enable tooltips
            },
        },
        indexAxis: props.indexAxis ? props.indexAxis : 'y', // Display the bars horizontally
        scales: {
            x: {
                ticks: {
                    padding: 10, // Padding for the y-axis labels
                    maxRotation: 45, // Prevent label rotation
                    autoSkip: false, // Do not skip labels                    
                },
                beginAtZero: true, // Start the x-axis at zero
                min: 0, // Minimum value for the x-axis
            },
            y: {
                ticks: {
                    callback: function (value) {
                        if (value >= 1000) {
                            return value / 1000 + 'k'; // Display large numbers in 'k' format
                        }
                        else if (value == 0) {
                            return 0;
                        }
                        else {
                            return value; // Display the value
                        }
                    },
                },
                beginAtZero: true // Start the y-axis at zero
            }
        }
    };

    return (
        <div>
            <h2>{props.title}</h2> {/* Display the chart title */}
            <Bar data={dataGroup} options={options} /> {/* Render the bar chart */}
        </div>
    );
};

export default BarChartVertical;

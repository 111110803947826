/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Nov, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */
import { Button, Col } from 'react-bootstrap';
import InnerLayout from "../components/InnerLayout";
import Strings from '../assets/strings/Strings.json';
import Errorimg from '../assets/img/404.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { _vendorProfile } from '../actions/VendorAction';
import { _adminProfile } from '../actions/AuthAction';
import  * as constants from '../utils/constants';


const NoPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let pathName = window.location.pathname;

  const vendorProfile = () => dispatch(_vendorProfile());
  const vendorProfileResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorProfileResponse);

  const adminProfile = () => dispatch(_adminProfile());
  const adminProfileResponse = useSelector((RootReducer) => RootReducer.AuthReducer.adminProfileResponse);

  const [redirectTo, setRedirectTo] = useState('/')
  const [redirectText, setRedirectText] = useState('Go to Login')

  useEffect(() => {
    process.env.REACT_APP_USER_TYPE == 'admin' ? adminProfile() : vendorProfile()
  }, [])


  useEffect(() => {
    if (process.env.REACT_APP_USER_TYPE == 'admin') {
      if (adminProfileResponse && adminProfileResponse.apiStatus) {
        if (adminProfileResponse.apiErrorCode == constants.STATUS_200) {
          setRedirectTo('/admin/managevendor')
          setRedirectText("Go to Vendor Management")
        }

        else if (pathName.indexOf("/resetpassword") === 0) {
          setRedirectTo('/')
          setRedirectText("Go to Vendor Management")
        }
      } else {
        setRedirectTo('/')
        setRedirectText("Go to Login")
      }
    }
  }, [adminProfileResponse])

  useEffect(() => {
    if (process.env.REACT_APP_USER_TYPE == 'vendor') {
      if (vendorProfileResponse && vendorProfileResponse.apiStatus) {
        if (vendorProfileResponse.apiErrorCode == constants.STATUS_200) {
          setRedirectTo('/dashboard')
          setRedirectText("Go to Dashboard")
        } else if (pathName.indexOf("/resetpassword") === 0) {
          setRedirectTo('/')
          setRedirectText("Go to Login")
        }
      } else {
        setRedirectTo('/')
        setRedirectText("Go to Login")
      } 
    }

  }, [vendorProfileResponse])

  const reddirectTo = () => {
    navigate(redirectTo)
  }

  return (
    <InnerLayout className='not-found text-center d-flex align-items-center' hideHeader={props.hideHeader} >
      <Col className='error-cont'>
        <img src={Errorimg} />
        <h3>{Strings.NotFound.Title}</h3>
        <p>{Strings.NotFound.Content}</p>
        <Button variant='primary' onClick={reddirectTo}>{redirectText}</Button>
      </Col>
    </InnerLayout>
  );
};
export default NoPage;
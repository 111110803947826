import React, { useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';

const Verified = (props) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Verified
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_532" data-name="Rectangle 532" width="18" height="18" fill="#00a706" />
          </clipPath>
        </defs>
        <g id="Group_878" data-name="Group 878" clip-path="url(#clip-path)">
          <path id="Path_31484" data-name="Path 31484" d="M1.311,10.742a2.919,2.919,0,0,1,.722.622,3.024,3.024,0,0,1-.231,1,2.417,2.417,0,0,0-.076,2.162c.441.632,1.273.612,2.009.594h0a2.8,2.8,0,0,1,.967.068,3.051,3.051,0,0,1,.355.929c.208.739.444,1.574,1.172,1.824h0A1.144,1.144,0,0,0,6.589,18a2.785,2.785,0,0,0,1.563-.8A2.734,2.734,0,0,1,9,16.649a2.733,2.733,0,0,1,.856.552c.573.457,1.223.98,1.925.739.726-.246.962-1.084,1.172-1.822h0a3.039,3.039,0,0,1,.355-.928,2.728,2.728,0,0,1,.965-.07c.735.018,1.563.044,2.009-.594a2.4,2.4,0,0,0-.077-2.163,3.065,3.065,0,0,1-.235-1,2.906,2.906,0,0,1,.723-.623C17.3,10.306,18,9.808,18,9s-.695-1.3-1.308-1.742a2.924,2.924,0,0,1-.723-.622,3.06,3.06,0,0,1,.229-1,2.415,2.415,0,0,0,.077-2.163c-.442-.632-1.276-.612-2.009-.594h0a2.707,2.707,0,0,1-.967-.068,3.024,3.024,0,0,1-.356-.929C12.736,1.144,12.5.309,11.771.059c-.7-.236-1.351.283-1.925.739h0A2.734,2.734,0,0,1,9,1.351,2.733,2.733,0,0,1,8.143.8C7.571.345,6.924-.179,6.219.06c-.726.246-.962,1.084-1.172,1.823A3.066,3.066,0,0,1,4.7,2.81a2.8,2.8,0,0,1-.968.07c-.735-.021-1.563-.044-2.009.594A2.4,2.4,0,0,0,1.8,5.637a3.024,3.024,0,0,1,.235,1,2.916,2.916,0,0,1-.722.623C.7,7.695,0,8.192,0,9S.7,10.3,1.311,10.742ZM6.045,8.1a.608.608,0,0,1,.887,0L8.221,9.446l2.913-2.282a.608.608,0,0,1,.465-.125.623.623,0,0,1,.413.256.678.678,0,0,1,.119.486.661.661,0,0,1-.245.432L8.539,10.836a.609.609,0,0,1-.421.13.616.616,0,0,1-.4-.191L6.045,9.026a.675.675,0,0,1,0-.927Z" transform="translate(0 0)" fill="#00a706" />
        </g>
      </svg>

    </OverlayTrigger>
  )
};
export default Verified;


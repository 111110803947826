import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './containers/Login';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Register from './containers/Register';
import ForgotEmail from "./containers/ForgotEmail";
import ForgotPassword from "./containers/ForgotPassword";
import ResetPassword from "./containers/ResetPassword";
import Dashboard from './containers/Dashboard';
import ProductDetails from "./containers/ProductDetails";
import PurchaseHistory from "./containers/PurchaseHistory";
import HeatMap from "./containers/HeatMap";
import Refund from "./containers/Refund";
import AddProduct from './containers/AddProduct'
import Profile from "./containers/Profile";
import AdminLogin from "./containers/admin/Login";
import ManageVendor from "./containers/admin/ManageVendor";
import VendorApprovals from "./containers/admin/VendorApproval";
import Deals from "./containers/admin/Deals";
import Notifications from "./containers/Notifications";
import AboutUs from "./containers/AboutUs";
import Terms from "./containers/Terms";
import Privacy from "./containers/Privacy";
import ManageUser from "./containers/admin/ManageUser";
import AdminProfile from "./containers/admin/AdminProfile";
import AdminChangePass from "./containers/admin/AdminChangePassword";
import VendorChangePass from "./containers/VendorChangePassword";
import ManageSubCategory from "./containers/admin/ManageSubCategory";
import AdminProfileEdit from "./containers/admin/AdminProfileEdit";
import ViewSchedulesAdmin from "./containers/admin/ViewSchedules";
import NoPage from './containers/NoPage';
import Payment from "./containers/Payment";
import Feedback from "./containers/Feedback";
import SetupShipment from "./containers/SetupShipment";
///import * as dotenv from 'dotnv'  


function App(props) {

  const [idleTimer, setIdleTimer] = useState(null);
  const idleTimerRef = useRef(idleTimer);
  let pathName = window.location.pathname;

  const onIdle = () => {
    if (pathName !== '/') {
      window.location.href = "/";
    }
  };

  const resetIdleTimer = () => {
    clearTimeout(idleTimerRef.current);

    const newIdleTimer = setTimeout(onIdle, 600000);
    idleTimerRef.current = newIdleTimer;
  };

  const handleMouseActivity = () => resetIdleTimer();
  const handleKeyboardActivity = () => resetIdleTimer();

  useEffect(() => {
    resetIdleTimer();

    document.addEventListener('mousemove', handleMouseActivity);
    document.addEventListener('keydown', handleKeyboardActivity);

    return () => {
      document.removeEventListener('mousemove', handleMouseActivity);
      document.removeEventListener('keydown', handleKeyboardActivity);
      clearTimeout(idleTimerRef.current);
    };
  }, []);

  useEffect(() => {
    console.log('== process.env.REACT_APP_USER_TYPE==' + process.env.REACT_APP_USER_TYPE)

  }, [])

  return (
    <BrowserRouter>
      <Routes>

        {
          process.env.REACT_APP_USER_TYPE === 'vendor' ?
            <>
              <Route path='/' element={<Login />} exact />
              <Route path='forgotemail' element={<ForgotEmail hideHeader='N' />} exact />
              <Route path='register' element={<Register step="0" hideHeader='N' />} exact />ForgotEmail
              <Route path='register/step/:step/:id' element={<Register props={props} hideHeader='N' />} exact />
              <Route path='dashboard' element={<Dashboard />} exact />
              <Route path='addproduct' element={<AddProduct />} exact />
              <Route path='register' element={<Register hideHeader='N' />} exact />
              <Route path='forgotpassword' element={<ForgotPassword hideHeader='N' />} exact />
              <Route path='resetpassword/:id' element={<ResetPassword hideHeader='N' />} exact />
              <Route path='customer/forgotpassword' element={<ForgotPassword hideHeader='N' />} exact />
              <Route path='customer/resetpassword/:id' element={<ResetPassword hideHeader='N' />} exact />
              <Route path='dashboard' element={<Dashboard />} exact />
              <Route path='productdetails' element={<ProductDetails />} exact />
              <Route path='addproduct' element={<AddProduct />} exact />
              <Route path='purchasehistory' element={<PurchaseHistory />} exact />
              <Route path='purchasehistory/refund' element={<Refund />} exact />
              <Route path='heatmap' element={<HeatMap />} exact />
              <Route path="feedback" element={<Feedback />} exact />
              <Route path='profile' element={<Profile />} exact />
              <Route path='payment' element={<Payment />} exact />
              <Route path='notifications' element={<Notifications />} exact />
              <Route path='aboutus' element={<AboutUs hideHeader='N' />} exact />
              <Route path='terms' element={<Terms hideHeader='N' />} exact />
              <Route path='privacy' element={<Privacy hideHeader='N' />} exact />
              <Route path='changepassword' element={<VendorChangePass />} exact />
              <Route path="setupshipment" element={<SetupShipment />} exact />
            </>
            :
            <>
              <Route path='/' element={<AdminLogin />} exact />
              <Route path='admin/managevendor/' element={<ManageVendor />} exact />
              <Route path='admin/viewschedules/' element={<ViewSchedulesAdmin />} exact />
              <Route path='admin/vendorapproval/' element={<VendorApprovals />} exact />
              <Route path='admin/deals/:id' element={<Deals />} exact />
              <Route path='admin/manageuser/' element={<ManageUser />} exact />
              <Route path='admin/deals/:id' element={<Deals />} exact />
              <Route path='admin/profile' element={<AdminProfile />} exact />
              <Route path='admin/editprofile' element={<AdminProfileEdit />} exact />
              <Route path='admin/manageuser' element={<ManageUser />} exact />
              <Route path='admin/changepassword' element={<AdminChangePass />} exact />
              <Route path='forgotpassword' element={<ForgotPassword hideHeader='N' />} exact />
              <Route path='resetpassword/:id' element={<ResetPassword hideHeader='N' />} exact />
              <Route path='admin/managesubcategory' element={<ManageSubCategory />} exact />
            </>
        }
        <Route path='*' element={<NoPage hideHeader='N' />} />
      </Routes>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={4000} transition={Slide} style={{ minWidth: "400px" }} hideProgressBar={true} />
    </BrowserRouter>
  );
}

export default App;

export const encryptUrls = [
    process.env.REACT_APP_VENDOR_API_URL + 'V1/signup',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/login',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/editbankinfo',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/forgotpassword',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/forgotpassword',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/resetpassword',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/payment/stripeverifyurl',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/payment/savecard',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/payment/savecard',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/payment/bankdetails',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/updatepassword',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/payment/bankdetails',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/signupstep2',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/signupstep3',
    process.env.REACT_APP_VENDOR_API_URL + 'V1/forgotemail',
    process.env.REACT_APP_PRODUCT_API_URL + 'V1/payment/intent',
    process.env.REACT_APP_ADMIN_API_URL + 'V1/login',
    process.env.REACT_APP_ADMIN_API_URL + 'V1/password/forgot',
    process.env.REACT_APP_ADMIN_API_URL + 'V1/password/reset',
    process.env.REACT_APP_ADMIN_API_URL + 'V1/password/update',
    process.env.REACT_APP_USERS_API_URL + 'V1/resetpassword',
]
  
export const uploadUrls = [
    process.env.REACT_APP_ADMIN_API_URL + 'V1/getpresignedurl',
]
  
  
/**
 * @file   src\
 * @brief  This file is responsible for 
 * @date   Oct, 2022
 * @author ZCO Engineer
 * @copyright (c) 2022, ZCO
 */

import { Button, Col, Pagination, Row, Table, Modal, Form } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InnerLayout from '../../components/InnerLayout'
import Strings from '../../assets/strings/Strings.json'
import SearchInput from '../../components/SearchInput'
import Filter from '../../assets/img/icons/Filter';
import { Link } from 'react-router-dom';
import UserDel from '../../assets/img/icons/DeleteUser';
import UserInfo from '../../assets/img/icons/Info';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { _userListing, _userPurchaseHistory, _deleteUser, _changeUserStatus, resetResponse } from '../../actions/admin/AdminVendorAction';
import { toast } from 'react-toastify';
import { dateToMMMDDYYYY } from '../../helpers/validationHelper';
import Paginate from '../../components/Paginate'
import moment from 'moment';
import * as alerts from "../../utils/Messages";
import * as constants from '../../utils/constants';

const ManageUser = () => {

  const dispatch = useDispatch();

  //getting User Details
  const userListing = (userListingParams) => dispatch(_userListing(userListingParams));
  const userListApiResponse = useSelector((RootReducer) => RootReducer.AdminUserReducer.userListApiResponse)

  //Getting  user Purchase history
  const userPurchaseHistory = (userPurchaseHistoryParams) => dispatch(_userPurchaseHistory(userPurchaseHistoryParams));
  const userPurchaseHistoryResponse = useSelector((RootReducer) => RootReducer.AdminUserReducer.userPurchaseHistoryApiResponse)

  //Change user status
  const changeUserStatus = (userStatus) => dispatch(_changeUserStatus(userStatus));
  const changeUserStatusApiResponse = useSelector((RootReducer) => RootReducer.AdminUserReducer.changeUserStatusApiResponse)

  //delete user
  const deleteUser = (deleteUserId) => dispatch(_deleteUser(deleteUserId));
  const deleteUserApiResponse = useSelector((RootReducer) => RootReducer.AdminUserReducer.deleteUserApiResponse)

  const intitalUserPurchaseHistoryParams = {
    user_id: "",
    p_PageNumber: 1,
    p_pageSize: 5
  }

  const intitalUserListParams = {
    p_SearchKey: "",
    p_PageNumber: 1,
    p_PageSize: 20,
    p_StatusOrder: "",
    p_LocationOrder: 1,
    p_DateFrom: "",
    p_DateTo: ""

  }

  const intitalUserListFilters = {
    p_LocationOrder: 1,
    p_StatusOrder: "",
    p_DateFrom: "",
    p_DateTo: ""
  };


  const [show, setShow] = useState(false);
  const [filtershow, setFilterShow] = useState(false);
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
  const [userListingParams, setUserListingParams] = useState(intitalUserListParams);
  const [userDetails, setUserDetails] = useState([]);
  const [userPurchaseHistoryDetails, setUserPurchaseHistoryDetails] = useState([]);
  const [userPurchaseHistoryParams, setUserPurchaseHistoryParams] = useState(intitalUserPurchaseHistoryParams);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [userListFilters, setuserListFilters] = useState(intitalUserListFilters);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userBlockItem, setUserBlockItem] = useState({});
  const [startDateFilterError, setStartDateFilterError] = useState('')

  const [showConfirmation, setShowConfirmation] = React.useState(false)

  //Calling userList API
  useEffect(() => {
    userListing(userListingParams)
  }, [userListingParams])


  //calling  User's Purchase history
  useEffect(() => {
    if (userPurchaseHistoryParams.user_id) {
      userPurchaseHistory(userPurchaseHistoryParams)
    }
  }, [userPurchaseHistoryParams])


  // RECEIVING USERLIST API RESPONSE
  useEffect(() => {
    if (userListApiResponse && userListApiResponse.apiStatus) {
      if (userListApiResponse.apiErrorCode == constants.STATUS_200 && userListApiResponse.apiResponse && userListApiResponse.apiResponse.user_info) {
        setUserDetails(userListApiResponse.apiResponse.user_info)
      } else {
        toast.error(userListApiResponse.apiMessage)
      }
    }
  }, [userListApiResponse])


  // RECEIVING PURCHASE HISTORY  API RESPONSE
  useEffect(() => {
    if (userPurchaseHistoryResponse && userPurchaseHistoryResponse.apiStatus) {
      if (userPurchaseHistoryResponse.apiErrorCode == constants.STATUS_200 && userPurchaseHistoryResponse.apiResponse && userPurchaseHistoryResponse.apiResponse.user_orders) {
        setUserPurchaseHistoryDetails(userPurchaseHistoryResponse.apiResponse.user_orders)
      } else {
        toast.error(userPurchaseHistoryResponse.apiMessage)
      }
    }
  }, [userPurchaseHistoryResponse])

  // RECEIVING Delete USER  API RESPONSE
  useEffect(() => {
    if (deleteUserApiResponse && deleteUserApiResponse.apiStatus) {
      if (deleteUserApiResponse.apiErrorCode == constants.STATUS_200) {
        if (deleteUserApiResponse.apiMessage)
          toast.success(deleteUserApiResponse.apiMessage)
        userListing(userListingParams)
      } else {
        toast.error(deleteUserApiResponse.apiMessage)
      }
      resetResponse(dispatch, "deleteUserApiResponse")
    }
  }, [deleteUserApiResponse])

  // CHANGE USER  API RESPONSE
  useEffect(() => {
    if (changeUserStatusApiResponse && changeUserStatusApiResponse.apiStatus) {
      if (changeUserStatusApiResponse.apiErrorCode == constants.STATUS_200) {
        userListing(userListingParams)
        if (changeUserStatusApiResponse.apiMessage)
          toast.success(changeUserStatusApiResponse.apiMessage)
      } else {
        toast.error(changeUserStatusApiResponse.apiMessage)
      }
      resetResponse(dispatch, "changeUserStatusApiResponse")
    }
  }, [changeUserStatusApiResponse])



  useEffect(() => {
    let startDates = startDate ? moment(startDate).format('YYYY-MM-DD') : "";
    setuserListFilters(userListFilters => ({ ...userListFilters, p_DateFrom: startDates }))
    setStartDateFilterError('')
  }, [startDate])

  useEffect(() => {
    let endDates = endDate ? moment(endDate).format('YYYY-MM-DD') : ""
    setuserListFilters(userListFilters => ({ ...userListFilters, p_DateTo: endDates }))
    setStartDateFilterError('')
  }, [endDate])


  // page change and state change
  const pageChange = (page) => {
    if (page) {
      setUserListingParams(userListingParams => ({ ...userListingParams, p_PageNumber: page }))
    }
  }

  // page change and state change
  const pageChangePurchaseHistory = (page) => {
    if (page) {
      setUserPurchaseHistoryParams(userPurchaseHistoryParams => ({ ...userPurchaseHistoryParams, p_PageNumber: page }))
    }
  }

  //Close Purchase history modal
  const handleClose = () => {
    setUserPurchaseHistoryDetails([])
    setShow(false)
  }

  //search by users's email
  const searchTextChange = (searchText) => {
    setUserListingParams(userListingParams => ({ ...userListingParams, p_SearchKey: searchText, p_PageNumber: 1 }))
  }

  const handleShow = () => setShow(true);
  const filterClose = () => setFilterShow(false);

  const deleteConfirmationClose = () => setDeleteConfirmationShow(false)

  const deleteUserById = () => {
    deleteUser({
      user_id: deleteUserId
    })
    deleteConfirmationClose()
  }
  //Block and Unblock users
  const userBlockOrActivate = () => {
    let useStatus;
    if (userBlockItem?.userid && (userBlockItem?.active_status === 'Active' || userBlockItem?.active_status === 'Blocked' || userBlockItem?.active_status === 'inactive')) {
      useStatus = userBlockItem?.active_status == 'Active' ? 2 : 1;
      changeUserStatus({
        user_id: userBlockItem?.userid,
        status: useStatus
      })
    }
  }

  const preventKeyPress = (event) => {
    event.preventDefault();
  }


  const filterUserListOptions = (filterType, value) => {
    setuserListFilters(userListFilters => ({ ...userListFilters, [filterType]: value }))
  }


  // set filter options
  const filterUserList = () => {

    if (userListFilters.p_DateTo != '' && userListFilters.p_DateFrom == "") {
      setStartDateFilterError(Strings.Admin.UserManagement.Modal.startDateError)
    } else {
      setStartDateFilterError('')
      setUserListingParams(userListingParams => ({
        ...userListingParams,
        p_PageNumber: 1,
        p_StatusOrder: userListFilters.p_StatusOrder,
        p_LocationOrder: userListFilters.p_LocationOrder,
        p_DateFrom: userListFilters.p_DateFrom,
        p_DateTo: userListFilters.p_DateTo
      }))
      filterClose()
    }
  }

  const clearFilterUserList = () => {
    setuserListFilters(intitalUserListFilters)
    setUserListingParams(intitalUserListParams)
    setStartDate('')
    setEndDate('')
    // setStartDateFilterError('')
  }

  const handleCloseConfirm = () => {
    userBlockOrActivate()
    setShowConfirmation(false)
  }

  const phoneFormat = (value) => {
    let formattedPhoneNumber = value;
    const fullFromatCheck = /^\(\d{3}\) \d{3}-\d{4}$/;
    const fullFromatCheck2 = /^\(\d{3}\) \d{3} \d{4}$/;
    if ( value ){
      if(fullFromatCheck.test(value) && value.length === 14){
        return value;
      } else if ( fullFromatCheck2.test(value) && value.length === 14) {
        if (value.length > 6) {
          formattedPhoneNumber = `${value.slice(0, 9)}-${value.slice(9, 14)}`;
        }
        return formattedPhoneNumber
      } else {
        if (value.length <= 3) {
          formattedPhoneNumber = value;
        } else if (value.length > 3 && value.length <= 6) {
          formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
        } else if (value.length > 6) {
          formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(
            3,
            6
          )}-${value.slice(6, 10)}`;
        }
        return formattedPhoneNumber
      }
    }
  };

  return (
    <InnerLayout>
      <Col className='action-wrap'>
        <Row>
          <Col lg='12' className='d-lg-flex align-items-lg-center'>
            <h1>{Strings.Admin.UserManagement.Title}</h1>
          </Col>
        </Row>
      </Col>
      <Row className='search-wrap'>
        <Col md="9" lg='5'>
          <SearchInput onClick={searchTextChange} p_SearchKey={userListingParams.p_SearchKey} />
        </Col>
        <Col md="3" lg="3">
          <Button variant='primary' onClick={() => setFilterShow(true)}><Filter />{Strings.Admin.UserManagement.Btn}</Button>
        </Col>
      </Row>
      <Col>
        <Table responsive>
          <thead>
            <tr>
              {/* <th>Sl.No</th> */}
              <th>{Strings.Admin.UserManagement.TH}</th>
              <th>{Strings.Admin.UserManagement.TH1}</th>
              <th>{Strings.Admin.UserManagement.TH2}</th>
              <th>{Strings.Admin.UserManagement.TH3}</th>
              <th>{Strings.Admin.UserManagement.TH4}</th>
              <th>{Strings.Admin.UserManagement.TH5}</th>
              <th>{Strings.Admin.UserManagement.TH6}</th>
              <th>{Strings.Admin.UserManagement.TH7}</th>
              <th>{Strings.Admin.UserManagement.TH8}</th>
            </tr>
          </thead>
          <tbody>
            {userListApiResponse && userListApiResponse.apiStatus && userDetails.length > 0 ? userDetails.map((items, index) => (
              <tr>
                {/* <td>1</td> */}
                <td>{items.firstname}</td>
                <td>{items.lastname}</td>
                <td>{items.email}</td>
                <td>{items.phoneno && phoneFormat(items.phoneno)}</td>
                <td>{items.city}</td>
                <td>{dateToMMMDDYYYY(items.createdon)}</td>
                <td className='action' onClick={()=>{setShowConfirmation(true);setUserBlockItem(items)}}><Link to="" className={items.active_status === 'Active' ? 'active' : 'block'}>{items.active_status === 'Active' ? 'Active' : 'Blocked'}</Link></td>
                {/* <td className='action' onClick={() => userBlockOrActivate(items)}><Link to="" className={items.active_status === 'Active' ? 'active' : 'block'}>{items.active_status === 'Active' ? 'Active' : 'Blocked'}</Link></td> */}
                <td className='action' onClick={() => (setDeleteUserId(items.userid), setDeleteConfirmationShow(true))}><UserDel /></td>
                <td className='action' onClick={() => (handleShow(), setUserPurchaseHistoryParams(userPurchaseHistoryParams => ({ ...userPurchaseHistoryParams, user_id: items.userid })))}><UserInfo /></td>
              </tr>
            )) : ""}
          </tbody>
        </Table>
        {userListApiResponse && userListApiResponse.apiStatus && userDetails.length > 0 ? "" : <Col className="text-center mt-5">{alerts.NO_USERS_AVAILABLE}</Col>}
        {userListApiResponse.apiStatus && userListApiResponse.apiResponse && userListApiResponse.apiResponse.total_records ?
          <Col className='d-flex justify-content-center paginate-wrap'>
            <span className='records'>
              {Strings.Pagination.Label} &nbsp;
              {((userListingParams.p_PageNumber - 1) * userListingParams.p_PageSize) + 1
              }
              &nbsp; {Strings.Pagination.Label1} &nbsp;
              {((userListingParams.p_PageNumber - 1) * userListingParams.p_PageSize) + userListApiResponse.apiResponse.user_info.length}
              &nbsp; {Strings.Pagination.Label2} &nbsp;
              {userListApiResponse.apiResponse.total_records}
              &nbsp; {Strings.Pagination.Label3}
            </span>

            <Paginate
              totalRecords={userListApiResponse.apiResponse.total_records}
              currentPage={userListApiResponse.apiResponse.current_page}
              perPage={userListApiResponse.apiResponse.per_page}
              onClick={pageChange}
            />
          </Col> : ""
        }
      </Col>
      <Modal show={show} onHide={handleClose} size="xl" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Admin.UserManagement.Modal.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='table-wrap'>
          <Table responsive>
            <thead>
              <tr>
                <th>{Strings.Admin.UserManagement.TH9}</th>
                <th>{Strings.Admin.UserManagement.TH10}</th>
                <th>{Strings.Admin.UserManagement.TH11}</th>
                <th>{Strings.Admin.UserManagement.TH12}</th>
                <th>{Strings.Admin.UserManagement.TH13}</th>
              </tr>
            </thead>
            <tbody>
              {userPurchaseHistoryResponse && userPurchaseHistoryResponse.apiStatus && userPurchaseHistoryDetails.length > 0 ? userPurchaseHistoryDetails.map((items, index) => (<tr>
                <td>{index + 1}</td>
                <td>{dateToMMMDDYYYY(items.purchase_date)}</td>
                <td>{items.business_name}</td>
                <td>{items.product_name}</td>
                <td>${items.price}</td>
              </tr>)) : ""}
            </tbody>
          </Table>
          {userPurchaseHistoryResponse && userPurchaseHistoryResponse.apiStatus && userPurchaseHistoryDetails.length > 0 ? "" : <Col className="text-center">{alerts.NO_PURCHASE_HISTORY_AVAILABLE}</Col>}

          {userPurchaseHistoryResponse.apiStatus && userPurchaseHistoryResponse.apiResponse && userPurchaseHistoryResponse.apiResponse.total_records ?
            <Col className='d-flex justify-content-center paginate-wrap'>
              <span className='records'>
                {Strings.Pagination.Label} &nbsp;
                {((userPurchaseHistoryParams.p_PageNumber - 1) * userPurchaseHistoryParams.p_pageSize) + 1
                }
                &nbsp; {Strings.Pagination.Label1} &nbsp;
                {((userPurchaseHistoryParams.p_PageNumber - 1) * userPurchaseHistoryParams.p_pageSize) + userPurchaseHistoryResponse.apiResponse.user_orders.length}
                &nbsp; {Strings.Pagination.Label2} &nbsp;
                {userPurchaseHistoryResponse.apiResponse.total_records}
                &nbsp; {Strings.Pagination.Label3}
              </span>

              <Paginate
                totalRecords={userPurchaseHistoryResponse.apiResponse.total_records}
                currentPage={userPurchaseHistoryResponse.apiResponse.current_page}
                perPage={userPurchaseHistoryResponse.apiResponse.per_page}
                onClick={pageChangePurchaseHistory}
              />
            </Col> : ""
          }
        </Modal.Body>
      </Modal>
      {/* Filter Popup */}
      <Modal show={filtershow} onHide={filterClose} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Admin.UserManagement.FilterModal.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='filter-wrap'>
          <Row>
            <Col lg='6'>
              <span>{Strings.Admin.UserManagement.FilterModal.Subtitle1}</span>
              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label1} name="p_LocationOrder" onClick={() => filterUserListOptions('p_LocationOrder', 1)} checked={userListFilters.p_LocationOrder === constants.STATUS_1 ? true : false} />
              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label2} name="p_LocationOrder" onClick={() => filterUserListOptions('p_LocationOrder', 2)} checked={userListFilters.p_LocationOrder === constants.STATUS_2 ? true : false} />
            </Col>
            <Col lg='6'>
              <span>{Strings.Admin.UserManagement.FilterModal.Subtitle3}</span>
              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label5} name="p_StatusOrder" onClick={() => filterUserListOptions('p_StatusOrder', 1)} checked={userListFilters.p_StatusOrder === constants.STATUS_1 ? true : false} />
              <Form.Check type="radio" aria-label="radio 1" label={Strings.Admin.UserManagement.FilterModal.Label6} name="p_StatusOrder" onClick={() => filterUserListOptions('p_StatusOrder', 2)} checked={userListFilters.p_StatusOrder === constants.STATUS_2 ? true : false} />
            </Col>
          </Row>
          <Row className='mt-4'>
            <div className='w-100'><span>{Strings.Admin.UserManagement.FilterModal.Subtitle4}</span></div>
            <Col lg="6">
              <div className='date-picker'>
                <label>{Strings.Admin.UserManagement.FilterModal.Label7}</label>
                <DatePicker  onKeyDown={preventKeyPress}  selected={startDate} maxDate={new Date()} onChange={(date) => setStartDate(date)} />
              </div>
            </Col>
            <Col lg="6">
              <div className='date-picker'>
                <label>{Strings.Admin.UserManagement.FilterModal.Label8}</label>
                <DatePicker  onKeyDown={preventKeyPress}  selected={endDate} minDate={startDate ? startDate : null} maxDate={new Date()} onChange={(date) => setEndDate(date)} />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='justify-content-start mt-3'>
          <Button variant="outline-primary" onClick={clearFilterUserList}>{Strings.Admin.UserManagement.FilterModal.Btn1}</Button>
          <Button variant="primary" onClick={filterUserList}>{Strings.Admin.UserManagement.FilterModal.Btn2}</Button>
        </Modal.Footer>
      </Modal>
      {/* delete Modal user popup */}
      <Modal show={deleteConfirmationShow} onHide={deleteConfirmationClose} centered backdrop="static">
        <Modal.Body className='text-center'>
          <h5>{Strings.Admin.UserManagement.Modal.Label1}</h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center'>
          <Button variant="outline-primary" onClick={deleteConfirmationClose}>{Strings.Admin.UserManagement.Modal.Btn2}</Button>
          <Button variant="primary" onClick={deleteUserById}>{Strings.Admin.UserManagement.Modal.Btn1}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmation} onHide={()=>setShowConfirmation(false)} size="l" centered backdrop="static">
        <Modal.Body >
        <h5 className="text-center"> {alerts.ACTIVE_BLOCK_CONFIRMATION} </h5>
        </Modal.Body>
        <Modal.Footer className='justify-content-center' >
          <Button variant="outline-primary" onClick={()=>setShowConfirmation(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleCloseConfirm}>OK</Button>
        </Modal.Footer>
      </Modal>
    </InnerLayout>

  );
}
export default ManageUser;

import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import './Header.scss'
import logo from '../assets/img/logo-topbar.svg';
import TopNav from './TopNav';
import OffCanvas from './OffCanvas';
import { useNavigate } from 'react-router-dom';
import * as constants from '../utils/constants';
import { toast } from 'react-toastify';
import * as alerts from '../utils/Messages'
import { useDispatch, useSelector } from 'react-redux';
import {  _adminProfile } from '../actions/AuthAction';
import { _vendorProfile } from '../actions/VendorAction';
const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vendorProfile = () => dispatch(_vendorProfile());
  const vendorProfileResponse = useSelector((RootReducer) => RootReducer.VendorReducer.vendorProfileResponse);

  const adminProfile = () => dispatch(_adminProfile());
  const adminProfileResponse = useSelector((RootReducer) => RootReducer.AuthReducer.adminProfileResponse);

  const [userProfileData, setUserProfileData] = useState("")
  const [adminProfileData, setAdminProfileData] = useState('')
  const [subscribed, setSubscribed] = useState(false)


  useEffect(() => {
    let pathName = window.location.pathname;
    
    if (pathName !== '/register' && pathName !== '/forgotpassword' && pathName !== '/forgotemail'  &&  !pathName.includes("/register/step") && pathName!==`/resetpassword/${props?.id}` && pathName!==`/customer/resetpassword/${props?.id}`&& pathName !== '/customer/forgotpassword' && pathName !== '/terms' && pathName !== '/aboutus'&& pathName !== '/privacy') {
      if (process.env.REACT_APP_USER_TYPE === 'admin')
        adminProfile()
      else {
        vendorProfile();
      }
    }

  }, [])
  useEffect(() => {
    if (vendorProfileResponse && vendorProfileResponse.apiStatus) {
      if (vendorProfileResponse.apiErrorCode === constants.STATUS_200 && vendorProfileResponse.apiResponse && vendorProfileResponse.apiResponse.basic_info) {
        setUserProfileData(vendorProfileResponse.apiResponse.basic_info.f_name)
      } else {
        setUserProfileData('')
      }
      if (vendorProfileResponse.apiErrorCode === constants.STATUS_200 &&
        vendorProfileResponse.apiResponse.verification_info &&
        vendorProfileResponse.apiResponse.verification_info?.subscribed === constants.STATUS_FALSE &&
        vendorProfileResponse.apiResponse.verification_info?.is_first_subscription === constants.STATUS_FALSE) {
        setSubscribed(true)
      } else {
        setSubscribed(false)
      }
    }
  }, [vendorProfileResponse])


  useEffect(() => {
    if (adminProfileResponse && adminProfileResponse.apiStatus) {

      if (adminProfileResponse.apiErrorCode === constants.STATUS_200 && adminProfileResponse.apiResponse && adminProfileResponse.apiResponse.f_name) {
        setAdminProfileData(adminProfileResponse.apiResponse.f_name)
      } else {
        setAdminProfileData('')
      }
    }
  }, [adminProfileResponse])

  const redirectFromLogo = () => {
    let redirectUrl = '/';
    if (process.env.REACT_APP_USER_TYPE === 'admin') {
      redirectUrl = '/admin/managevendor'
    } else if (process.env.REACT_APP_USER_TYPE === 'vendor') {
      redirectUrl = '/dashboard'
    }
    if (subscribed === true) {
      toast.error(alerts.PLEASE_SUBSCRIBE_LOGIN)
    } else {
      navigate(redirectUrl)
    }
  }
  let pathName = window.location.pathname;

  return (
    <Container fluid className='header d-flex align-items-center'>
      <Container>
        <Row className='postion-relative'>

          {
            pathName.indexOf('/register') === 0 || pathName.indexOf('/customer/forgotpassword') === 0 || pathName.indexOf('/forgotpassword') === 0 || pathName.indexOf('/resetpassword') === 0 || pathName.indexOf('/customer/resetpassword') === 0 || pathName.indexOf('/terms') === 0 || pathName.indexOf('/aboutus') === 0 || pathName.indexOf('/privacy') === 0 ||pathName.indexOf('/forgotemail') === 0 || pathName.indexOf('/dashboard')=== 0?
              <Col sm="auto" ><img src={logo} alt='NearBuys Logo' /></Col> :
              <Col sm="auto" className='cursor-pointer'><img src={logo} alt='NearBuys Logo' onClick={redirectFromLogo} /></Col>
          }

          <Col className='d-flex align-items-center justify-content-end'>
            {props.hideHeader && props.hideHeader === 'N' ? "" : <TopNav hideHeader={props.hideHeader} />}
            <OffCanvas hideHeader={props.hideHeader} />
          </Col>
        </Row>
      </Container>
    </Container>
  )
};

export default Header;


import React, { useState, useEffect } from "react";
import "./ForgotPasswordForm.scss";
import { Button, Col } from "react-bootstrap";
import Strings from "../assets/strings/Strings.json";
import Input from "../components/Input";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { _vendorForgotPassword, resetResponse } from "../actions/VendorAction";
import { _adminForgotPassword } from "../actions/admin/AdminVendorAction";
import { _userForgotPassword } from "../actions/UserAction";
import { emailValidation } from "../helpers/authHelper";
import { toast } from "react-toastify";
import * as alerts from "../utils/Messages";
import  * as constants from '../utils/constants';

const ForgotPasswordForm = (props) => {
  const dispatch = useDispatch();

  // ------- call API from functions and response from redux start ---------
  // vendor forgot password
  const vendorForgotPassword = (params) =>
    dispatch(_vendorForgotPassword(params));
  const vendorForgotPasswordResponse = useSelector(
    (RootReducer) => RootReducer.VendorReducer.vendorForgotPasswordResponse
  );

  // admin forgot password
  const adminForgotPassword = (params) =>
    dispatch(_adminForgotPassword(params));
  const adminForgotPasswordResponse = useSelector(
    (RootReducer) => RootReducer.AdminUserReducer.adminForgotPasswordResponse
  );

  // user forgot password
  const userForgotPassword = (params) => dispatch(_userForgotPassword(params));
  const userForgotPasswordResponse = useSelector(
    (RootReducer) => RootReducer.UserReducer.userForgotPasswordResponse
  );

  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddressError, setEmailAddressError] = useState("");
  const [submitDisable, setSubmitDisable] = useState(false);

  let pathName = window.location.pathname;

  // handle vendor forgot password response
  useEffect(() => {
    if (vendorForgotPasswordResponse && vendorForgotPasswordResponse.apiStatus) {
      if (vendorForgotPasswordResponse && vendorForgotPasswordResponse.apiErrorCode === constants.STATUS_200) {
        setSubmitDisable(true);
      } else if (
        vendorForgotPasswordResponse &&
        vendorForgotPasswordResponse.apiErrorCode !== constants.STATUS_200
      ) {
        toast.error(vendorForgotPasswordResponse.apiMessage);
      }
      resetResponse(dispatch, "forgotPassword");
    }
  }, [vendorForgotPasswordResponse]);

  // handle admin forgot password response
  useEffect(() => {
    if (adminForgotPasswordResponse && adminForgotPasswordResponse.apiStatus) {
      if (adminForgotPasswordResponse && adminForgotPasswordResponse.apiErrorCode === constants.STATUS_200) {
        setSubmitDisable(true);
      } else if (adminForgotPasswordResponse && adminForgotPasswordResponse.apiErrorCode !== constants.STATUS_200
      ) {
        toast.error(adminForgotPasswordResponse.apiMessage);
      }
      resetResponse(dispatch, "adminForgotPassword");
    }
  }, [adminForgotPasswordResponse]);

  // handle user forgot password response
  useEffect(() => {
    if (userForgotPasswordResponse && userForgotPasswordResponse.apiStatus) {
      if (userForgotPasswordResponse && userForgotPasswordResponse.apiErrorCode === constants.STATUS_200
      ) {
        setSubmitDisable(true);
      } else if (userForgotPasswordResponse && userForgotPasswordResponse.apiErrorCode !== constants.STATUS_200
      ) {
        toast.error(userForgotPasswordResponse.apiMessage);
      }
      resetResponse(dispatch, "forgotUserPassword");
    }
  }, [userForgotPasswordResponse]);

  // handle input change
  const handleChange = (event) => {
    setEmailAddress(event.target.value);
  };

  // handle validation
  const validation = () => {
    let isValid = true;
    setEmailAddressError("");
    if (emailAddress.trim() === "") {
      isValid = false;
      setEmailAddressError(alerts.ENTER_EMAIL);
    } else if (emailValidation(emailAddress) === false) {
      isValid = false;
      setEmailAddressError(alerts.ENTER_VALID_EMAIL);
    }
    return isValid;
  };

  // handle submit
  const submit = () => {
    let isValid = validation();
    if (isValid) {
      if (pathName === "/customer/forgotpassword") {
        userForgotPassword({ email: emailAddress });
      } else {
        if (process.env.REACT_APP_USER_TYPE === "admin") {
          adminForgotPassword({
            email: emailAddress,
          });
        } else {
          vendorForgotPassword({
            email: emailAddress,
          });
        }
      }
    }
  };

  const submitFormOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  };

  return (
    <div className="forgot-pass-wrap">
      <h1>
        {process.env.REACT_APP_USER_TYPE === "admin" ? "Admin - " : ""}{" "}
        {Strings.ForgotPassword.Title1}
      </h1>
      <p className="info">{Strings.ForgotPassword.Info}</p>

      <Input
        id="email"
        label={Strings.ForgotPassword.Label1}
        name="email"
        type="email"
        autoComplete="off"
        maxLength={200}
        value={emailAddress}
        onChange={handleChange}
        errorMessage={emailAddressError}
        onKeyDown={submitFormOnKeyDown}
      />
      {pathName !== "/customer/forgotpassword" &&
        <Col className="d-block text-end forgot-pass">
          <Link to="/">{Strings.Register.BasicInfo.Info2}</Link>
        </Col>
      }
      <Button
        onClick={submit}
        disabled={submitDisable || emailAddress === ""}
        className="mt-2"
      >
        {Strings.ForgotPassword.Btn1}
      </Button>

      <p className="pass-alert">
        {submitDisable ? Strings.ForgotPassword.Alert : ""}
      </p>
    </div>
  );
};

export default ForgotPasswordForm;

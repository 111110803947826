import React, { useState } from 'react'

const Subscription = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.269" height="19.317" viewBox="0 0 14.269 19.317">
      <g id="Group_848" data-name="Group 848" transform="translate(-317.5 21.715)">
        <line id="Line_143" data-name="Line 143" y2="2.138" transform="translate(320.85 -11.449)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        <path id="Path_31478" data-name="Path 31478" d="M329.492-12.534,323.7-13.587v-3.486a1.479,1.479,0,0,0-1.186-1.482,1.427,1.427,0,0,0-1.665,1.406v5.7h-1.425A1.425,1.425,0,0,0,318-10.024v2.063a4.272,4.272,0,0,0,.937,2.671L320.85-2.9h9.264l1.108-7.207A2.138,2.138,0,0,0,329.492-12.534Z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        <path id="Path_31479" data-name="Path 31479" d="M110.452,210.06a3.373,3.373,0,1,1,5.842,0" transform="translate(208.938 -226.215)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
      </g>
    </svg>

  )
};
export default Subscription;


import React, { useState } from 'react'

const Mail = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.243" height="11" viewBox="0 0 16.243 11">
      <g id="Group_673" data-name="Group 673" transform="translate(-1679.312 -2654.688)">
        <rect id="Rectangle_521" data-name="Rectangle 521" width="15" height="10" transform="translate(1679.933 2655.188)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" />
        <line id="Line_139" data-name="Line 139" x2="4.822" y2="3.812" transform="translate(1690.027 2660.48)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" />
        <line id="Line_140" data-name="Line 140" y1="3.605" x2="4.416" transform="translate(1680.017 2660.688)" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1" />
        <path id="Path_31472" data-name="Path 31472" d="M1680.11,2659.053l6.177,5.384a1.79,1.79,0,0,0,2.478,0l6.177-5.384" transform="translate(-0.093 -2.797)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1" />
      </g>
    </svg>
  )
};
export default Mail;


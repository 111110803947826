import React from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Input.scss'
import Info from '../assets/img/icons/InfoTooltip'

const Input = (props) => {
  return (
    <Form className='input-group'>
      <Form.Group>
        <Form.Label>
          {props.label}
          {props.subText &&<OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{props.subText}</Tooltip>}>
            <span className='tooltip-wrap'><Info /></span>
          </OverlayTrigger>
          }
        </Form.Label>
        <Form.Control
          id={props.id}
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          onChange={props.onChange}
          value={props.value}
          ref={props.txtRef}
          disabled={props.status}
          readOnly={props.readOnly}
          className={props.errorMessage && props.errorMessage !== '' ? 'error' : ""}
          onKeyDown={props.onKeyDown}
          maxLength={props.maxLength}
          autoCapitalize={props.autoCapitalize}
          onBlur={props.onBlur}
          onSubmit={props.onSubmit}
          autoComplete={props.autoComplete}
          onClick={props.onClick}
          onPointerMove={props.onPointerMove}
          as={props.as}
          rows={props.rows}
          pattern={props.pattern}
        />
        {props.errorMessage && props.errorMessage !== '' ? <Form.Text className='error'>
          {props.errorMessage}
        </Form.Text> : ""}
      </Form.Group>
    </Form>
  )
};

export default Input;


/**
 * @file   src\utils\Enum.js
 * @brief  javascript file to define enums used in the system
 * @date   Sept , 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

export const ShipmentLabel = Object.freeze({
    Add: 1,
    Edit: 2
});
export const CategoryId = Object.freeze({
    
    Coupons: 6,
    Classifieds:5,
    OutAndAbout: 4,
    Services:3,
    Food:2,
    Goods:1,

});
export const SubscriptionPlans = Object.freeze({
    Monthly:1,
    Basic:2,
    Advanced:3,
    Ultimate:4
})